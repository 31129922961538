/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICampaignV2Client } from '@vacinas-net/shared'
import { CampaignDetailsFetchAction, CampaignsState } from './types'

export type SetCampaignsAction = PayloadAction<ICampaignV2Client[]>
export type SetCampaignDetailAction = PayloadAction<ICampaignV2Client>

const initialState: CampaignsState = {
  campaigns: [],
  isLoading: true,
  isLoadingCampaignDetail: true,
  limit: 20,
}

export const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    getCampaignsFetch: (state, action) => {
      const { limit, sortOrder, sort, searchTerm, showEndedCampaigns } =
        action.payload
      state.limit = limit
      state.sort = sort
      state.sortOrder = sortOrder
      state.isLoading = true
      state.searchTerm = searchTerm
      state.showEndedCampaigns = showEndedCampaigns
    },
    getCampaignsSuccess: (state, data: SetCampaignsAction) => {
      state.campaigns = [...state.campaigns, ...data.payload]
      state.isLoading = false
    },
    getCampaignsError: (state) => {
      state.isLoading = false
    },
    getCampaignDetailsFetch: (state, action: CampaignDetailsFetchAction) => {
      state.isLoadingCampaignDetail = true
    },
    getCampaignDetailsSuccess: (state, data: SetCampaignDetailAction) => {
      state.campaignDetails = data.payload
      state.isLoadingCampaignDetail = false
    },
    getCampaigDetailsError: (state) => {
      state.isLoadingCampaignDetail = false
    },
    setTotalCampaigns: (state, action) => {
      state.total = action.payload
    },
    resetCampaigns: (state) => {
      state.total = 0
      state.campaigns = []
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useState, ChangeEvent, useEffect } from 'react'
import { mainTheme } from '../../../styles/mainTheme'
import {
  TableCellBody,
  TableCellHeader,
  TablePaper,
  TableTitle,
  TableTitleContainer,
} from './styles'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../state/store'
import { actions } from '../../../state'
import { useParams } from 'react-router-dom'
import { formatCPF } from '@brazilian-utils/brazilian-utils'
import CampaignOrderStatusChip from '../chips/CampaignOrdersStatusChip'
import OrderReportFilters from './Filters/OrderReportFilters'
import { MoreVert } from '@mui/icons-material'

const OrderReportTable = () => {
  const params = useParams<{ campaignId?: string }>()
  const dispatch = useDispatch()
  const campaignOrders = useSelector((state) => state.campaignOrders)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const downloadCampaignOrders = () => {
    dispatch(
      actions.campaignOrders.downloadCampaignOrders({
        campaignId: params.campaignId!,
        filters: campaignOrders.filters,
      })
    )
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  useEffect(() => {
    dispatch(
      actions.campaignOrders.getCamapaignOrders({
        page: pagination.page,
        limit: pagination.rowsPerPage,
        campaignId: params.campaignId,
        filters: campaignOrders.filters,
      })
    )
  }, [dispatch, pagination, params.campaignId])

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 0,
    })
  }, [campaignOrders.filters])

  return (
    <>
      <OrderReportFilters />
      <TablePaper>
        <TableTitleContainer>
          <TableTitle>Pedidos</TableTitle>
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              disabled={campaignOrders.isDownloadingCampaigns}
              onClick={() => {
                downloadCampaignOrders()
                handleClose()
              }}
            >
              Exportar todos os pedidos
            </MenuItem>
          </Menu>
        </TableTitleContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHeader>Pedido</TableCellHeader>
                <TableCellHeader>Cliente</TableCellHeader>
                <TableCellHeader>CPF</TableCellHeader>
                <TableCellHeader>Locais</TableCellHeader>
                <TableCellHeader>Cidades</TableCellHeader>
                <TableCellHeader>Situação</TableCellHeader>
                <TableCellHeader>Data Compra</TableCellHeader>
                <TableCellHeader>Cupom</TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {!campaignOrders.isLoading ? (
                campaignOrders.orders.map((order) => (
                  <TableRow key={order._id}>
                    <TableCellBody>{order.orderId || '-'}</TableCellBody>
                    <TableCellBody>{order.customer?.name || '-'}</TableCellBody>
                    <TableCellBody>
                      {formatCPF(String(order.customer?.document)) || '-'}
                    </TableCellBody>
                    <TableCellBody>
                      {order.pickupPointNames.join(', ') || '-'}
                    </TableCellBody>
                    <TableCellBody>
                      {order.cities.join(', ') || '-'}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <CampaignOrderStatusChip status={order.status!} />
                    </TableCellBody>
                    <TableCellBody>
                      {order.purchasedAt
                        ? format(new Date(order.purchasedAt!), 'P', {
                            locale: ptBR,
                          })
                        : '-'}
                    </TableCellBody>
                    <TableCellBody>
                      {order.marketingData?.couponCode || '-'}
                    </TableCellBody>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <CircularProgress
                    style={{ padding: mainTheme.spacing(2) }}
                    size={30}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={campaignOrders.total}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </TablePaper>
    </>
  )
}

export default OrderReportTable

import { AppBar, Box, Link, Toolbar, Typography } from '@mui/material'
import React from 'react'
import VacinasLogoNoName from '../../../assets/VacinasLogoNoName.svg'
import LogoWithouSystems from '../../../assets/LogoWithouSystems.svg'
import CustomAvatar from '../CustomAvatar'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { mainTheme } from '../../../styles/mainTheme'
import { useNavigate } from 'react-router-dom'
import { ToolbarContainer, ToolbarItem } from './styles'
import { Help } from '@mui/icons-material'

const Header = () => {
  const user = useSelector((state: RootState) => state.user.user)
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderBottom: `1px solid ${mainTheme.palette.primary.main}`,
      }}
    >
      <AppBar color="inherit" position="static">
        <Toolbar>
          <ToolbarContainer>
            <ToolbarItem
              onClick={() => {
                navigate('/campaign')
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <img width={36} src={VacinasLogoNoName} alt="Logo Vacinas.net" />
              <img src={LogoWithouSystems} alt="Logo Jenner" />
            </ToolbarItem>
            <ToolbarItem>
              <Link
                href="https://vacinas-net.tomticket.com/"
                target="_blank"
                style={{
                  display: 'flex',
                  gap: 8,
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Help />
                <Typography sx={{ fontWeight: 'bold' }}>Suporte</Typography>
              </Link>
            </ToolbarItem>
            <ToolbarItem>
              <CustomAvatar imageURL={user.profileImageUrl} name={user.name!} />
            </ToolbarItem>
          </ToolbarContainer>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header

import styled from 'styled-components'
import { mainTheme } from '../../../../../styles/mainTheme'
import { Grid, Typography, Badge, Paper, ListItem } from '@mui/material'

export const PaperContainer = styled(Paper)`
  background-color: #f9f0ff;
  padding: ${mainTheme.spacing(2)}px;
  margin-top: ${mainTheme.spacing(2)}px;
`

export const PixIconContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${mainTheme.spacing(2)}px;
`

export const PixCodeConatiner = styled('div')`
  display: flex;
  border-radius: ${mainTheme.spacing(1)}px;
  padding: ${mainTheme.spacing(1)}px;
  background: #fff;
  align-items: center;
  justify-content: center;
  margin-top: ${mainTheme.spacing(2)}px;
  margin-bottom: ${mainTheme.spacing(2)}px;
`
export const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${mainTheme.spacing(1)}px;
`

export const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: flex-start;
  padding-right: 0px;
`

export const StyledBadge = styled(Badge)`
  margin-right: ${mainTheme.spacing(2 + 1 / 2)}px;
  top: 12px;
`

export const PixBannerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${mainTheme.spacing(2)}px;
`

export const ImageStyles = styled('img')`
  width: 50%;
  margin-bottom: ${mainTheme.spacing(2)}px;

  ${mainTheme.breakpoints.up('md')} {
    width: 30%;
  }
`

import { Typography } from '@mui/material'
import React from 'react'
import { LabeledItemContainer, LabeledItemHeader } from './styles'

const LabeledItem = (props: { title: string; content: string }) => {
  return (
    <LabeledItemContainer>
      <LabeledItemHeader fontWeight={'bold'} variant="body1">
        {props.title}
      </LabeledItemHeader>
      <Typography variant="body1">{props.content}</Typography>
    </LabeledItemContainer>
  )
}

export default LabeledItem

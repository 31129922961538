import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  getAllVouchersDownloadUrl,
  getSingleVoucherPackageDownloadUrl,
  getVoucherPackage,
  requestAdditionalPackage,
} from '../../services/vouchers'
import { actions } from './slice'
import {
  DownloadAllVouchersAction,
  DownloadSingleVoucherPackageAction,
  RequestAdditionalVouchersAction,
  VouchersFetchAction,
} from './types'
import { saveAs } from 'file-saver'
import { errorActions } from '../errors'

export function* sagaGetVouchersFetch(
  action: VouchersFetchAction
): SagaIterator<void> {
  try {
    const { campaignId } = action.payload
    const vouchers = yield call(getVoucherPackage, campaignId)
    yield put(actions.getVouchersSuccess(vouchers))
  } catch (e) {
    console.error(e)
    yield put(actions.getVouchersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaDownloadAllVouchers(
  action: DownloadAllVouchersAction
): SagaIterator<void> {
  try {
    const { campaignId } = action.payload
    const vouchers = yield call(getAllVouchersDownloadUrl, campaignId)
    yield call(saveAs, vouchers, 'download.xlsx')
    yield put(actions.downloadAllVouchersSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.downloadAllVouchersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaDownloadSingleVoucherPackages(
  action: DownloadSingleVoucherPackageAction
): SagaIterator<void> {
  try {
    const { campaignId, voucherPackageId } = action.payload
    const vouchers = yield call(
      getSingleVoucherPackageDownloadUrl,
      campaignId,
      voucherPackageId
    )

    yield call(saveAs, vouchers, 'download.xlsx')
    yield put(actions.downloadSingleVoucherPackageSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.downloadSingleVoucherPackageError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaRequestAdditionalVoucherPackage(
  action: RequestAdditionalVouchersAction
): SagaIterator<void> {
  try {
    const { campaignId, quantity } = action.payload
    yield call(requestAdditionalPackage, campaignId, quantity)
    yield put(actions.requestAdditionalPackageSuccess())
    yield put(
      actions.getVouchersFetch({
        campaignId,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(actions.requestAdditionalPackageError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeEvery(actions.getVouchersFetch.type, sagaGetVouchersFetch),
  takeEvery(actions.downloadAllVouchers.type, sagaDownloadAllVouchers),
  takeEvery(
    actions.downloadSingleVoucherPackage.type,
    sagaDownloadSingleVoucherPackages
  ),
  takeEvery(
    actions.requestAdditionalPackage.type,
    sagaRequestAdditionalVoucherPackage
  ),
]

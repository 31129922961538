import React from 'react'
import Grid from '@mui/material/Grid'
import { useSelector } from '../../../../state/store'
import { Button } from '@mui/material'
import { actions } from '../../../../state/createCampaignOrder'
import { useDispatch } from 'react-redux'
import {
  createCampaignOrder,
  CreateCampaignOrderPayload,
} from '../../../../services/createCampaignOrder'
import {
  CreateCampaignOrderDTO,
  IDateRange,
  PaymentStatus,
  ShippingPolicy,
} from '@vacinas-net/shared'
import { LoadingButton } from '@mui/lab'
import { errorActions } from '../../../../state/errors'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'
import { useNavigate } from 'react-router-dom'

const SubmitSection = () => {
  const formData = useSelector((state) => state.createCampaignOrder)
  const { createOrderForm } = formData
  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )

  const submit = async () => {
    dispatch(actions.setLoading(true))
    formData.campaign?.requiredCustomerPayment &&
      dispatch(actions.setOpenLoadingPaymentModal(true))
    try {
      const payload: CreateCampaignOrderDTO = {
        campaignId: String(formData.campaign?._id),
        shippingPolicy:
          formData.shippingPolicy === ShippingPolicy.Company
            ? ShippingPolicy.Company
            : ShippingPolicy.Clinic,
        allowedCustomerDocument: customer.document.replace(/[^\d]/g, ''),
        availableAttendanceId:
          formData.shippingPolicy === ShippingPolicy.Company
            ? createOrderForm.availableAttendanceId
            : undefined,
        customer: {
          birthDate: createOrderForm.birthdate,
          document: createOrderForm.document.replace(/[^\d]/g, ''),
          email: createOrderForm.email,
          name: createOrderForm.fullName,
          phone: createOrderForm.phone.replace(/[^\d]/g, ''),
        },
        clinicId:
          formData.shippingPolicy === ShippingPolicy.Clinic
            ? formData.selectedClinic?._id
            : undefined,
        deliveryWindow:
          formData.shippingPolicy === ShippingPolicy.Clinic
            ? (JSON.parse(formData.vaccineShotTime!) as Required<IDateRange>)
            : undefined,
        payment: formData.campaign?.requiredCustomerPayment
          ? paymentMethod === 'creditCard'
            ? {
                creditCard: {
                  token: formData.paymentToken,
                },
                installmentCount:
                  formData.iuguCreditCardFormData?.installmentsNumber,
                method: 'creditCard',
              }
            : {
                method: 'pix',
              }
          : undefined,
      }
      const result = await createCampaignOrder(
        payload as CreateCampaignOrderPayload
      )
      if (!formData.campaign?.requiredCustomerPayment) {
        dispatch(actions.setCreatedOrder(result))
        navigate('/campaign/order/receipt')
        return
      }

      if (formData.paymentMethod === 'creditCard') {
        const payment =
          result?.payments?.find(
            (payment) => payment.method === 'creditCard'
          ) || undefined
        const invoiceId = payment?.paymentId
        dispatch(actions.setInvoiceId(invoiceId))
        dispatch(
          actions.setPaymentStatus({
            status: PaymentStatus.PROCESSING,
          })
        )
        dispatch(actions.setCreatedOrder(result))
        return
      }

      const payment =
        result?.payments?.find((payment) => payment.method === 'pix') ||
        undefined
      const invoiceId = payment?.paymentId

      dispatch(actions.setCreatedOrder(result))
      dispatch(actions.setInvoiceId(invoiceId))
      dispatch(actions.setPixPaymentCode(payment!.pix!.textCode))
      dispatch(actions.setPixPaymentQrCode(payment!.pix!.qrCode))
      dispatch(
        actions.setPaymentStatus({
          status: PaymentStatus.PROCESSING,
        })
      )
      dispatch(actions.setCreateOrderFormStep('pixPayment'))
    } catch (error) {
      const errorMessage = (error as Error).message

      formData.campaign?.requiredCustomerPayment &&
        dispatch(actions.setOpenLoadingPaymentModal(false))

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  const goBack = () => {
    dispatch(
      actions.setCreateOrderFormStep(
        formData.campaign?.requiredCustomerPayment ? 'payment' : 'place'
      )
    )
  }

  const ResponsibleGrid = styled(Grid)`
    ${mainTheme.breakpoints.up('md')} {
      margin-top: 32px;
    }
  `

  return (
    <ResponsibleGrid
      container
      xs={12}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      marginBottom={4.5}
      gap={2}
      marginRight={2}
    >
      <Button onClick={() => goBack()} variant="text" size="small">
        {formData.campaign?.requiredCustomerPayment
          ? '< Alterar Pagamento'
          : '< Alterar Dados'}
      </Button>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        onClick={() => submit()}
        variant="contained"
        size="large"
      >
        {paymentMethod === 'pix' ? 'Gerar Código PIX' : 'Finalizar'}
      </LoadingButton>
    </ResponsibleGrid>
  )
}

export default SubmitSection

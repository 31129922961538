import { Select, TextField } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../styles/mainTheme'

export const StyledTextField = styled(TextField)`
  margin-left: 16px;
  background: white;
  border-radius: 24px;

  input:-webkit-autofill {
    -webkit-text-fill-color: #000000;
    transition-delay: 9999s;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: ${mainTheme.spacing(3)}px;
  }

  & .MuiInputLabel-root {
    top: auto;
    left: auto;
  }

  @media (max-width: 900px) {
    margin-top: ${mainTheme.spacing(1)}px;
  }
`

export const FilterInputsContainer = styled('div')`
  display: flex;
  background-color: #ececec;
  padding: ${mainTheme.spacing(1)}px;
  margin-bottom: ${mainTheme.spacing(2)}px;
  @media (max-width: 900px) {
    overflow-x: scroll;
  }
`
export const StyledTextFieldSquare = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #b5b5b5;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000000;
  }
`

export const StyledSelectSquare = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #b5b5b5;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000000;
  }
`

import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getMapClinics } from '../../services/clinicMap'
import { errorActions } from '../errors'
import { actions } from './slice'
import { FetchMapClinicsAction } from './types'

export function* sagaGetMapClinicsFetch(
  action: FetchMapClinicsAction
): SagaIterator<void> {
  try {
    const { campaignId } = action.payload
    const clinics = yield call(getMapClinics, campaignId)
    yield put(actions.getMapClinicsSuccess(clinics))
  } catch (e) {
    console.error(e)
    yield put(actions.getMapClinicsError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getMapClinicsFetch.type, sagaGetMapClinicsFetch),
]

import { FilterList } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { StyledFilterButton } from './styles'

const FilterManagerButton = (props: {
  menuItemsList?: {
    filterName: string
    onClick: () => void
  }[]
}) => {
  const { menuItemsList } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <div>
        <StyledFilterButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          size="large"
          startIcon={<FilterList />}
        >
          Filtrar
        </StyledFilterButton>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItemsList?.map((item) => (
          <MenuItem
            onClick={() => {
              item.onClick()
              handleClose()
            }}
            key={item.filterName}
          >
            {item.filterName}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default FilterManagerButton

import { CircularProgress, Grid, InputAdornment, Button } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import CampaignCard from '../../components/elements/CampaignCard'
import {
  ButtonContainer,
  LoadingCampaignContainer,
  StyleButtonViewMore,
} from './styles'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../state/store'
import { actions } from '../../state'
import ptBR from 'date-fns/locale/pt-BR'
import { StyledTextField } from '../../utils/sharedStyles'
import { Search, Visibility, VisibilityOff } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'
import { mainTheme } from '../../styles/mainTheme'

function Campaign() {
  const loading = useSelector((state) => state.campaigns.isLoading)
  const campaingsArray = useSelector((state) => state.campaigns.campaigns)
  const campaingsTotal = useSelector((state) => state.campaigns.total)
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({
    page: 0,
  })
  const limit = useSelector((state) => state.campaigns.limit)
  const [inputValue, setInputValue] = useState('')
  const shouldRenderLoadMoreContainer =
    campaingsArray.length < campaingsTotal! && campaingsArray.length > 0
  const [showEndedCampaigns, setShowEndedCampaigns] = useState(false)

  const debounceText = useMemo(
    () =>
      _.debounce((value: string) => {
        setInputValue(value)
        setPagination({
          page: 0,
        })
      }, 1000),
    []
  )

  const getCampaigns = () => {
    dispatch(
      actions.campaigns.getCampaignsFetch({
        page: pagination.page,
        limit: limit,
        sort: 'name',
        sortOrder: 'asc',
        searchTerm: inputValue,
        showEndedCampaigns: showEndedCampaigns,
      })
    )
  }

  useEffect(() => {
    setPagination({
      page: 0,
    })
  }, [showEndedCampaigns])

  useEffect(() => {
    if (!pagination.page) {
      dispatch(actions.campaigns.resetCampaigns())
    }
    getCampaigns()
  }, [pagination])

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <StyledTextField
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => {
              debounceText(ev.target.value)
            }}
            fullWidth
            placeholder="Buscar por nome da campanha ou cupom"
            sx={{
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  border: `2px solid ${mainTheme.palette.primary.main}`,
                },
              },
            }}
          />
          <Grid item xs={12}>
            <ButtonContainer>
              <Button
                variant="outlined"
                startIcon={
                  !showEndedCampaigns ? <Visibility /> : <VisibilityOff />
                }
                onClick={() => setShowEndedCampaigns(!showEndedCampaigns)}
              >
                {!showEndedCampaigns
                  ? 'Mostar Encerradas'
                  : 'Ocultar Encerradas'}
              </Button>
            </ButtonContainer>
          </Grid>
          {loading && campaingsArray.length === 0 ? (
            <LoadingCampaignContainer>
              <CircularProgress color="primary" size={40} />
            </LoadingCampaignContainer>
          ) : (
            campaingsArray.map((campaign) => (
              <Grid key={campaign._id} item xs={12} md={6}>
                <CampaignCard
                  id={campaign._id!}
                  name={campaign.name!}
                  status={campaign.status!}
                  startDate={format(new Date(campaign.startDate!), 'P', {
                    locale: ptBR,
                  })}
                  endDate={format(new Date(campaign.endDate!), 'P', {
                    locale: ptBR,
                  })}
                  voucherTotal={campaign.voucherQuantity!}
                  clinicTotal={campaign.clinicsQuantity!}
                  cityTotal={campaign.cityQuantity!}
                  usedPercentage={campaign.consumptionPercentage!}
                />
              </Grid>
            ))
          )}
        </Grid>
      </div>

      <div>
        <StyleButtonViewMore>
          {shouldRenderLoadMoreContainer && (
            <div>
              {!loading ? (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  startIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    setPagination({
                      page: pagination.page + 1,
                    })
                  }}
                >
                  Ver Mais
                </Button>
              ) : (
                <CircularProgress color="primary" size={40} />
              )}
            </div>
          )}
        </StyleButtonViewMore>
      </div>
    </div>
  )
}

export default Campaign

import { Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 0;
`

export const DateItem = styled(Typography)`
  color: ${mainTheme.palette.grey[300]};
  fontweight: bold;
`

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { sendEmailPasswordReset } from '../../../config/firebase'
import { mainTheme } from '../../../styles/mainTheme'
import { StyledTextfield } from '../LoginForm/styles'

const ForgetPasswordPopup = (props: {
  open: boolean
  handleClose: () => void
}) => {
  const [email, setEmail] = useState('')
  const { open, handleClose } = props

  const handleSubmit = async () => {
    try {
      await sendEmailPasswordReset(email)

      toast('E-mail para redefinir senha enviado!', { type: 'success' })
    } catch (error) {
      toast(String(error), { type: 'error' })
    } finally {
      handleClose()
    }
  }

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const email = event.target.value
    setEmail(email)
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Redefinir senha?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Um E-mail para redefinir sua senha será enviado ao E-mail:
        </DialogContentText>
        <StyledTextfield
          id="outlined-basic"
          label="E-mail"
          type="email"
          variant="outlined"
          style={{ width: '100%', marginTop: mainTheme.spacing(2) }}
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Enviar E-mail
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ForgetPasswordPopup

import { Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { CloudUpload } from '@mui/icons-material'

import { useDropzone } from 'react-dropzone'
import { DropzoneContainer } from './styles'

const CustomDropzone = (props: {
  file?: File
  setFile: (file: File) => void
}) => {
  const { file, setFile } = props
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <CloudUpload color="primary" fontSize="large" />
      {file ? (
        <Typography variant="body1">
          Arquivo selecionado: {file.name}
        </Typography>
      ) : (
        <Typography variant="body1">
          Arraste o arquivo ou clique para selecionar
        </Typography>
      )}
    </DropzoneContainer>
  )
}

export default CustomDropzone

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { actions } from '../../../state'
import { useSelector } from '../../../state/store'

const Errors = () => {
  const dispatch = useDispatch()
  const errors = useSelector((state) => state.errors)

  useEffect(() => {
    errors.forEach((error) => {
      toast(error.errorMessage, { type: 'error' })
      dispatch(actions.errors.popError())
    })
  }, [errors])

  return null
}

export default Errors

import { Paper, TableCell, TableRow } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const TablePaper = styled(Paper)`
  overflow: none;
  padding: ${mainTheme.spacing(3)}px;
`

export const LoadingRow = styled(TableRow)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${mainTheme.spacing(1)}px;
`

export const StyledTableCellHeader = styled(TableCell)`
  color: ${mainTheme.palette.grey[400]};
  font-weight: bold;
  border-color: ${mainTheme.palette.grey[500]};
  max-width: 80px;
`
export const StyledTableCellBody = styled(TableCell)`
  border-color: ${mainTheme.palette.grey[500]};
  @media (max-width: 900px) {
    overflow: scroll;
  }
`
export const TableContainer = styled('div')`
  width: 100%;
  @media (max-width: 900px) {
    overflow: scroll;
  }
`
export const VoucherTableHeader = styled('div')`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const TableFooter = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-top: ${mainTheme.spacing(3)}px;
`

export const TableFooterContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: ${mainTheme.spacing(1)}px;
`

/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RolesEnum } from '@vacinas-net/shared'
import { AuthState } from './types'

export type SetRolesAction = PayloadAction<RolesEnum[]>
export type SetIsLoggedAction = PayloadAction<boolean>

const initialState: AuthState = {
  isLogged: false,
  roles: [],
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogged: (state, data: SetIsLoggedAction) => {
      state.isLogged = data.payload
    },
    setRoles: (state, data: SetRolesAction) => {
      state.roles = data.payload
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

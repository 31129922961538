import { CircularProgress } from '@mui/material'
import React from 'react'

const LoadingContainer = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="primary" size={40} />
    </div>
  )
}

export default LoadingContainer

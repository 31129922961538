import { Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const CouponContainer = styled('div')`
  border: 2px solid ${mainTheme.palette.primary.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CouponText = styled(Typography)`
  margin: ${mainTheme.spacing(1)}px;
  overflow: auto;
`

import React from 'react'
import { Button, Grid } from '@mui/material'
import { useSelector } from '../../../../state/store'

import { useDispatch } from 'react-redux'
import {
  actions,
  CreateCampaignOrderStateOrder,
} from '../../../../state/createCampaignOrder'
import { cpf } from 'cpf-cnpj-validator'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'
import { isValid } from 'date-fns'
import { useNavigate } from 'react-router-dom'

const SubmitButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;

  ${mainTheme.breakpoints.up('md')} {
    position: relative;
    bottom: 0px;
    right: 0px;
  }
`

const SubmitForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )

  const checkRequiredInputs = () => {
    const errors: Partial<CreateCampaignOrderStateOrder> = {}
    const keysToSkip: Array<keyof CreateCampaignOrderStateOrder> = [
      'placeName',
      'availableAttendanceId',
    ]
    const keys = Object.keys(formData) as Array<
      keyof CreateCampaignOrderStateOrder
    >

    keys
      .filter((key) => !keysToSkip.includes(key))
      .forEach((key) => {
        if (formData[key]) {
          errors[key] = ''
        } else {
          errors[key] = 'Campo obrigatório'
        }
      })

    return errors as CreateCampaignOrderStateOrder
  }

  const validateOrder = () => {
    const hasValidEmail = formData.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    const hasValidDocument = cpf.isValid(formData.document)
    const phoneValue = formData.phone.replace(/[^\d]/g, '')
    const hasValidPhone = phoneValue.length === 11
    const hasValidBirthdate = isValid(formData.birthdate)

    const invalidMessage = 'Campo inválido'

    const errors: CreateCampaignOrderStateOrder = {
      document: hasValidDocument ? '' : 'CPF inválido',
      email: hasValidEmail ? '' : invalidMessage,
      phone: hasValidPhone ? '' : invalidMessage,
      availableAttendanceId: '',
      birthdate: hasValidBirthdate ? '' : invalidMessage,
      fullName: '',
      placeName: '',
    }

    return errors
  }

  const submit = () => {
    let isValid = true
    const newErrors: Partial<CreateCampaignOrderStateOrder> = {}
    const requiredErrors = checkRequiredInputs()
    const validationErrors = validateOrder()

    const keys = Object.keys(formData)

    keys.forEach((key) => {
      const indexKey = key as keyof CreateCampaignOrderStateOrder
      const errorMessage =
        requiredErrors[indexKey] || validationErrors[indexKey]
      newErrors[indexKey] = errorMessage
      if (errorMessage) isValid = false
    })

    dispatch(actions.setCreateOrderFormErrors(newErrors))

    if (!isValid) return
    dispatch(actions.setCreateOrderFormStep('place'))
  }

  const goBack = () => {
    navigate('/campaign/order/list')
  }

  return (
    <Grid
      item
      xs={12}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      marginBottom={4.5}
      gap={2}
    >
      <Button onClick={() => goBack()} variant="text" size="small">
        {'< Voltar'}
      </Button>
      <SubmitButton onClick={() => submit()} variant="contained" size="large">
        Próximo
      </SubmitButton>
    </Grid>
  )
}

export default SubmitForm

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  Button,
  CircularProgress,
} from '@mui/material'
import { CustomerAttendanceItem, IPatient } from '@vacinas-net/shared'
import React, { useState } from 'react'
import { useSelector } from '../../../state/store'
import { mainTheme } from '../../../styles/mainTheme'
import _ from 'lodash'
import { StyledTextFieldSquare } from '../../../utils/sharedStyles'
import {
  ButtonContainer,
  DoubleInputsContainer,
  FormContainer,
  PatientChipContainer,
} from './styles'
import ReactInputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state'
import { useParams } from 'react-router-dom'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { format } from 'date-fns'

interface PatientData {
  name?: string
  birthDate?: string
  document?: string
  email?: string
  phoneNumber?: string
}

const CustomerAttendanceFormInputChip = (props: {
  label: string
  handleClick: () => void
}) => {
  const { label, handleClick } = props
  return (
    <Chip
      style={{ marginRight: mainTheme.spacing(1) }}
      color="primary"
      label={label}
      onClick={handleClick}
    />
  )
}

export const dateToCorrectISOString = (date: string) => {
  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  const timeZone = getTimeZone()
  const zonedTime = utcToZonedTime(date, timeZone)
  return zonedTime.toISOString()
}

const AttendanceItemPopUp = (props: {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  item: CustomerAttendanceItem
}) => {
  const { openModal, setOpenModal, item } = props
  const [formData, setFormData] = useState<PatientData>(
    item.patient
      ? {
          name: item.patient.name,
          phoneNumber: item.patient.phoneNumber,
          document: item.patient.document,
          email: item.patient.email,
          birthDate: format(new Date(item.patient.birthDate!), 'yyyy-MM-dd'),
        }
      : {}
  )
  const dispatch = useDispatch()
  const params = useParams()
  const isMakingRequest = useSelector(
    (state) => state.customerAttendance.isRegisteringPatient
  )

  const parseFormValues = () => {
    return {
      ...formData,
      birthDate: new Date(dateToCorrectISOString(formData.birthDate!)),
    }
  }

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(
      actions.customerAttendance.registerPatient({
        patient: parseFormValues(),
        orderItemId: item.orderItemId!,
        attendanceUUID: params.attendanceUUID!,
      })
    )
  }

  const getAllPatientsData = () => {
    const data: IPatient[] = []

    const attendanceItems = useSelector(
      (state) => state.customerAttendance.attendance.itemList
    )

    if (!attendanceItems) {
      return []
    }
    attendanceItems.forEach((item) => {
      if (item.patient?.name) {
        data.push(item.patient)
      }
    })

    return _.uniqBy(data, 'name')
  }

  const isSaveButtonDisabled = () => {
    if (formData.name && formData.birthDate && formData.document) {
      return false
    }
    return true
  }
  return (
    <Dialog
      maxWidth={'md'}
      open={openModal}
      onClose={() => {
        if (!item.patient) {
          setFormData({})
        }
        setOpenModal(false)
      }}
    >
      <DialogTitle color="primary">Registro de Paciente</DialogTitle>
      <DialogContent>
        <form onSubmit={submitForm}>
          <FormContainer>
            {getAllPatientsData().length > 0 && (
              <PatientChipContainer>
                {getAllPatientsData().map((patient) => {
                  return (
                    <div
                      key={patient.name}
                      style={{
                        marginRight: mainTheme.spacing(1),
                      }}
                    >
                      <CustomerAttendanceFormInputChip
                        label={patient.name!}
                        handleClick={() => {
                          setFormData({
                            name: patient.name,
                            phoneNumber: patient.phoneNumber,
                            document: patient.document,
                            email: patient.email,
                            birthDate: format(
                              new Date(patient.birthDate!),
                              'yyyy-MM-dd'
                            ),
                          })
                        }}
                      />
                    </div>
                  )
                })}
              </PatientChipContainer>
            )}
            <StyledTextFieldSquare
              color="primary"
              label="Nome"
              size="small"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  name: event.target.value,
                })
              }}
              style={{
                marginTop: mainTheme.spacing(2),
              }}
              InputLabelProps={{ shrink: Boolean(formData.name) }}
            />
            <DoubleInputsContainer>
              <ReactInputMask
                mask="999.999.999-99"
                disabled={false}
                value={formData.document}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    document: event.target.value,
                  })
                }}
              >
                {() => (
                  <StyledTextFieldSquare
                    color="primary"
                    label="CPF"
                    size="small"
                    variant="outlined"
                    fullWidth
                    style={{ marginRight: mainTheme.spacing(1) }}
                    InputLabelProps={{ shrink: Boolean(formData.document) }}
                  />
                )}
              </ReactInputMask>
              <StyledTextFieldSquare
                type="date"
                color="primary"
                label="Data de Nascimento"
                size="small"
                variant="outlined"
                fullWidth
                value={formData.birthDate}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    birthDate: event.target.value,
                  })
                }}
                InputLabelProps={{ shrink: true }}
                style={{ marginLeft: mainTheme.spacing(1) }}
              />
            </DoubleInputsContainer>
            <DoubleInputsContainer>
              <StyledTextFieldSquare
                color="primary"
                label="Email"
                size="small"
                variant="outlined"
                fullWidth
                value={formData.email}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }}
                style={{ marginRight: mainTheme.spacing(1) }}
                InputLabelProps={{ shrink: Boolean(formData.email) }}
              />
              <ReactInputMask
                mask="(99) 99999-9999"
                disabled={false}
                value={formData.phoneNumber}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    phoneNumber: event.target.value,
                  })
                }}
              >
                {() => (
                  <StyledTextFieldSquare
                    color="primary"
                    label="Telefone"
                    size="small"
                    variant="outlined"
                    fullWidth
                    style={{ marginLeft: mainTheme.spacing(1) }}
                    InputLabelProps={{ shrink: Boolean(formData.phoneNumber) }}
                  />
                )}
              </ReactInputMask>
            </DoubleInputsContainer>
            <ButtonContainer>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                color="primary"
                disabled={isSaveButtonDisabled()}
              >
                {isMakingRequest ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  'Salvar'
                )}
              </Button>
            </ButtonContainer>
          </FormContainer>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AttendanceItemPopUp

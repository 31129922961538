import * as React from 'react'
import { Button } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useState, useEffect } from 'react'

const RedirectButtons = () => {
  const [showElement, setShowElement] = useState<boolean>()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    if (location.pathname === `/campaign` || location.pathname === '/order') {
      setShowElement(false)
    } else {
      setShowElement(true)
    }
  }, [location])

  const redirect = () => {
    if (location.pathname === `/campaign`) {
    } else if (location.pathname === `/campaign/${params?.campaignId}`) {
      navigate(`/campaign`)
    } else if (
      location.pathname === `/campaign/${params?.campaignId}/acceptance` ||
      location.pathname === `/campaign/${params?.campaignId}/voucherReport` ||
      location.pathname === `/campaign/${params?.campaignId}/orderReport`
    ) {
      navigate(`/campaign/${params?.campaignId}`)
    }
  }

  return (
    <div>
      {showElement && (
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          startIcon={<ArrowBackIosIcon />}
          onClick={redirect}
        >
          Voltar
        </Button>
      )}
    </div>
  )
}

export default RedirectButtons

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import ReactInputMask from 'react-input-mask'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../../../state/store'
import { actions } from '../../../../../state/createCampaignOrder'
import styled from 'styled-components'
import { mainTheme } from '../../../../../styles/mainTheme'
import { valueInCentsToBRL } from '../../../../../utils/utils'
import { StyledSelectSquare } from '../../../../../utils/sharedStyles'
import Cards, { Focused } from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import './styles.css'

const StyledTextFieldSquare = styled(TextField)`
  margin-top: ${mainTheme.spacing(2)}px;
  width: 100%;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #b5b5b5;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000000;
  }
`

const FormBackgroundGrid = styled(Grid)`
  background-color: white;
  padding: ${mainTheme.spacing(2)}px;
`

const DesktopHiddenGrid = styled(Grid)`
  ${mainTheme.breakpoints.up('md')} {
    display: none;
  }
`

const MobileHiddenGrid = styled(Grid)`
  ${mainTheme.breakpoints.down(960)} {
    display: none;
  }
`

const CreditCardForm = () => {
  const dispatch = useDispatch()
  const [creditCardSnippetFocus, setCreditCardSnippetFocus] =
    useState<Focused>('')
  const errors = useSelector(
    (state) => state.createCampaignOrder.iuguCreditCardFormDataErrors
  )
  const formData = useSelector(
    (state) => state.createCampaignOrder.iuguCreditCardFormData
  )
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)

  const [installmentsArray, setInstallmentsArray] = useState<number[]>([])

  useEffect(() => {
    if (
      campaign?.productPriceInCents &&
      campaign?.requiredCustomerPaymentInCents
    ) {
      const installments = []
      for (let index = 0; index < 12; index++) {
        installments.push(
          campaign?.requiredCustomerPaymentInCents / (index + 1)
        )
      }
      setInstallmentsArray(installments)
      dispatch(
        actions.setIuguCreditCardFormData({
          ...formData,
          installmentsNumber: formData?.installmentsNumber || 1,
        })
      )
    }
  }, [])

  const getCreditCardMask = (currentValue: string) => {
    if (currentValue.match(/^3(0[0-5]|[68]\d)/)) {
      return '9999 999999 9999'
    }
    if (currentValue.match(/^3[47]/)) {
      return '9999 999999 99999'
    }

    return '9999 9999 9999 9999'
  }

  return (
    <FormBackgroundGrid container xs={12}>
      <Grid container xs={12} md={6}>
        <Typography variant="body1" color="primary">
          <strong>Dados do cartão de crédito</strong>
        </Typography>
        <DesktopHiddenGrid
          marginTop={'16px'}
          marginBottom={'16px'}
          item
          xs={12}
        >
          <Cards
            number={formData?.number || ''}
            expiry={formData?.expiration || ''}
            cvc={formData?.verification_value || ''}
            name={formData?.full_name || ''}
            focused={creditCardSnippetFocus}
            placeholders={{
              name: 'SEU NOME',
            }}
            locale={{
              valid: 'Válido até',
            }}
          />
        </DesktopHiddenGrid>
        <Grid item xs={12}>
          <ReactInputMask
            mask={getCreditCardMask(formData!.number)}
            value={formData?.number}
            onFocus={() => setCreditCardSnippetFocus('number')}
            onChange={(event) => {
              dispatch(
                actions.setIuguCreditCardFormData({
                  ...formData,
                  number: event.target.value.replace(/[^\d]/g, ''),
                })
              )
              dispatch(
                actions.setIuguCreditCardFormDataErrors({
                  ...errors,
                  number: '',
                })
              )
            }}
          >
            {() => (
              <StyledTextFieldSquare
                color="primary"
                label="Número do Cartão"
                size="small"
                variant="outlined"
                fullWidth
                error={!!errors?.number}
                placeholder="Número do Cartão"
              />
            )}
          </ReactInputMask>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors?.number}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTextFieldSquare
            color="primary"
            label="Nome impresso no Cartão"
            size="small"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: Boolean(formData?.full_name) }}
            error={!!errors?.full_name}
            value={formData?.full_name}
            onFocus={() => setCreditCardSnippetFocus('name')}
            onChange={(event) => {
              dispatch(
                actions.setIuguCreditCardFormData({
                  ...formData,
                  full_name: event.target.value,
                })
              )
              dispatch(
                actions.setIuguCreditCardFormDataErrors({
                  ...errors,
                  full_name: '',
                })
              )
            }}
          />
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors?.full_name}
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <Grid paddingRight={`${mainTheme.spacing(1)}px`} item xs={6}>
            <ReactInputMask
              mask="99/99"
              value={formData?.expiration}
              onFocus={() => setCreditCardSnippetFocus('expiry')}
              onChange={(event) => {
                dispatch(
                  actions.setIuguCreditCardFormData({
                    ...formData,
                    expiration: event.target.value,
                  })
                )
                dispatch(
                  actions.setIuguCreditCardFormDataErrors({
                    ...errors,
                    expiration: '',
                  })
                )
              }}
            >
              {() => (
                <StyledTextFieldSquare
                  color="primary"
                  label="Validade"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: Boolean(formData?.expiration) }}
                  error={!!errors?.expiration}
                />
              )}
            </ReactInputMask>
            <Typography color="error" variant="body2" marginTop={0.5}>
              {errors?.expiration}
            </Typography>
          </Grid>

          <Grid paddingLeft={`${mainTheme.spacing(1)}px`} item xs={6}>
            <StyledTextFieldSquare
              type="number"
              color="primary"
              label="CVV"
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: Boolean(formData?.verification_value),
              }}
              error={!!errors?.verification_value}
              value={formData?.verification_value}
              onFocus={() => setCreditCardSnippetFocus('cvc')}
              onBlur={() => setCreditCardSnippetFocus('number')}
              onChange={(event) => {
                dispatch(
                  actions.setIuguCreditCardFormData({
                    ...formData,
                    verification_value: event.target.value,
                  })
                )
                dispatch(
                  actions.setIuguCreditCardFormDataErrors({
                    ...errors,
                    verification_value: '',
                  })
                )
              }}
            />
            <Typography color="error" variant="body2" marginTop={0.5}>
              {errors?.verification_value}
            </Typography>
          </Grid>
        </Grid>
        <Grid marginTop={`${mainTheme.spacing(2)}px`} item xs={12}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors?.installmentsNumber}
          >
            <InputLabel>Opções de parcelamento</InputLabel>
            <StyledSelectSquare
              value={formData?.installmentsValue}
              onChange={(e) => {
                dispatch(
                  actions.setIuguCreditCardFormData({
                    ...formData,
                    installmentsNumber: (installmentsArray.findIndex(
                      (installment) => installment === e.target.value
                    ) + 1) as number,
                    installmentsValue: e.target.value,
                  })
                )
              }}
              label="Opções de parcelamento"
              variant="outlined"
              color="primary"
              defaultValue={campaign?.requiredCustomerPaymentInCents}
            >
              {installmentsArray.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {`${index + 1}x de ${valueInCentsToBRL(item)}`}
                </MenuItem>
              ))}
            </StyledSelectSquare>
          </FormControl>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors?.installmentsNumber}
          </Typography>
        </Grid>
      </Grid>
      <MobileHiddenGrid
        marginTop={`${mainTheme.spacing(5)}px`}
        container
        md={6}
      >
        <Cards
          number={formData?.number || ''}
          expiry={formData?.expiration || ''}
          cvc={formData?.verification_value || ''}
          name={formData?.full_name || ''}
          focused={creditCardSnippetFocus}
          placeholders={{
            name: 'SEU NOME',
          }}
          locale={{
            valid: 'Válido até',
          }}
        />
      </MobileHiddenGrid>
    </FormBackgroundGrid>
  )
}

export default CreditCardForm

import React from 'react'
import LoginForm from '../../../components/elements/LoginForm'
import { VacinasLogo } from '../Shared/styles'
import {
  LoginLogoContainer,
  LoginPageDesktopContainer,
  StyledPaper,
} from './styles'
import LogoVacinas from '../../../assets/VacinasLogoNoName.svg'
import LogoLogin from '../../../assets/LogoLogin.svg'
import MediaFooter from '../../../components/elements/MediaFooter'

const DesktopLoginLayout = () => {
  return (
    <LoginPageDesktopContainer>
      <StyledPaper elevation={12}>
        <VacinasLogo>
          <img alt="Logo Vacinas.net" src={LogoVacinas} />
        </VacinasLogo>
        <LoginForm />
        <MediaFooter />
      </StyledPaper>
      <LoginLogoContainer>
        <img
          width={'75%'}
          alt="Conectamos você a quem faz vacinação. Vacinas em casa ou em clínicas parceiras"
          src={LogoLogin}
        />
      </LoginLogoContainer>
    </LoginPageDesktopContainer>
  )
}

export default DesktopLoginLayout

import React from 'react'
import ClinicMap from '../../components/elements/ClinicMap'
import { AcceptanceContainer } from './styles'

const Acceptance = () => {
  return (
    <AcceptanceContainer>
      <ClinicMap />
    </AcceptanceContainer>
  )
}

export default Acceptance

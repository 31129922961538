import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
  TableContainer,
  TableFooterContainer,
  TablePaper,
  VoucherTableHeader,
} from './styles'
import VouchersTableChip from '../chips/VouchersTableChip'
import { mainTheme } from '../../../styles/mainTheme'
import { CloudDownload, MoreVert } from '@mui/icons-material'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state'
import { valueInCentsToBRL } from '../../../utils/utils'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale'
import AdditionalVouchers from '../AdditionalVouchers'
import AddCustomers from '../AddCustomers/AddCustomers'
import AddSingleCustomer from '../AddSingleCustomer'

const VouchersTable = () => {
  const params = useParams()
  const campaign = useSelector((state) => state.campaigns.campaignDetails)
  const handler = campaign?.handler

  const isDownloadSingleVoucherPackage = useSelector(
    (state) => state.vouchers.isDownloadingSingleVoucher
  )
  const downloadingAllVouchers = useSelector(
    (state) => state.vouchers.isDownloadingAllVouchers
  )
  const loading = useSelector((state) => state.vouchers.isLoading)
  const vouchersArray = useSelector((state) => state.vouchers.vouchers)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const downloadAllVouchers = () => {
    dispatch(
      actions.vouchers.downloadAllVouchers({
        campaignId: params.campaignId!,
      })
    )
  }

  const downloadSingleVoucherPackage = (voucherId: string) => {
    dispatch(
      actions.vouchers.downloadSingleVoucherPackage({
        campaignId: params.campaignId!,
        voucherPackageId: voucherId,
      })
    )
  }

  const getVouhcers = () => {
    dispatch(
      actions.vouchers.getVouchersFetch({
        campaignId: params.campaignId!,
      })
    )
  }

  const getPercentageUsedVouchers = (
    approvedQuantity: number,
    usedTotal: number
  ) => {
    return Boolean(approvedQuantity)
      ? Math.trunc((usedTotal! / approvedQuantity!) * 100)
      : '0'
  }

  const formatDate = (date: string) => {
    return format(new Date(date), 'P', { locale: ptBR })
  }

  useEffect(() => {
    getVouhcers()
  }, [])

  return (
    <TablePaper>
      <VoucherTableHeader>
        <Typography
          style={{
            paddingTop: mainTheme.spacing(1 + 1 / 2),
            marginLeft: mainTheme.spacing(2),
            marginBottom: mainTheme.spacing(4),
          }}
          variant="h2"
        >
          {handler === 'jenner' ? `Listas de beneficiários e Vales` : `Vales`}
        </Typography>

        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            disabled={downloadingAllVouchers}
            onClick={() => {
              downloadAllVouchers()
              handleClose()
            }}
          >
            Exportar todos os vales
          </MenuItem>
        </Menu>
      </VoucherTableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left">
                Data de solicitação
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Data de aprovação
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Qtd. Solicitada
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Qtd. Disponibilizada
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                % Vales utilizados
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Data de validade
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Valor
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Status
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Vales
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              vouchersArray.map((voucher) => {
                return (
                  <TableRow key={voucher._id}>
                    <StyledTableCellBody align="left">
                      {formatDate(String(voucher.createdAt!))}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {(voucher.approvalDate &&
                        formatDate(String(voucher.approvalDate!))) ||
                        '-'}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {voucher.requestedQuantity}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {voucher.approvedQuantity}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {getPercentageUsedVouchers(
                        voucher.approvedQuantity!,
                        voucher.usedTotal!
                      )}
                      %
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {(voucher.expirationDate &&
                        formatDate(String(voucher.expirationDate!))) ||
                        '-'}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {valueInCentsToBRL(voucher.voucherValueInCents!)}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {<VouchersTableChip status={voucher.status!} />}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      <IconButton
                        onClick={() => {
                          downloadSingleVoucherPackage(voucher._id!)
                        }}
                        color="primary"
                        size="large"
                        disabled={
                          isDownloadSingleVoucherPackage ||
                          voucher.status !== 'approved'
                        }
                      >
                        <CloudDownload />
                      </IconButton>
                    </StyledTableCellBody>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <CircularProgress
                  style={{ padding: mainTheme.spacing(2) }}
                  size={30}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TableFooter>
          {handler !== 'jenner' ? (
            <AdditionalVouchers />
          ) : (
            <TableFooterContainer>
              <AddCustomers />
              <AddSingleCustomer />
            </TableFooterContainer>
          )}
        </TableFooter>
      </TableContainer>
    </TablePaper>
  )
}

export default VouchersTable

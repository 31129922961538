import { Button, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions } from '../../../state'
import { useSelector } from '../../../state/store'
import CustomDialog from '../CustomDialog'
import { AdditionalVouchersContainer, StyledTextField } from './styles'

const AdditionalVouchers = () => {
  const loading = useSelector(
    (state) => state.vouchers.isRequestingAdditionalVouchers
  )
  const [quantity, setQuantity] = useState<number>()
  const [openDialog, setOpenDialog] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()

  return (
    <div>
      <AdditionalVouchersContainer>
        <StyledTextField
          label="Vales Adicionais"
          type="number"
          variant="outlined"
          color="primary"
          value={quantity}
          onChange={(event) => {
            setQuantity(parseFloat(event.target.value))
          }}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          onClick={() => {
            setOpenDialog(true)
          }}
          size="large"
          variant="contained"
          color="primary"
          disabled={!quantity}
        >
          {loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            'Solicitar'
          )}
        </Button>
      </AdditionalVouchersContainer>
      {/* Add tests and state management */}
      <CustomDialog
        open={openDialog}
        title="Você está solicitando vales adicionais!"
        message={
          'Vales adicionais serão cobrados normalmente caso forem consumidos.\n Deseja confirmar essa ação?'
        }
        closeDialog={() => {
          setOpenDialog(false)
        }}
        onConfirm={() => {
          dispatch(
            actions.vouchers.requestAdditionalPackage({
              campaignId: params.campaignId!,
              quantity: quantity!,
            })
          )
          setOpenDialog(false)
        }}
      />
    </div>
  )
}

export default AdditionalVouchers

//import { Button } from '@mui/material'
import { TextField, Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const LoginContainer = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding-left: ${mainTheme.spacing(3)}px;
  padding-right: ${mainTheme.spacing(3)}px;
`
export const TextFieldWrapper = styled('div')`
  margin-bottom: ${mainTheme.spacing(2)}px;
  width: 100%;
`

export const ButtonContainer = styled('div')`
  margin-top: ${mainTheme.spacing(5)}px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const TitleTypographyWrapper = styled('div')`
  margin-bottom: ${mainTheme.spacing(4)}px;
  align-self: flex-start;
`

export const FirstAccessTypographyWrapper = styled('div')`
  margin-bottom: ${mainTheme.spacing(1)}px;
  align-self: flex-start;
`

export const ForgotPasswordTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  padding-top: ${mainTheme.spacing(1)}px;
`

export const WrongMailTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  padding-top: ${mainTheme.spacing(1)}px;
  margin-left: auto;
`

export const ClickableTypography = styled(Typography)`
  cursor: pointer;
`

export const PaddedTypography = styled(Typography)`
  margin-top: ${mainTheme.spacing(5)}px;
`
export const PaddedTypography2 = styled(Typography)`
  margin-top: ${mainTheme.spacing(2)}px;
`

export const StyledTextfield = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d3d3;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000000;
  }
`

import { CancelOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { StyledTextFilter, TextFilterContainer } from './styles'

const TextFieldFilter = (props: {
  open: boolean
  label?: string
  initialValue?: string
  dispatchAction: (value: string) => void
  closeFilter: () => void
}) => {
  const { label, initialValue, dispatchAction, open, closeFilter } = props
  const [value, setValue] = useState(initialValue || '')

  const debounceText = useMemo(
    () =>
      _.debounce((value: string) => {
        dispatchAction(value)
      }, 1000),
    []
  )

  return open ? (
    <TextFilterContainer>
      <IconButton
        onClick={() => {
          setValue('')
          dispatchAction('')
          closeFilter()
        }}
      >
        <CancelOutlined fontSize="medium" />
      </IconButton>
      <StyledTextFilter
        size="small"
        color="primary"
        variant="outlined"
        label={label}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
          debounceText(event.target.value)
        }}
      />
    </TextFilterContainer>
  ) : (
    <></>
  )
}

export default TextFieldFilter

import { Typography } from '@mui/material'
import { ListClientOrderDTO } from '@vacinas-net/shared'
import React from 'react'
import { mainTheme } from '../../../styles/mainTheme'
import CampaignOrderStatusChip from '../chips/CampaignOrdersStatusChip'
import LabeledItem from '../LabeledItem'
import OrderStatusStepper from '../OrderStatusStepper'
import {
  CardHeader,
  CustomerDataContainer,
  ItemContainer,
  Link,
  OrderCardContainer,
} from './styles'
import ShotDataField from '../ShotDataField'

const OrderCard = (props: { order: ListClientOrderDTO }) => {
  const { order } = props
  return (
    <OrderCardContainer>
      <CardHeader>
        <Typography variant="h2">Pedido {order.orderId}</Typography>
        <CampaignOrderStatusChip status={order.status} />
      </CardHeader>
      <CustomerDataContainer>
        <LabeledItem
          title={`Nome Comprador:`}
          content={`${order.customer.name}`}
        />
        <div style={{ marginRight: mainTheme.spacing(4) }}></div>
        <LabeledItem
          title={`CPF Comprador:`}
          content={`${order.customer.document}`}
        />
      </CustomerDataContainer>
      <Typography variant="h2">Vacinas</Typography>
      {order.items &&
        order.items.map((item) => {
          return (
            <ItemContainer key={item.orderItemId}>
              <Typography style={{ minWidth: 250 }} variant="body1">
                {item.product?.name}
              </Typography>
              <div style={{ minWidth: 150, textAlign: 'left' }}>
                {item.patient ? (
                  <Typography variant="body2">{item.patient?.name}</Typography>
                ) : (
                  <Link
                    onClick={() => console.log('TODO')}
                    color="primary"
                    variant="body2"
                  >
                    Registrar paciente
                  </Link>
                )}
              </div>
              <ShotDataField data={item.statusData} />
              <div>
                <OrderStatusStepper step={item.statusData.status} />
              </div>
            </ItemContainer>
          )
        })}
    </OrderCardContainer>
  )
}

export default OrderCard

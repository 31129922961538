/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICampaignV2Client, ListClientOrderDTO } from '@vacinas-net/shared'
import { CustomerOrdersState } from './types'

export type SetOrdersAction = PayloadAction<ListClientOrderDTO[]>

const initialState: CustomerOrdersState = {
  orders: [],
  isLoading: true,
  limit: 20,
}

export const slice = createSlice({
  name: 'customerOrders',
  initialState,
  reducers: {
    getOrdersFetch: (state, action) => {
      const { limit, sortOrder, sort } = action.payload
      state.limit = limit
      state.sort = sort
      state.sortOrder = sortOrder
      state.isLoading = true
    },
    getOrdersSuccess: (state, data: SetOrdersAction) => {
      state.orders = [...state.orders, ...data.payload]
      state.isLoading = false
    },
    getOrdersError: (state) => {
      state.isLoading = false
    },
    setTotalOrders: (state, action) => {
      state.total = action.payload
    },
    resetOrders: (state) => {
      state.total = 0
      state.orders = []
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'

export const FilterInputsContainer = styled('div')`
  display: flex;
  background-color: #ececec;
  padding: ${mainTheme.spacing(1)}px;
  margin-bottom: ${mainTheme.spacing(2)}px;
  @media (max-width: 900px) {
    overflow-x: scroll;
  }
`

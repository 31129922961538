/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CustomerAttendanceDTO,
  ICampaignV2Client,
  ListClientOrderDTO,
} from '@vacinas-net/shared'
import {
  CustomerAttendanceFetchAction,
  CustomerAttendanceState,
  RegisterPatientAction,
} from './types'

export type SetCustomerAttendanceSuccessAction =
  PayloadAction<CustomerAttendanceDTO>

const initialState: CustomerAttendanceState = {
  attendance: {},
  isLoading: true,
  isRegisteringPatient: false,
}

export const slice = createSlice({
  name: 'customerAttendance',
  initialState,
  reducers: {
    getCustomerAttendanceFetch: (
      state,
      action: CustomerAttendanceFetchAction
    ) => {
      state.isLoading = true
    },
    getCustomerAttendanceSuccess: (
      state,
      data: SetCustomerAttendanceSuccessAction
    ) => {
      state.attendance = data.payload
      state.isLoading = false
    },
    getCustomerAttendanceError: (state) => {
      state.isLoading = false
    },
    registerPatient: (state, action: RegisterPatientAction) => {
      state.isRegisteringPatient = true
    },
    registerPatientSuccess: (state) => {
      state.isRegisteringPatient = false
    },
    registerPatientError: (state) => {
      state.isRegisteringPatient = false
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

import React from 'react'
import { Grid, Typography } from '@mui/material'
import PersonalDataForm from './PersonalDataForm'
import SubmitForm from './SubmitForm'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'

const ScheduleOrderFormGrid = styled(Grid)`
  width: 100%;
  gap: ${mainTheme.spacing(2.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    max-width: 700px;
  }
`

const ScheduleOrderForm = () => {
  return (
    <ScheduleOrderFormGrid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="h1" marginBottom={1.5}>
          Falta pouco para agendar!
        </Typography>
        <Typography variant="h2" fontWeight={'normal'}>
          Preencha os dados de quem será vacinado. Lembre-se que o documento
          informado nesta etapa deverá obrigatoriamente ser apresentado no
          momento da vacinação.
          <br />
          <br />
          Em seguida, escolha o local, dia e horário para receber sua vacina.
        </Typography>
      </Grid>
      <PersonalDataForm />
      <SubmitForm />
    </ScheduleOrderFormGrid>
  )
}

export default ScheduleOrderForm

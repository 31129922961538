import { Grid } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'
import CreateCampaignOrderFooter from '../CreateCampaignOrderFooter'

export const HighlightTypography = styled.b`
  color: ${mainTheme.palette.primary.main};
`

export const ScheduleOrderFormGrid = styled(Grid)`
  width: 100%;
  gap: ${mainTheme.spacing(2.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    max-width: 700px;
  }
`

export const CreateCampaignOrderFooterStyled = styled(
  CreateCampaignOrderFooter
)`
  padding-bottom: 0px;
  margin-bottom: ${mainTheme.spacing(1.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    padding-bottom: 0px;
    margin-bottom: ${mainTheme.spacing(0)}px;
  }
`

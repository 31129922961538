import { CustomerAttendanceItem } from '@vacinas-net/shared'
import { AttendanceItemContainer, AttendanceItemDataCell } from './styles'
import React, { useState } from 'react'
import LabeledItem from '../LabeledItem'
import { format } from 'date-fns'
import { Button } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import AttendanceItemPopUp from './AttendanceItemPopUp'

const AttendanceItem = (props: { attendanceItem: CustomerAttendanceItem }) => {
  const { attendanceItem } = props
  const [openModal, setOpenModal] = useState(false)
  return (
    <AttendanceItemContainer>
      <AttendanceItemDataCell>
        <LabeledItem
          content={attendanceItem.product?.name || '-'}
          title={'Vacina'}
        />
      </AttendanceItemDataCell>
      <AttendanceItemDataCell>
        <LabeledItem
          content={attendanceItem.patient?.name || '-'}
          title={'Nome do paciente'}
        />
      </AttendanceItemDataCell>
      <AttendanceItemDataCell>
        <LabeledItem
          content={attendanceItem.patient?.document || '-'}
          title={'CPF'}
        />
      </AttendanceItemDataCell>
      <AttendanceItemDataCell>
        <LabeledItem
          content={
            attendanceItem.patient?.birthDate
              ? format(
                  new Date(attendanceItem.patient?.birthDate),
                  'dd/MM/yyyy'
                )
              : '-'
          }
          title={'Data de nascimento'}
        />
      </AttendanceItemDataCell>
      <AttendanceItemDataCell>
        <LabeledItem
          content={attendanceItem.patient?.email || '-'}
          title={'Email'}
        />
      </AttendanceItemDataCell>
      <AttendanceItemDataCell>
        <LabeledItem
          content={attendanceItem.patient?.phoneNumber || '-'}
          title={'Telefone'}
        />
      </AttendanceItemDataCell>
      {attendanceItem.patient ? (
        <div>
          <Button onClick={() => setOpenModal(true)} startIcon={<Edit />}>
            Editar
          </Button>
        </div>
      ) : (
        <div>
          <Button onClick={() => setOpenModal(true)} startIcon={<Add />}>
            Registrar paciente
          </Button>
        </div>
      )}

      <AttendanceItemPopUp
        openModal={openModal}
        setOpenModal={setOpenModal}
        item={attendanceItem}
      />
    </AttendanceItemContainer>
  )
}

export default AttendanceItem

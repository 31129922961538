import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '../../state/store'
import { useIsMobile } from '../../utils/utils'
import DesktopLoginLayout from './DesktopLayout'
import MobileLoginLayout from './MobileLayout'

const Login = () => {
  const isLogged = useSelector((state) => state.auth.isLogged)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLogged) {
      navigate('/')
    }
  }, [])

  const isMobile = useIsMobile()

  return isMobile ? <MobileLoginLayout /> : <DesktopLoginLayout />
}

export default Login

import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const CustomerOrderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const LoadingOrdersContainer = styled('div')`
  margin-top: ${mainTheme.spacing(6)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

import { Paper } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const LoginPageDesktopContainer = styled('div')`
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(319.95deg, #cb76ff -25.64%, #fdfeff 29.56%);
`

export const StyledPaper = styled(Paper)`
  box-shadow: 0px 7px 8px -4px rgb(97 0 156 / 30%),
    0px 12px 17px 2px rgb(97 0 156 / 14%), 0px 5px 22px 4px rgb(97 0 156 / 12%) !important;
  height: 85%;
  width: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${mainTheme.spacing(6)}px;
  position: relative;
  overflow: hidden;
`

export const LoginLogoContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

import React, { useEffect } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { mainTheme } from '../../../styles/mainTheme'
import { useLocation } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { BreadcrumbsStyled, LinkStyled } from './styles'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state/'

type LabelsType = string[]

const CustomBreadcrumbs = () => {
  const { pathname } = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const getCampaignName = () => {
    params?.campaignId &&
      dispatch(
        actions.campaigns.getCampaignDetailsFetch({
          campaignId: params?.campaignId,
        })
      )
  }

  useEffect(() => {
    getCampaignName()
  }, [])

  const loadingCampaign = useSelector(
    (state) => state.campaigns.isLoadingCampaignDetail
  )
  const campaignName = useSelector(
    (state) => state.campaigns.campaignDetails?.name
  )

  const redirects: Map<string, string> = new Map([
    ['Campanhas', '/campaign'],
    [`${params?.campaignId}`, `/campaign/${params?.campaignId}`],
    [`${campaignName}`, `/campaign/${params?.campaignId}`],
    ['Rede de Aceitação', `/campaign/${params?.campaignId}/acceptance`],
    ['Relatório Utilização', `/campaign/${params?.campaignId}/voucherReport`],
    ['Relatório de Pedidos', `/campaign/${params?.campaignId}/orderReport`],
    ['Pedidos', `/order`],
  ])

  const breadCrumbItems: Map<string, LabelsType> = new Map([
    ['/campaign', ['Campanhas']],
    [`/order`, ['Pedidos']],
    [
      `/campaign/${params?.campaignId}`,
      [
        'Campanhas',
        `${campaignName && !loadingCampaign ? campaignName : 'Carregando...'}`,
      ],
    ],
    [
      `/campaign/${params?.campaignId}/acceptance`,
      [
        'Campanhas',
        `${campaignName && !loadingCampaign ? campaignName : 'Carregando...'}`,
        'Rede de Aceitação',
      ],
    ],
    [
      `/campaign/${params?.campaignId}/voucherReport`,
      [
        'Campanhas',
        `${campaignName && !loadingCampaign ? campaignName : 'Carregando...'}`,
        'Relatório Utilização',
      ],
    ],
    [
      `/campaign/${params?.campaignId}/orderReport`,
      [
        'Campanhas',
        `${campaignName && !loadingCampaign ? campaignName : 'Carregando...'}`,
        'Relatório de Pedidos',
      ],
    ],
  ])

  const breadCrumbItem = breadCrumbItems.get(pathname)

  return (
    <BreadcrumbsStyled separator={<NavigateNextIcon fontSize="small" />}>
      {breadCrumbItem &&
        breadCrumbItem.map((item, index) => {
          return index === breadCrumbItem.length - 1 ? (
            <LinkStyled
              color={mainTheme.palette.grey[300]}
              underline="hover"
              key={index}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              component={RouterLink}
              to={redirects.get(item) ?? ''}
            >
              {item}
            </LinkStyled>
          ) : (
            <LinkStyled
              color={mainTheme.palette.primary.main}
              underline="hover"
              key={index}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              component={RouterLink}
              to={redirects.get(item) ?? ''}
            >
              {item}
            </LinkStyled>
          )
        })}
    </BreadcrumbsStyled>
  )
}

export default CustomBreadcrumbs

import styled from 'styled-components'
import { Breadcrumbs, Link } from '@mui/material'
import { mainTheme } from '../../../styles/mainTheme'

export const BreadcrumbsStyled = styled(Breadcrumbs)`
  margin-top: ${mainTheme.spacing(4)}px;
  margin-bottom ${mainTheme.spacing(4)}px;
  @media(max-width: 900px) {
    margin-top: ${mainTheme.spacing(2)}px;
    margin-bottom ${mainTheme.spacing(2)}px;
  }

`

export const LinkStyled = styled(Link)`
  font-weight: bold;
`

import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const ToolbarContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  gap: ${mainTheme.spacing(1)};
`

export const ToolbarItem = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
`

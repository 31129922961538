import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getUserEmailValidation } from '../../services/user'
import { errorActions } from '../errors'
import { actions } from './slice'
import { CheckEmailAction } from './types'

export function* sagaCheckEmail(action: CheckEmailAction): SagaIterator<void> {
  try {
    const validation = yield call(getUserEmailValidation, action.payload)
    yield put(actions.getEmailValidationSuccess(validation))
  } catch (e) {
    console.error(e)
    yield put(actions.getEmailValidationError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getEmailValidationFetch.type, sagaCheckEmail),
]

import {
  CancelOutlined,
  CheckCircleOutline,
  ErrorOutline,
  RestartAlt,
} from '@mui/icons-material'
import { Button, Dialog, DialogContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'
import { PaymentStatus, parseIuguStatus } from '@vacinas-net/shared'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state/createCampaignOrder'
import { useSelector } from '../../../../state/store'
import { checkPaymentStatus } from '../../../../services/createCampaignOrder'
import { useNavigate } from 'react-router-dom'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${mainTheme.spacing(4)}px;
`

const ProcessingPaymentModal = () => {
  const invoiceId = useSelector((state) => state.createCampaignOrder.invoiceId)
  const paymentStatus = useSelector(
    (state) => state.createCampaignOrder.paymentStatus
  )
  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )
  const open =
    useSelector((state) => state.createCampaignOrder.openLoadingPaymentModal) &&
    paymentMethod === 'creditCard'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goBack = () => {
    dispatch(actions.setInvoiceId(undefined))
    dispatch(actions.setPaymentStatus(undefined))
    dispatch(actions.setCreateOrderFormStep('payment'))
    dispatch(actions.setOpenLoadingPaymentModal(false))
  }

  useEffect(() => {
    if (
      !invoiceId ||
      paymentStatus?.status === PaymentStatus.CANCELED ||
      paymentStatus?.status === PaymentStatus.ERROR
    ) {
      return
    }
    if (paymentStatus?.status === PaymentStatus.PAID) {
      setTimeout(() => {
        dispatch(actions.setOpenLoadingPaymentModal(false))
        navigate('/campaign/order/receipt')
      }, 2000)
    }
    setTimeout(async () => {
      const updatedPaymentStatus = await checkPaymentStatus(invoiceId!)
      dispatch(
        actions.setPaymentStatus({
          status: parseIuguStatus(updatedPaymentStatus.status),
        })
      )
    }, 2000)
  }, [paymentStatus, invoiceId])

  if (
    paymentStatus?.status === PaymentStatus.CANCELED ||
    paymentStatus?.status === PaymentStatus.ERROR
  ) {
    return (
      <Dialog open={open}>
        <StyledDialogContent>
          <CancelOutlined
            color="error"
            style={{
              fontSize: 82,
              marginBottom: mainTheme.spacing(2),
            }}
          />
          <Typography variant="h1" color={'primary'} gutterBottom>
            Pagamento não processado!
          </Typography>
          <Typography
            style={{
              marginBottom: mainTheme.spacing(2),
            }}
            variant="body1"
            color={'textSecondary'}
          >
            Verifique os dados inseridos e tente novamente
          </Typography>
          <Button
            startIcon={<RestartAlt />}
            onClick={() => goBack()}
            color="primary"
            variant="contained"
          >
            Tentar novamente
          </Button>
        </StyledDialogContent>
      </Dialog>
    )
  }

  if (paymentStatus?.status === PaymentStatus.PAID) {
    return (
      <Dialog open={open}>
        <StyledDialogContent>
          <CheckCircleOutline
            color="primary"
            style={{
              fontSize: 82,
              marginBottom: mainTheme.spacing(2),
            }}
          />
          <Typography variant="h1" color={'primary'}>
            Pagamento realizado com sucesso!
          </Typography>
        </StyledDialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open}>
      <StyledDialogContent>
        <ErrorOutline
          color="primary"
          style={{
            fontSize: 82,
            marginBottom: mainTheme.spacing(2),
          }}
        />
        <Typography variant="h1" color={'primary'}>
          Processando pagamento...
        </Typography>
      </StyledDialogContent>
    </Dialog>
  )
}

export default ProcessingPaymentModal

import { GetOrdersPayload } from '../../state/customerOrders/types'
import api from '../api'

export const getCustomerOrders = async (payload: GetOrdersPayload) => {
  const { limit, page, sort, sortOrder } = payload
  const params: URLSearchParams = new URLSearchParams()
  limit && params.set('limit', limit.toString())
  page?.toString() && params.set('page', page.toString())
  sort && params.set('sort', sort)
  sortOrder && params.set('sortOrder', sortOrder.toString())
  return api.get(`client/order?${params}`)
}

import { CircularProgress, Grid, Typography, Button } from '@mui/material'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions } from '../../../state/campaigns/slice'
import { useSelector } from '../../../state/store'
import { mainTheme } from '../../../styles/mainTheme'
import { Heading } from '../CampaignCard/styles'
import CampaignStatusChip from '../chips/CampaignCardChip'
import DateRange from '../DateRange'
import {
  Container,
  ContentContainer,
  CouponData,
  DataContainer,
  DateContainer,
} from './styles'
import CouponToClipboard from '../CouponToClipboard'

export interface CampaignDataCardProps {
  status?: 'active | unfinish'
  name?: string
  startDate?: string
  endDate?: string
  coupons?: string[]
}

const CampaignDataCard = () => {
  const params = useParams()
  const loading = useSelector(
    (state) => state.campaigns.isLoadingCampaignDetail
  )
  const campaignDetails = useSelector(
    (state) => state.campaigns.campaignDetails
  )
  const handler = campaignDetails?.handler
  const [showAllCoupons, setShowAllCoupons] = useState(false)

  const dispatch = useDispatch()
  const getCampaignDataDetails = () => {
    dispatch(
      actions.getCampaignDetailsFetch({
        campaignId: params.campaignId!,
      })
    )
  }

  useEffect(() => {
    getCampaignDataDetails()
  }, [])
  return (
    <Container>
      {loading ? (
        <CircularProgress style={{ padding: mainTheme.spacing(2) }} size={30} />
      ) : (
        campaignDetails && (
          <>
            <Heading>
              <CampaignStatusChip status={`${campaignDetails.status}`} />
            </Heading>
            <ContentContainer>
              <DataContainer>
                <Typography variant="h1">{campaignDetails.name}</Typography>
                <DateContainer>
                  <DateRange
                    startDate={`${format(
                      new Date(campaignDetails.startDate!),
                      'P',
                      {
                        locale: ptBR,
                      }
                    )}`}
                    endDate={`${format(
                      new Date(campaignDetails.endDate!),
                      'P',
                      {
                        locale: ptBR,
                      }
                    )}`}
                  />
                </DateContainer>
                {handler !== 'jenner' && (
                  <CouponData>
                    <Typography
                      style={{ marginBottom: mainTheme.spacing(1) }}
                      variant="h3"
                    >
                      Cupons:
                    </Typography>
                    <Grid container spacing={2}>
                      {campaignDetails.coupons &&
                        campaignDetails.coupons.slice(0, 4).map((coupon) => (
                          <Grid key={coupon} item xs={8} md={3}>
                            <CouponToClipboard couponName={coupon} />
                          </Grid>
                        ))}
                      {showAllCoupons &&
                        campaignDetails.coupons!.slice(4).map((coupon) => (
                          <Grid key={coupon} item xs={8} md={3}>
                            <CouponToClipboard couponName={coupon} />
                          </Grid>
                        ))}
                    </Grid>
                    <div>
                      {campaignDetails.coupons &&
                        campaignDetails.coupons.length > 4 && (
                          <Button
                            sx={{ marginTop: mainTheme.spacing(0.25) }}
                            variant="text"
                            onClick={() =>
                              setShowAllCoupons(
                                (showAllCoupons) => !showAllCoupons
                              )
                            }
                          >
                            {showAllCoupons
                              ? `Mostrar menos cupons`
                              : `Mostrar todos os ${campaignDetails.coupons.length} cupons`}
                          </Button>
                        )}
                    </div>
                  </CouponData>
                )}
              </DataContainer>
            </ContentContainer>
          </>
        )
      )}
    </Container>
  )
}

export default CampaignDataCard

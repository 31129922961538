/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVoucherPackage } from '@vacinas-net/shared'
import {
  DownloadAllVouchers,
  DownloadAllVouchersAction,
  DownloadSingleVoucherPackageAction,
  RequestAdditionalVouchersAction,
  VouchersFetchAction,
  VouchersState,
} from './types'

export type SetVouchersAction = PayloadAction<IVoucherPackage[]>

const initialState: VouchersState = {
  vouchers: [],
  isLoading: true,
  campaignId: undefined,
  isDownloadingAllVouchers: false,
  isDownloadingSingleVoucher: false,
  isRequestingAdditionalVouchers: false,
}

export const slice = createSlice({
  name: 'Vouchers',
  initialState,
  reducers: {
    getVouchersFetch: (state, action: VouchersFetchAction) => {
      state.isLoading = true
    },
    getVouchersSuccess: (state, data: SetVouchersAction) => {
      state.vouchers = data.payload
      state.isLoading = false
    },
    getVouchersError: (state) => {
      state.isLoading = false
    },
    downloadAllVouchers: (state, action: DownloadAllVouchersAction) => {
      state.isDownloadingAllVouchers = true
    },
    downloadAllVouchersSuccess: (state) => {
      state.isDownloadingAllVouchers = false
    },
    downloadAllVouchersError: (state) => {
      state.isDownloadingAllVouchers = false
    },
    downloadSingleVoucherPackage: (
      state,
      action: DownloadSingleVoucherPackageAction
    ) => {
      state.isDownloadingSingleVoucher = true
    },
    downloadSingleVoucherPackageSuccess: (state) => {
      state.isDownloadingSingleVoucher = false
    },
    downloadSingleVoucherPackageError: (state) => {
      state.isDownloadingSingleVoucher = false
    },
    requestAdditionalPackage: (
      state,
      action: RequestAdditionalVouchersAction
    ) => {
      state.isRequestingAdditionalVouchers = true
    },
    requestAdditionalPackageSuccess: (state) => {
      state.isRequestingAdditionalVouchers = false
    },
    requestAdditionalPackageError: (state) => {
      state.isRequestingAdditionalVouchers = false
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

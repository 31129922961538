import React from 'react'
import LoginForm from '../../../components/elements/LoginForm'
import { LoginPageMobileContainer } from './styles'
import LogoVacinas from '../../../assets/VacinasLogoNoName.svg'
import MediaFooter from '../../../components/elements/MediaFooter'
import { VacinasLogo } from '../Shared/styles'

const MobileLoginLayout = () => {
  return (
    <LoginPageMobileContainer>
      <VacinasLogo>
        <img alt="Logo Vacinas.net" src={LogoVacinas} />
      </VacinasLogo>
      <LoginForm />
      <MediaFooter />
    </LoginPageMobileContainer>
  )
}

export default MobileLoginLayout

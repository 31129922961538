import { Button } from '@mui/material'
import React, { useState } from 'react'
import AddCustomersModal from './AddCustomersModal'
import { AddCustomerContainer } from './styles'

const AddCustomers = () => {
  const [open, setOpen] = useState(false)
  return (
    <AddCustomerContainer>
      <Button onClick={() => setOpen(true)} size="large" variant="contained">
        Adicionar Lista de Beneficiários
      </Button>
      <AddCustomersModal
        open={open}
        setOpen={(value: boolean) => setOpen(value)}
      />
    </AddCustomerContainer>
  )
}

export default AddCustomers

import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'
import CreateCampaignOrderFooter from '../CreateCampaignOrderFooter'
import { Card, IconButton, List } from '@mui/material'

export const ScheduleOrderStepContainer = styled(Grid)`
  min-height: 100vh;
`

export const ScheduleOrderFormContainer = styled(Grid)`
  background-color: white;
  padding-left: ${mainTheme.spacing(2.5)}px;
  padding-right: ${mainTheme.spacing(2.5)}px;

  padding-top: ${mainTheme.spacing(2)}px;
  padding-bottom: ${mainTheme.spacing(2)}px;

  ${mainTheme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4%;
    padding-bottom: 4%;
  }
`

export const ScheduleOrderMenuContainer = styled(Grid)`
  background-color: ${mainTheme.palette.primary.light};

  padding-top: ${mainTheme.spacing(2)}px;
  padding-bottom: ${mainTheme.spacing(2)}px;
  ${mainTheme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 4%;
    padding-bottom: 4%;
  }
`

export const CreateCampaignOrderFooterStyled = styled(
  CreateCampaignOrderFooter
)`
  padding-bottom: 0px;
  margin-bottom: ${mainTheme.spacing(1.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    padding-bottom: 0px;
    margin-bottom: ${mainTheme.spacing(0)}px;
  }
`

export const PlaceFormDesktopContainer = styled(Grid)`
  ${mainTheme.breakpoints.up('md')} {
    width: 450px;
  }
`

export const ClinicVaccinationDesktopContainer = styled(Grid)`
  ${mainTheme.breakpoints.up('md')} {
    width: 742px;
  }
`
export const ModalLocationPinContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ModalClinicDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${mainTheme.spacing(1)}px;
`
export const ModalList = styled(List)`
  max-height: 365px;
`
export const StyledCard = styled(Card)`
  width: 100%;
`
export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  padding: ${mainTheme.spacing(1.2)}px;
`

import { addDays, format, formatISO } from 'date-fns'
import {
  CampaignOrderFilters,
  CampaignOrdersGet,
  DownloadAllCampaignOrders,
} from '../../state/campaignOrders/types'
import { GetCampaignPayload } from '../../state/campaigns/types'
import api from '../api'

export const getCampaigns = async (payload: GetCampaignPayload) => {
  const { limit, page, sort, sortOrder, searchTerm, showEndedCampaigns } =
    payload
  const params: URLSearchParams = new URLSearchParams()
  limit && params.set('limit', limit.toString())
  page?.toString() && params.set('page', page.toString())
  sort && params.set('sort', sort)
  sortOrder && params.set('sortOrder', sortOrder.toString())
  searchTerm && params.set('searchTerm', searchTerm.toString())
  !showEndedCampaigns &&
    params.set('dateRange.endDate(min)', formatISO(Date.now()))

  return api.get(`/client/campaign?${params}`)
}

export const getCampaignDetails = async (campaignId: string) => {
  return api.get(`client/campaign/${campaignId}`)
}

export const getCampaignOrders = async (payload: CampaignOrdersGet) => {
  const { campaignId, page, limit, filters } = payload

  return api.get(
    `campaign/${campaignId}/orders?${generateCampaignOrdersParams(
      filters,
      limit,
      page
    )}`
  )
}

export const getDownloadCampaignOrdersUrl = async (
  payload: DownloadAllCampaignOrders
) => {
  const { campaignId, filters } = payload
  return api.get(
    `/campaign/${campaignId}/orders/export?${generateCampaignOrdersParams(
      filters
    )}`
  )
}

const generateCampaignOrdersParams = (
  filters: CampaignOrderFilters | undefined,
  limit?: number,
  page?: number
) => {
  const params: URLSearchParams = new URLSearchParams()
  limit && params.set('limit', limit.toString())
  page && page.toString() && params.set('page', page.toString())

  filters?.orderId && params.set('orderId', `%${filters.orderId}%`)
  filters?.customerName &&
    params.set('customer.name', `%${filters.customerName}%`)
  filters?.customerDocument &&
    params.set(
      'customer.document',
      `%${filters?.customerDocument.replace(/\D/g, '')}%`
    )
  filters?.city && params.set('cities', `%${filters?.city}%`)
  filters?.clinicName &&
    params.set('pickupPointNames', `%${filters?.clinicName}%`)
  filters?.coupon &&
    params.set('marketingData.couponCode', `%${filters?.coupon}%`)
  filters?.status && params.set('status', `%${filters?.status}%`)

  filters?.orderPurchasedAt &&
    params.set(
      'purchasedAt(max)',
      format(addDays(new Date(filters.orderPurchasedAt), 2), 'yyyy-MM-dd')
    )

  filters?.orderPurchasedAtEnd &&
    params.set('purchasedAt(min)', filters.orderPurchasedAtEnd)

  filters?.hasVoucher &&
    filters?.hasVoucher === 'yes' &&
    params.set('payments.method', 'voucher')
  filters?.hasVoucher &&
    filters?.hasVoucher === 'no' &&
    params.set('payments.method!', 'voucher')

  return params
}

export const downloadAllowedCustomerBatchTemplate = async () => {
  return api.get(
    `/campaign/allowed-customer-batch/template`
  ) as unknown as string
}

export const uploadAllowedCustomerBatchTemplate = async (
  formData: FormData,
  campaignId: string
) => {
  return api.postFormData(
    `/client/campaign/${campaignId}/additionalPackage`,
    formData
  )
}

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

const CustomDialog = (props: {
  onConfirm: () => void
  closeDialog: () => void
  open: boolean
  title: string
  message?: string
}) => {
  const { onConfirm, closeDialog, open, title, message } = props
  return (
    <Dialog maxWidth={'md'} onClose={() => closeDialog()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeDialog()
          }}
          variant="contained"
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onConfirm()
          }}
          variant="contained"
          color="primary"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog

import { Grid } from '@mui/material'
import { CSSProperties } from 'react'
import styled from 'styled-components'

export const RowWrapper = styled(Grid)`
  flex-basis: 0;
`

export const iconStyle: CSSProperties = {
  width: 24,
  height: 24,
}

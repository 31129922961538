import { Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const WelcomeSection = styled(Grid)`
  display: flex;
  background-color: ${mainTheme.palette.primary.main};
  margin-top: ${mainTheme.spacing(4)}px;
  padding: ${mainTheme.spacing(2)}px;
  border-radius: ${mainTheme.spacing(2)}px;
  @media print {
    display: none;
  }
`

export const WelcomeSectionImageContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

export const WelcomeSectionTextContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${mainTheme.spacing(2)}px;
`
export const ClientInfoSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: ${mainTheme.spacing(4)}px;
  padding: ${mainTheme.spacing(2)}px;
  border-radius: ${mainTheme.spacing(2)}px;
  @media (max-width: 900px) {
    overflow: scroll;
  }

  @media print {
    overflow: unset;
  }
`

export const ClientInfoData = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${mainTheme.spacing(2)}px;
`

export const AttendanceItemsSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: ${mainTheme.spacing(4)}px;
  padding: ${mainTheme.spacing(2)}px;
  border-radius: ${mainTheme.spacing(2)}px;
`
export const ClientInfoText = styled(Typography)`
  @media (max-width: 900px) {
    max-width: 270px;
  }
`

export const ClientInfoDataCell = styled('div')`
  margin-right: ${mainTheme.spacing(4)}px;
`

export const PrintButtonContainer = styled('div')`
  margin-top: ${mainTheme.spacing(2)}px;
  display: flex;
  justify-content: flex-end;
`

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  DialogContentContainer,
  HighlightedText,
  ModalContainer,
  ModalTypographyItem,
} from './styles'
import { CheckCircleRounded, UploadFile } from '@mui/icons-material'
import {
  downloadAllowedCustomerBatchTemplate,
  uploadAllowedCustomerBatchTemplate,
} from '../../../services/campaign'
import { downloadFile } from '../../../utils/utils'
import { toast } from 'react-toastify'
import { useSelector } from '../../../state/store'
import CustomDropzone from '../CustomDropzone'
import { mainTheme } from '../../../styles/mainTheme'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state'

const AddCustomersModal = (props: {
  open: boolean
  setOpen: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const { open, setOpen } = props
  const campaign = useSelector((state) => state.campaigns.campaignDetails)
  // eslint-disable-next-line prettier/prettier
  const [step, setStep] = useState<'info' | 'file' | 'uploading' | 'complete'>(
    'info'
  )
  const [file, setFile] = useState<File>()

  const downloadTemplate = async () => {
    try {
      const response = await downloadAllowedCustomerBatchTemplate()
      downloadFile(response, 'template.csv')
    } catch (error) {
      toast(String(error), { type: 'error' })
      console.error(error)
    }
  }

  const uploadTemplate = async () => {
    setStep('uploading')
    try {
      const formData = new FormData()
      formData.append('file', new Blob([file!]), 'template.csv')
      await uploadAllowedCustomerBatchTemplate(formData, String(campaign?._id))
    } catch (error) {
      toast(String(error), { type: 'error' })
      console.error(error)
    } finally {
      setStep('complete')
    }
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setStep('info')
      }, 2000)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogContentContainer>
        {step === 'info' && (
          <div>
            <Typography
              style={{ marginBottom: mainTheme.spacing(2) }}
              variant="h2"
              color="textPrimary"
            >
              Instruções de inclusão de beneficiário
            </Typography>
            <ModalTypographyItem
              onClick={() => downloadTemplate()}
              variant="body1"
            >
              1. Faça download do{' '}
              <HighlightedText>modelo de lista</HighlightedText>
            </ModalTypographyItem>
            <ModalTypographyItem variant="body1">
              2. Não altere o título das colunas
            </ModalTypographyItem>

            <ModalTypographyItem variant="body1">
              3. Preencha a planilha com as informações de 1 beneficiário por
              linha colocando as informações na coluna correspondente
            </ModalTypographyItem>
            <ModalTypographyItem variant="body1">
              4. Clique em “Enviar lista”, selecione o arquivo e confirme
            </ModalTypographyItem>
          </div>
        )}
        {step === 'file' && (
          <ModalContainer>
            <CustomDropzone file={file} setFile={(file) => setFile(file)} />
          </ModalContainer>
        )}
        {step === 'uploading' && (
          <ModalContainer>
            <CircularProgress size={40} />
          </ModalContainer>
        )}
        {step === 'complete' && (
          <ModalContainer>
            <CheckCircleRounded fontSize="large" color="secondary" />
            <Typography
              style={{ marginTop: mainTheme.spacing(1) }}
              variant="h2"
              color="primary"
            >
              Upload concluído
            </Typography>
            <Button
              style={{ marginTop: mainTheme.spacing(4) }}
              onClick={() => {
                setOpen(false)
                dispatch(
                  actions.vouchers.getVouchersFetch({
                    campaignId: String(campaign?._id),
                  })
                )
              }}
              size="medium"
              variant="contained"
            >
              Finalizar
            </Button>
          </ModalContainer>
        )}
      </DialogContentContainer>
      <DialogActions>
        {step !== 'complete' && (
          <Button
            onClick={() => {
              setOpen(false)
            }}
            size="medium"
            variant="outlined"
          >
            Cancelar
          </Button>
        )}
        {step === 'info' && (
          <Button
            startIcon={<UploadFile />}
            size="medium"
            variant="contained"
            onClick={() => setStep('file')}
          >
            Enviar lista
          </Button>
        )}
        {step === 'file' && (
          <Button
            disabled={!file}
            startIcon={<UploadFile />}
            size="medium"
            variant="contained"
            onClick={() => uploadTemplate()}
          >
            Confirmar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddCustomersModal

import { ContentCopy } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { CouponContainer, CouponText } from './styles'

const CouponToClipboard = (props: { couponName: string }) => {
  return (
    <CouponContainer
      onClick={() => {
        navigator.clipboard.writeText(props.couponName)
      }}
    >
      <CouponText variant="body1">{props.couponName}</CouponText>
      <IconButton color="primary">
        <ContentCopy />
      </IconButton>
    </CouponContainer>
  )
}

export default CouponToClipboard

import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getCustomerOrders } from '../../services/customerOrders'
import { errorActions } from '../errors'
import { actions } from './slice'
import { OrdersFetchAction } from './types'

export function* sagaCustomerOrdersFetch(
  action: OrdersFetchAction
): SagaIterator<void> {
  try {
    const orders = yield call(getCustomerOrders, action.payload)
    yield put(actions.getOrdersSuccess(orders.results))
    yield put(actions.setTotalOrders(orders.total))
  } catch (e) {
    console.error(e)
    yield put(actions.getOrdersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getOrdersFetch.type, sagaCustomerOrdersFetch),
]

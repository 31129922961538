import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../../styles/mainTheme'

const VoucherUsageTableChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'used':
      statusText = 'UTILIZADO'
      statusColor = mainTheme.palette.success.main
      break
    case 'unused':
      statusText = 'NÃO UTILIZADO'
      statusColor = mainTheme.palette.warning.light
      break
    default:
      statusText = 'INDEFINIDO'
      statusColor = mainTheme.palette.grey['300']
      break
  }
  return (
    <Chip
      style={{
        backgroundColor: statusColor,
        color: '#FFFFFF',
        fontWeight: 'bold',
      }}
      label={statusText}
      className="campaign-status-chip"
    />
  )
}

export default VoucherUsageTableChip

import { Paper } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const CardContainer = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: ${mainTheme.spacing(2)}px;
  cursor: pointer;
  font-size: 44px;
`
export const TitleContainer = styled('div')`
  max-width: 130px;
  overflow: hidden;
`

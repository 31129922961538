/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  DownloadAllVouchersAction,
  VoucherFiltersAction,
  VoucherReportPagedGetAction,
  VoucherReportState,
} from './types'

const initialState: VoucherReportState = {
  isLoading: true,
  vouchers: [],
  page: 0,
  total: 0,
  filters: {},
  isDownloadingAllVouchers: false,
}

export const slice = createSlice({
  name: 'voucherReport',
  initialState,
  reducers: {
    getVouchersPaged: (state, action: VoucherReportPagedGetAction) => {
      const { page, limit } = action.payload
      state.isLoading = true
      state.page = page
      state.limit = limit
    },
    getVouchersPagedSuccess: (state, action) => {
      state.isLoading = false
      state.vouchers = action.payload.vouchers
      state.total = action.payload.total
    },
    getVouchersPagedError: (state) => {
      state.isLoading = false
    },
    downloadAllVouchers: (state, action: DownloadAllVouchersAction) => {
      state.isDownloadingAllVouchers = true
    },
    downloadAllVouchersSuccess: (state) => {
      state.isDownloadingAllVouchers = false
    },
    downloadAllVouchersError: (state) => {
      state.isDownloadingAllVouchers = false
    },
    setVoucherRedemptionCodeFilter: (state, action: VoucherFiltersAction) => {
      state.filters.voucherRedemptionCode = action.payload.voucherRedemptionCode
    },
    setCustomerNameFilter: (state, action: VoucherFiltersAction) => {
      state.filters.customerName = action.payload.customerName
    },
    setClinicNameFilter: (state, action: VoucherFiltersAction) => {
      state.filters.clinicName = action.payload.clinicName
    },
    setClinicCityFilter: (state, action: VoucherFiltersAction) => {
      state.filters.clinicCity = action.payload.clinicCity
    },
    setUsageStatusFilter: (state, action: VoucherFiltersAction) => {
      state.filters.usageStatus = action.payload.usageStatus
    },
    setOrderPurchasedAtFilter: (state, action: VoucherFiltersAction) => {
      state.filters.orderPurchasedAt = action.payload.orderPurchasedAt
    },
    setOrderPurchasedAtFilterEnd: (state, action: VoucherFiltersAction) => {
      state.filters.orderPurchasedAtEnd = action.payload.orderPurchasedAtEnd
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

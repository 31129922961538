import React from 'react'
import { useSelector } from '../../../../state/store'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  IAvailableAttendance,
  IDateRange,
  ShippingPolicy,
} from '@vacinas-net/shared'
import { format } from 'date-fns'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { RowWrapper, iconStyle } from './styles'
import { datePattern, parseAddress, timePattern } from '../../../../utils/utils'
import { PurpleBackgroundPaper } from '../PaymentStep/IuguCreditCardForm/PaymentSummaryCard'
import ChangePrviousDataButton from './ChangePreviousDataButton'
import { Vaccines } from '@mui/icons-material'

const PlaceSection = () => {
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )

  const shippingPolicy = useSelector(
    (state) => state.createCampaignOrder.shippingPolicy
  )
  const clinic = useSelector(
    (state) => state.createCampaignOrder.selectedClinic
  )

  const vaccineShotTime = useSelector(
    (state) => state.createCampaignOrder.vaccineShotTime
  )

  const availableAttendances = useSelector(
    (state) => state.createCampaignOrder.availableAttendances
  )

  const availableAttendance = availableAttendances?.find(
    (item) => item._id === formData.availableAttendanceId
  )

  const formatAddressEnterprise = (
    availableAttendance: IAvailableAttendance | undefined
  ) => {
    if (!availableAttendance) return '-'

    const address = availableAttendance.address
    const addressNumber = address.streetNumber
      ? `nº ${address.streetNumber}`
      : ''
    const firstLevel = [
      address.streetName,
      addressNumber,
      address.neighborhood,
    ].join(', ')
    const secondLevel = [address.city, address.state].join('/')

    return `${firstLevel}. ${secondLevel}`
  }

  const formatHoursEnterprise = (
    availableAttendance: IAvailableAttendance | undefined
  ) => {
    if (!availableAttendance) return '-'
    const startTime = format(
      new Date(availableAttendance.startDatetime),
      timePattern
    )
    const endTime = format(
      new Date(availableAttendance.endDatetime),
      timePattern
    )

    return `${startTime} até ${endTime}`
  }

  const formatHoursClinic = (vaccineShotTime: string | undefined) => {
    if (!vaccineShotTime) return '-'

    const parsedVaccineShotTime: IDateRange = JSON.parse(vaccineShotTime)
    if (!parsedVaccineShotTime.startDate || !parsedVaccineShotTime.endDate)
      return '-'

    const startTime = format(
      new Date(parsedVaccineShotTime.startDate),
      timePattern
    )
    const endTime = format(new Date(parsedVaccineShotTime.endDate), timePattern)

    return `${startTime} até ${endTime}`
  }

  const formatDateEnterpise = (
    availableAttendance: IAvailableAttendance | undefined
  ) => {
    if (!availableAttendance) return '-'

    const date = format(
      new Date(availableAttendance.startDatetime),
      datePattern
    )

    return date
  }

  const formatDateClinic = (vaccineShotTime: string | undefined) => {
    if (!vaccineShotTime) return '-'

    const parsedVaccineShotTime: IDateRange = JSON.parse(vaccineShotTime)
    if (!parsedVaccineShotTime.startDate || !parsedVaccineShotTime.endDate)
      return '-'

    const date = format(new Date(parsedVaccineShotTime.startDate), datePattern)

    return date
  }

  return (
    <PurpleBackgroundPaper color="primary" elevation={0}>
      <Grid
        item
        xs={12}
        container
        gap={1}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flexWrap="unset"
      >
        <RowWrapper item xs={12} container gap={1}>
          <Typography marginBottom={1} color="primary" variant="h3">
            Dados de agendamento{' '}
          </Typography>
          <ChangePrviousDataButton stepToGoBack="place" />
        </RowWrapper>

        <RowWrapper item xs={12} container gap={1}>
          <CalendarTodayOutlinedIcon style={iconStyle} color="primary" />
          <Typography variant="body1">
            {shippingPolicy === ShippingPolicy.Company
              ? formatDateEnterpise(availableAttendance)
              : formatDateClinic(vaccineShotTime)}
          </Typography>
        </RowWrapper>
        <RowWrapper item xs={12} container gap={1}>
          <AccessAlarmOutlinedIcon style={iconStyle} color="primary" />
          <Typography variant="body1">
            {shippingPolicy === ShippingPolicy.Company
              ? formatHoursEnterprise(availableAttendance)
              : formatHoursClinic(vaccineShotTime)}
          </Typography>
        </RowWrapper>
        <RowWrapper
          item
          xs={12}
          container
          gap={1}
          display={'flex'}
          flexDirection="row"
          flexWrap={'unset'}
          alignItems="center"
        >
          <Grid item>
            <Vaccines style={iconStyle} color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {shippingPolicy === ShippingPolicy.Company
                ? availableAttendance?.address.name
                : clinic?.name}
            </Typography>
          </Grid>
        </RowWrapper>
        <RowWrapper
          item
          xs={12}
          container
          gap={1}
          display={'flex'}
          flexDirection="row"
          flexWrap={'unset'}
          alignItems="center"
        >
          <Grid item>
            <PinDropOutlinedIcon style={iconStyle} color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {shippingPolicy === ShippingPolicy.Company
                ? formatAddressEnterprise(availableAttendance)
                : parseAddress(clinic?.address)}
            </Typography>
          </Grid>
        </RowWrapper>
      </Grid>
    </PurpleBackgroundPaper>
  )
}

export default PlaceSection

import { Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state/createCampaignOrder'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'

const UndersocredTypography = styled(Typography)`
  cursor: pointer;
  margin-bottom: ${mainTheme.spacing(1)}px;
  text-decoration: underline;
  margin-left: auto;
`

const ChangePrviousDataButton = (props: { stepToGoBack: string }) => {
  const dispatch = useDispatch()

  const goBack = () => {
    dispatch(actions.setCreateOrderFormStep(props.stepToGoBack))
  }
  return (
    <UndersocredTypography
      onClick={() => goBack()}
      color="primary"
      variant="body2"
    >
      {'Alterar'}
    </UndersocredTypography>
  )
}

export default ChangePrviousDataButton

import React from 'react'
import { Chip } from '@material-ui/core'

const VouchersTableChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'approved':
      statusText = 'DISPONIBILIZADO'
      statusColor = '#30C836'
      break
    case 'pending':
      statusText = 'SOLICITADO'
      statusColor = '#FFB400'
      break
    default:
      statusText = 'INDEFINIDO'
      statusColor = '#686868'
      break
  }
  return (
    <Chip
      style={{
        backgroundColor: statusColor,
        color: '#FFFFFF',
        fontWeight: 'bold',
      }}
      label={statusText}
      className="campaign-status-chip"
    />
  )
}

export default VouchersTableChip

import React from 'react'
import { Typography } from '@mui/material'
import { CardContainer, TitleContainer } from './styles'
import { useNavigate } from 'react-router-dom'

interface MenuCardProps {
  url?: string
  title: string
  children?: React.ReactNode
}

const MenuCard = (props: MenuCardProps) => {
  const navigate = useNavigate()

  return (
    <CardContainer
      onClick={() => {
        if (props.url) {
          navigate(props.url)
        }
      }}
    >
      <TitleContainer>
        <Typography color="primary" variant="h2">
          {props.title}
        </Typography>
      </TitleContainer>
      {props.children && props.children}
    </CardContainer>
  )
}

export default MenuCard

import React from 'react'
import { AttendanceDataContainer, Link } from './styles'
import { format } from 'date-fns'
import { Typography } from '@mui/material'
import { mainTheme } from '../../../styles/mainTheme'

const ShotDataField = (props: {
  data: {
    status: 'purchased' | 'scheduled' | 'applied'
    date?: Date
  }
}) => {
  return (
    <div>
      <div style={{ minWidth: 250, textAlign: 'center' }}>
        {props.data.status === 'purchased' && (
          <AttendanceDataContainer>
            <Link
              onClick={() => console.log('TODO')}
              color="primary"
              variant="body2"
            >
              Agendar
            </Link>
          </AttendanceDataContainer>
        )}
        {props.data.status === 'scheduled' && (
          <AttendanceDataContainer>
            <Typography
              color={`${mainTheme.palette.grey[300]}`}
              variant="body2"
            >
              Agendado para
            </Typography>
            <Typography fontWeight={'bold'} variant="body2">
              {props.data.date ? format(new Date(props.data.date), 'P') : '-'}
            </Typography>
            <Link
              onClick={() => console.log('TODO')}
              color="primary"
              variant="body2"
            >
              Ver agendamento
            </Link>
          </AttendanceDataContainer>
        )}
        {props.data.status === 'applied' && (
          <AttendanceDataContainer>
            <Typography
              color={`${mainTheme.palette.grey[300]}`}
              variant="body2"
            >
              Aplicado em
            </Typography>
            <Typography fontWeight={'bold'} variant="body2">
              {props.data.date ? format(new Date(props.data.date), 'P') : '-'}
            </Typography>
            <Link
              onClick={() => console.log('TODO')}
              color="primary"
              variant="body2"
            >
              Dados da vacina
            </Link>
          </AttendanceDataContainer>
        )}
      </div>
    </div>
  )
}

export default ShotDataField

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '@vacinas-net/shared'
import { UserState } from './types'

export type SetUserAction = PayloadAction<IUser>

const initialState: UserState = {
  user: {},
  isLoading: false,
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserFetch: (state) => {
      state.isLoading = true
    },
    getUserSuccess: (state, data: SetUserAction) => {
      state.user = data.payload
      state.isLoading = false
    },
    getUserError: (state) => {
      state.isLoading = false
    },
    clearUser: (state) => {
      state.user = {}
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

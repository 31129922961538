import React from 'react'
import AppRoutes from './routes'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { mainTheme } from './styles/mainTheme'
import { ThemeProvider } from '@mui/material'
import AuthWrapper from './components/modules/AuthWrapper'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={mainTheme}>
        <AuthWrapper>
          <AppRoutes />
        </AuthWrapper>
      </ThemeProvider>
    </Provider>
  )
}

export default App

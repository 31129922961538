import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const LayoutContainer = styled('div')`
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  padding-left: ${mainTheme.spacing(1 + 1 / 2)}px;
  padding-right: ${mainTheme.spacing(1 + 1 / 2)}px;
  padding-bottom: ${mainTheme.spacing(1 + 1 / 2)}px;
`

import React from 'react'
import { Grid, Typography } from '@mui/material'
import { CreditCard, Paid, Pix } from '@mui/icons-material'
import { useSelector } from '../../../../state/store'
import { mainTheme } from '../../../../styles/mainTheme'
import {
  PurpleBackgroundPaper,
  StyledGrid,
} from '../PaymentStep/IuguCreditCardForm/PaymentSummaryCard'
import ChangePrviousDataButton from './ChangePreviousDataButton'
import { getCreditCardHiddenPreview } from '../../utils'

const PaymentMethodCard = () => {
  const iuguCreditCardFormData = useSelector(
    (state) => state.createCampaignOrder.iuguCreditCardFormData
  )

  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )

  return (
    <PurpleBackgroundPaper color="primary" elevation={0}>
      <Grid container xs={12}>
        <StyledGrid alignItems={'center'} item xs={12}>
          <Typography marginBottom={1} variant="h3" color="primary">
            Dados do pagamento
          </Typography>
          <ChangePrviousDataButton stepToGoBack="payment" />
        </StyledGrid>
        {paymentMethod === 'creditCard' ? (
          <>
            <StyledGrid direction="row" item xs={12}>
              <CreditCard
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              />
              <Typography variant="body1" color="textPrimary">
                {getCreditCardHiddenPreview(
                  String(iuguCreditCardFormData?.number)
                )}
              </Typography>
            </StyledGrid>
            <StyledGrid direction="row" item xs={12}>
              <Paid
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              />
              <Typography variant="body1" color="textPrimary">
                Parcelamento: {iuguCreditCardFormData?.installmentsNumber}x sem
                juros
              </Typography>
            </StyledGrid>
          </>
        ) : (
          <StyledGrid direction="row" item xs={12}>
            <Pix
              style={{ marginRight: mainTheme.spacing(1) }}
              color="primary"
            />
            <Typography variant="body1" color="textPrimary">
              Pagamento via PIX
            </Typography>
          </StyledGrid>
        )}
      </Grid>
    </PurpleBackgroundPaper>
  )
}

export default PaymentMethodCard

import React from 'react'
import { FooterContainer, IconWrapper } from './styles'
import { LinkedIn, Instagram, WhatsApp, Facebook } from '@mui/icons-material'

const MediaFooter = () => {
  return (
    <FooterContainer>
      <IconWrapper
        onClick={() => {
          window.open('https://www.instagram.com/vacinas_net/?hl=en')
        }}
      >
        <Instagram fontSize="large" />
      </IconWrapper>
      <IconWrapper
        onClick={() => {
          window.open('https://www.linkedin.com/company/vacinas-net/')
        }}
      >
        <LinkedIn fontSize="large" />
      </IconWrapper>
      <IconWrapper
        onClick={() => {
          window.open(
            'https://api.whatsapp.com/send?phone=5511933930901&text=Ol%C3%A1,%20poderia%20me%20ajudar%3F'
          )
        }}
      >
        <WhatsApp fontSize="large" />
      </IconWrapper>
      <IconWrapper
        onClick={() => {
          window.open('https://www.facebook.com/vacinas.net/')
        }}
      >
        <Facebook fontSize="large" />
      </IconWrapper>
    </FooterContainer>
  )
}

export default MediaFooter

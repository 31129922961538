import { RolesEnum } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { firebaseAuth } from '../../config/firebase'
import api, { Api } from '../../services/api'
import { actions } from '../../state'
import { useSelector } from '../../state/store'
import LoadingContainer from '../../utils/LoadingContainer'

const AuthWrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const isLoadingUserData = useSelector((state) => state.user.isLoading)

  const handleSetRoles = (roles: RolesEnum[]) => {
    dispatch({ type: actions.auth.setRoles, payload: roles })
    dispatch({ type: actions.auth.setIsLogged, payload: true })
  }

  const handleSetApiToken = (token: string) => {
    Api.setToken(token)
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        setLoading(false)
      }
      user?.getIdTokenResult().then((result) => {
        const roles = (result?.claims?.roles || []) as RolesEnum[]
        if (roles) {
          handleSetRoles(roles)
        }
      })
    })

    firebaseAuth.onIdTokenChanged((user) => {
      user?.getIdToken(true).then((token) => {
        handleSetApiToken(token)
        dispatch(actions.user.getUserFetch())
        setLoading(false)
      })
    })

    api.onError((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        firebaseAuth.signOut()
        window.location.href = '/login'
      }
    })
  }, [])

  return loading || isLoadingUserData ? <LoadingContainer /> : <>{children}</>
}

export default AuthWrapper

import { Grid } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../../styles/mainTheme'

export const DataContainer = styled(Grid)`
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${mainTheme.spacing(1)}px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${mainTheme.palette.primary.light};
  padding: ${mainTheme.spacing(0.5)}px;
  border-radius: 4px;
`

export const UsedVouchersContainer = styled(DataContainer)`
  flex-direction: column;
  align-items: flex-start;
`

export const AvailableVoucherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${mainTheme.spacing(0.5)}px;
  margin-top: ${mainTheme.spacing(0.5)}px;
`

import React from 'react'
import { Typography } from '@mui/material'
import {
  Container,
  Heading,
  ContentContainer,
  DataContainer,
  ChartContainer,
  TotalsContainer,
  TotalItem,
  ProgressLabel,
} from './styles'
import CircularProgressWithLabel from '../CircularProgressWithLabel'
import CampaignStatusChip from '../chips/CampaignCardChip'
import DateRange from '../DateRange'
import { useNavigate } from 'react-router-dom'

interface CampaignCardProps {
  id: string
  name: string
  status: 'active' | 'finished' | 'notStarted'
  startDate: string
  endDate: string
  voucherTotal: number
  clinicTotal: number
  cityTotal: number
  usedPercentage: number
}

const CampaignCard = (props: CampaignCardProps) => {
  const navigate = useNavigate()
  return (
    <Container
      onClick={() => {
        navigate(`/campaign/${props.id}`)
      }}
    >
      <Heading>
        <CampaignStatusChip status={props.status} />
      </Heading>
      <ContentContainer>
        <DataContainer>
          <Typography variant="h1">{props.name}</Typography>
          <DateRange startDate={props.startDate} endDate={props.endDate} />
          <TotalsContainer>
            <TotalItem variant="h2">{`${props.voucherTotal} Vales`}</TotalItem>
            <TotalItem variant="h2">{`${props.clinicTotal} Clínicas`}</TotalItem>
            <TotalItem variant="h2">{`${props.cityTotal} Cidades`}</TotalItem>
          </TotalsContainer>
        </DataContainer>
        <ChartContainer>
          <CircularProgressWithLabel value={props.usedPercentage} />
          <ProgressLabel variant="h2">Utilização</ProgressLabel>
        </ChartContainer>
      </ContentContainer>
    </Container>
  )
}

export default CampaignCard

import React from 'react'
import { Grid, Typography, Button, List } from '@mui/material'
import {
  PaperContainer,
  PixCodeConatiner,
  StyledBadge,
  StyledListItem,
  StyledTypography,
} from './styles'
import { useSelector } from '../../../../../state/store'

const PixCodePayment = () => {
  const pixCode = useSelector(
    (state) => state.createCampaignOrder.pixPaymentCode
  )

  return (
    <PaperContainer elevation={0}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <PixCodeConatiner>
            <StyledTypography color={'textSecondary'} fontSize={18}>
              {pixCode}
            </StyledTypography>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(pixCode!)
              }}
              color="primary"
              variant="outlined"
            >
              Copiar
            </Button>
          </PixCodeConatiner>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={'left'} color={'primary'} variant="h2">
            Copia e Cola
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <StyledListItem>
              <StyledBadge color="primary" badgeContent={1} />
              <Typography color={'textSecondary'} variant="body1">
                Copie o código acima.
              </Typography>
            </StyledListItem>

            <StyledListItem>
              <StyledBadge color="primary" badgeContent={2} />
              <Typography color={'textSecondary'} variant="body1">
                Em seu app de pagamento, entre na seção do Pix, selecionando a
                opção de pagar com Código Copia e Cola.{' '}
              </Typography>
            </StyledListItem>

            <StyledListItem>
              <StyledBadge color="primary" badgeContent={3} />
              <Typography color={'textSecondary'} variant="body1">
                Cole o código, confirme o valor e demais dados e faça o
                pagamento!{' '}
              </Typography>
            </StyledListItem>
          </List>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

export default PixCodePayment

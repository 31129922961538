import { Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 205px;

  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
`

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 10px;
  align-items: center;
  padding: 0.3rem;

  .campaign-status-chip {
    position: relative;
    top: 1rem;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.2rem 0.8rem;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 65%;
`
export const TotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TotalItem = styled(Typography)`
  color: ${mainTheme.palette.primary.main};
`

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0.5rem;
`

export const ProgressLabel = styled(Typography)`
  color: #000000;
  margin-top: 0.5rem;
`

import { CancelOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { StyledDateFilter, DateFilterContainer } from './styles'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { format, isValid } from 'date-fns'

const DateFieldFilter = (props: {
  open: boolean
  label?: string
  initialValue?: string
  dispatchAction: (value: string) => void
  closeFilter: () => void
}) => {
  const { label, initialValue, dispatchAction, open, closeFilter } = props
  const [value, setValue] = useState(initialValue || null)

  return open ? (
    <DateFilterContainer>
      <IconButton
        onClick={() => {
          setValue('')
          dispatchAction('')
          closeFilter()
        }}
      >
        <CancelOutlined fontSize="medium" />
      </IconButton>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          label={label}
          value={value}
          onChange={(newDate) => {
            setValue(String(newDate))
            if (isValid(new Date(newDate!)))
              dispatchAction(format(new Date(newDate!), 'yyyy-MM-dd'))
          }}
          renderInput={(params) => (
            <StyledDateFilter
              size="small"
              color="primary"
              variant="outlined"
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </DateFilterContainer>
  ) : (
    <></>
  )
}

export default DateFieldFilter

import { Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'

const steps = ['Comprado', 'Agendado', 'Aplicado']

const OrderStatusStepper = (props: {
  step: 'purchased' | 'scheduled' | 'applied'
}) => {
  const getActiveStep = () => {
    switch (props.step) {
      case 'purchased':
        return 0
        break
      case 'scheduled':
        return 1
        break
      case 'applied':
        return 2
        break

      default:
        break
    }
  }
  return (
    <Stepper activeStep={getActiveStep()} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default OrderStatusStepper

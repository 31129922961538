// import { AssignmentTurnedInOutlined, DateRange } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
// import { ThemeProvider } from 'styled-components'
// import OrderMenuCard from '../../components/elements/OrderMenuCard'
// import { mainTheme } from '../../styles/mainTheme'
import { CustomerOrderContainer, LoadingOrdersContainer } from './styles'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../state/store'
import { actions } from '../../state'
import { Button, CircularProgress, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyleButtonViewMore } from '../Campaign/styles'
import OrderCard from '../../components/elements/OrderCard'

const CustomerOrders = () => {
  const loading = useSelector((state) => state.customerOrders.isLoading)
  const ordersArray = useSelector((state) => state.customerOrders.orders)
  const ordersTotal = useSelector((state) => state.customerOrders.total)
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({
    page: 0,
  })
  const limit = useSelector((state) => state.customerOrders.limit)
  const shouldRenderLoadMoreContainer =
    ordersArray.length < ordersTotal! && ordersArray.length > 0

  const getOrders = () => {
    dispatch(
      actions.customerOrders.getOrdersFetch({
        page: pagination.page,
        limit: limit,
        sort: 'name',
        sortOrder: 'asc',
      })
    )
  }

  useEffect(() => {
    if (!pagination.page) {
      dispatch(actions.customerOrders.resetOrders())
    }
    getOrders()
  }, [pagination])

  return (
    <CustomerOrderContainer>
      {/* <OrderMenuCard
        title="Seu próximo atendimento é em 15 dias!(Mocked)"
        subtitle="Seu atendimento será dia 26/07/22 ás  16:00 horas, na clínica Aquidabã - Aevnida Aquidabã 1250 Centro, Campinas/SP(Mocked)"
      >
        <ThemeProvider theme={mainTheme}>
          <DateRange color="primary" fontSize="inherit" />
        </ThemeProvider>
      </OrderMenuCard>
      <OrderMenuCard isVaccineSchema title="Caderneta de vacinação">
        <ThemeProvider theme={mainTheme}>
          <AssignmentTurnedInOutlined color="primary" fontSize="inherit" />
        </ThemeProvider>
      </OrderMenuCard> */}
      {loading && ordersArray.length === 0 ? (
        <LoadingOrdersContainer>
          <CircularProgress color="primary" size={40} />
        </LoadingOrdersContainer>
      ) : (
        ordersArray.map((order) => (
          <Grid key={order.orderId} item xs={12}>
            <OrderCard order={order} />
          </Grid>
        ))
      )}
      <div>
        <StyleButtonViewMore>
          {shouldRenderLoadMoreContainer && (
            <div>
              {!loading ? (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  startIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    setPagination({
                      page: pagination.page + 1,
                    })
                  }}
                >
                  Ver Mais
                </Button>
              ) : (
                <CircularProgress color="primary" size={40} />
              )}
            </div>
          )}
        </StyleButtonViewMore>
      </div>
    </CustomerOrderContainer>
  )
}
export default CustomerOrders

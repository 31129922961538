import { Typography } from '@mui/material'
import React from 'react'
import { mainTheme } from '../../../styles/mainTheme'
import { SummaryCardPaper, ContentContainer } from './styles'

const SummaryCard = (props: { quantity: number; title: string }) => {
  return (
    <SummaryCardPaper>
      <ContentContainer>
        <Typography color={mainTheme.palette.grey[400]} variant="h2">
          {props.title}
        </Typography>
        <Typography style={{ marginTop: mainTheme.spacing(1) }} variant="h1">
          {props.quantity}
        </Typography>
      </ContentContainer>
    </SummaryCardPaper>
  )
}

export default SummaryCard

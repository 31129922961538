import { Grid } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const CreateCampaignOrderLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: white;
`

export const CreateCampaignOrderContent = styled(Grid)`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${mainTheme.spacing(1.5)}px;
  padding-right: ${mainTheme.spacing(1.5)}px;
  padding-top: ${mainTheme.spacing(2)}px;
  padding-bottom: ${mainTheme.spacing(2)}px;

  max-width: 900px;
  width: 100%;

  ${mainTheme.breakpoints.up('md')} {
    padding-top: ${mainTheme.spacing(6)}px;
    padding-bottom: ${mainTheme.spacing(6)}px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-start;
    max-width: 600px;
    padding-top: 8%;
  }
`

export const CampaingOrderListWrapper = styled(CreateCampaignOrderContent)`
  padding-bottom: 0px;
  height: 80%;
  margin-bottom: ${mainTheme.spacing(3)}px;

  ${mainTheme.breakpoints.up('md')} {
    padding-bottom: ${mainTheme.spacing(3)}px;
  }
`

export const TitleGrid = styled(Grid)`
  width: 100%;
`

export const InputGrid = styled(Grid)`
  display: flex;
  gap: ${mainTheme.spacing(2)}px;
  margin-top: ${mainTheme.spacing(3)}px;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  ${mainTheme.breakpoints.up('md')} {
    width: 240px;
  }
`

export const VoucherCardGrid = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${mainTheme.spacing(2)}px;

  overflow-y: auto;
  width: 100%;
  margin-top: ${mainTheme.spacing(3)}px;

  ${mainTheme.breakpoints.up('md')} {
    width: 420px;
    padding-right: ${mainTheme.spacing(2)}px;
  }
`

export const SubmitGrid = styled(Grid)`
  display: flex;
  margin-top: ${mainTheme.spacing(3)}px;
  justify-content: flex-end;
  width: 100%;
`

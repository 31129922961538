import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const LoadingCampaignContainer = styled('div')`
  margin-top: ${mainTheme.spacing(6)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const StyleButtonViewMore = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: ${mainTheme.spacing(3)}px;
  margin-bottom: ${mainTheme.spacing(2)}px;
`

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`

import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'
import { DialogContent, Typography } from '@mui/material'

export const AddCustomerContainer = styled('div')`
  margin-top: ${mainTheme.spacing(2)}px;
`
export const ModalTypographyItem = styled(Typography)`
  margin-bottom: ${mainTheme.spacing(2)}px;
`
export const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const HighlightedText = styled('strong')`
  color: ${mainTheme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`
export const DialogContentContainer = styled(DialogContent)`
  ${mainTheme.breakpoints.up('md')} {
    width: ${mainTheme.breakpoints.values.md}px;
  }
  ${mainTheme.breakpoints.down('md')} {
    min-width: calc(100vw - 112px);
  }
`

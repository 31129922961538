import React from 'react'
import { Typography } from '@mui/material'
import { DateRangeContainer, DateItemContainer, DateItem } from './styles'

interface DateRangeProps {
  startDate: string
  endDate: string
}

const DateRange = (props: DateRangeProps) => {
  return (
    <DateRangeContainer>
      <DateItemContainer>
        <DateItem variant="h3">Data início:</DateItem>
        <Typography variant="body2">{props.startDate}</Typography>
      </DateItemContainer>
      <DateItemContainer>
        <DateItem variant="h3">Data fim:</DateItem>
        <Typography variant="body2">{props.endDate}</Typography>
      </DateItemContainer>
    </DateRangeContainer>
  )
}

export default DateRange

import { TextField } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const StyledDateFilter = styled(TextField)`
  max-width: 200px;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #b5b5b5;
  }
`
export const DateFilterContainer = styled('div')`
  min-width: 200px;
  display: flex;
  align-items: center;
  margin-right: ${mainTheme.spacing(1 / 2)}px;
`

import { Button } from '@mui/material'
import React, { useState } from 'react'
import { AddSingleCustomerContainer } from './styles'
import AddSingleCustomerModal from './AddSingleCustomerModal'

const AddSingleCustomer = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <AddSingleCustomerContainer>
      <Button onClick={() => setOpen(true)} size="large" variant="contained">
        Adicionar Beneficiário Manualmente
      </Button>
      <AddSingleCustomerModal
        open={open}
        setOpen={(value: boolean) => setOpen(value)}
        loading={loading}
        setLoading={(value: boolean) => setLoading(value)}
      />
    </AddSingleCustomerContainer>
  )
}

export default AddSingleCustomer

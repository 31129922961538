import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'
import { DialogContent, Grid } from '@mui/material'

export const AddSingleCustomerContainer = styled('div')`
  margin-top: ${mainTheme.spacing(2)}px;
`

export const DialogContentContainer = styled(DialogContent)`
  ${mainTheme.breakpoints.up('md')} {
    width: ${mainTheme.breakpoints.values.md}px;
  }
  ${mainTheme.breakpoints.down('md')} {
    min-width: calc(100vw - 112px);
  }
`

export const ActionsContainerGrid = styled(Grid)`
  margin-top: ${mainTheme.spacing(1)}px;
  margin-bottom: ${mainTheme.spacing(1)}px;
  display: flex;
  justify-content: flex-end;
`

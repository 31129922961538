import React from 'react'
import { Divider, Grid, Paper, Typography } from '@mui/material'
import styled from 'styled-components'
import { Vaccines } from '@mui/icons-material'
import { useSelector } from '../../../../../state/store'
import { valueInCentsToBRL } from '../../../../../utils/utils'
import { mainTheme } from '../../../../../styles/mainTheme'

export const PurpleBackgroundPaper = styled(Paper)`
  background-color: #f9f0ff;
  padding: ${mainTheme.spacing(2)}px;
  ${mainTheme.breakpoints.up('md')} {
    height: 100%;
    margin-bottom: 0px;
  }
`

export const StyledGrid = styled(Grid)`
  display: flex;
  margin-bottom: ${mainTheme.spacing(1)}px;
`

const StyledDivider = styled(Divider)`
  width: 100%;
  margin-top: ${mainTheme.spacing(1)}px;
  margin-bottom: ${mainTheme.spacing(2)}px;
`

const PaymentSummaryCard = (props: { style?: React.CSSProperties }) => {
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  return (
    <PurpleBackgroundPaper color="primary" elevation={0} style={props.style}>
      <Grid container xs={12}>
        <StyledGrid item xs={12}>
          <Typography marginBottom={1} variant="h3" color="primary">
            Resumo da compra
          </Typography>
        </StyledGrid>
        <StyledGrid direction="row" item xs={12}>
          <Vaccines
            style={{ marginRight: mainTheme.spacing(1) }}
            color="primary"
          />
          <Typography variant="body1" color="textPrimary">
            {campaign?.fullCommercialConditions![0].products[0].name
              ? `Produto: ${campaign?.fullCommercialConditions[0].products[0].name}`
              : 'Valor da vacina'}
          </Typography>
          <Typography
            style={{ marginLeft: 'auto' }}
            variant="body1"
            color="textPrimary"
          >
            {valueInCentsToBRL(campaign?.productPriceInCents)}
          </Typography>
        </StyledGrid>
        <StyledGrid direction="row" item xs={12}>
          <Vaccines
            style={{ marginRight: mainTheme.spacing(1) }}
            color="primary"
          />
          <Typography variant="body1" color="textPrimary">
            Desconto
          </Typography>
          <Typography
            style={{ marginLeft: 'auto' }}
            variant="body1"
            color="error"
          >
            -
            {campaign?.productPriceInCents &&
              campaign?.requiredCustomerPaymentInCents &&
              valueInCentsToBRL(
                campaign?.productPriceInCents -
                  campaign?.requiredCustomerPaymentInCents
              )}
          </Typography>
        </StyledGrid>
        <StyledDivider />
        <StyledGrid direction="row" item xs={12}>
          <Typography variant="h3" color="primary">
            Total
          </Typography>
          <Typography
            style={{ marginLeft: 'auto' }}
            variant="h3"
            color="primary"
          >
            {valueInCentsToBRL(campaign?.requiredCustomerPaymentInCents)}
          </Typography>
        </StyledGrid>
      </Grid>
    </PurpleBackgroundPaper>
  )
}

export default PaymentSummaryCard

import { CheckEmailPayload } from '../../state/login/types'
import api from '../api'

export const getMine = () => {
  return api.get('/user/me')
}

export const getUserEmailValidation = async (payload: CheckEmailPayload) => {
  return api.get(`/user/email/validation/${payload.email}`)
}

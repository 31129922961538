import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../Header'
import CustomBreadcrumbs from '../CustomBreadcrumbs'
import { LayoutContainer } from './styles'
import RedirectButtons from '../RedirectButtons/'
import { Grid } from '@mui/material'
import { useSelector } from '../../../state/store'

const Layout = () => {
  const isLogged = useSelector((state) => state.auth.isLogged)

  return (
    <div>
      <Header />
      <LayoutContainer>
        <Grid container justifyContent="space-between" alignItems="center">
          <CustomBreadcrumbs />
          <RedirectButtons />
        </Grid>
        {isLogged ? <Outlet /> : <Navigate to="/login" />}
      </LayoutContainer>
    </div>
  )
}

export default Layout

import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import { getMine } from '../../services/user'
import { errorActions } from '../errors'
import { actions } from './slice'

export function* sagaGetUserFetch(): SagaIterator<void> {
  try {
    const user = yield call(getMine)
    yield put(actions.getUserSuccess(user!))
  } catch (e) {
    console.error(e)
    yield put(actions.getUserError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [takeEvery(actions.getUserFetch.type, sagaGetUserFetch)]

import { LocalActivity } from '@mui/icons-material'
import MapIcon from '@mui/icons-material/Map'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import CampaignDataCard from '../../components/elements/CampaignDataCard'
import MenuCard from '../../components/elements/MenuCard'
import SummaryCard from '../../components/elements/SummaryCard'
import VouchersTable from '../../components/elements/VouchersTable'
import { actions } from '../../state/campaigns'
import { useSelector } from '../../state/store'
import { mainTheme } from '../../styles/mainTheme'
import { CamapaignDetailsContainer, MenuGrid, StyledGrid } from './styles'

const CampaignDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const campaign = useSelector((state) => state.campaigns.campaignDetails)
  const getCampaignDetails = () => {
    dispatch(
      actions.getCampaignDetailsFetch({ campaignId: params.campaignId! })
    )
  }
  useEffect(() => {
    getCampaignDetails()
  }, [])

  return (
    <CamapaignDetailsContainer>
      <MenuGrid style={{ marginTop: 0 }} container spacing={{ xs: 3, md: 6 }}>
        <StyledGrid item xs={12} md={3}>
          <MenuCard
            title="Utilização dos vales"
            url={`/campaign/${params.campaignId}/voucherReport`}
          >
            <ThemeProvider theme={mainTheme}>
              <LocalActivity color="primary" fontSize="inherit" />
            </ThemeProvider>
          </MenuCard>
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <MenuCard
            title="Rede de Aceitação"
            url={`/campaign/${params.campaignId}/acceptance`}
          >
            <MapIcon color="primary" fontSize="inherit" />
          </MenuCard>
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <MenuCard
            title="Pedidos da campanha"
            url={`/campaign/${params.campaignId}/orderReport`}
          >
            <StickyNote2Icon color="primary" fontSize="inherit" />
          </MenuCard>
        </StyledGrid>
      </MenuGrid>
      <MenuGrid>
        <CampaignDataCard />
      </MenuGrid>
      {campaign && (
        <MenuGrid container spacing={{ xs: 3, md: 6 }}>
          <Grid item xs={12} md={3}>
            <SummaryCard title="Vales" quantity={campaign.voucherQuantity!} />
          </Grid>
          <Grid item xs={12} md={3}>
            <SummaryCard
              title="Vales Utilizados"
              quantity={campaign.voucherUsed!}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SummaryCard
              title="Clínicas"
              quantity={campaign.clinicsQuantity!}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SummaryCard title="Cidades" quantity={campaign.cityQuantity!} />
          </Grid>
        </MenuGrid>
      )}
      <VouchersTable />
    </CamapaignDetailsContainer>
  )
}

export default CampaignDetails

import { FullVoucherReportReturnType } from '@vacinas-net/shared'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import {
  getAllVouchersOnReportDownloadUrl,
  getVoucherUsageReport,
} from '../../services/vouchers'
import { saveAs } from 'file-saver'
import { actions } from './slice'
import { DownloadAllVouchersAction, VoucherReportPagedGetAction } from './types'
import { errorActions } from '../errors'

export type getVoucherReportResponse = {
  total: number
  results: FullVoucherReportReturnType[]
}

export function* sagaGetVouchersFetch(
  action: VoucherReportPagedGetAction
): SagaIterator<void> {
  try {
    const { results, total }: getVoucherReportResponse = yield call(
      getVoucherUsageReport,
      action.payload
    )
    yield put(
      actions.getVouchersPagedSuccess({
        vouchers: results,
        total: total,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(actions.getVouchersPagedError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaDownloadAllVouchers(
  action: DownloadAllVouchersAction
): SagaIterator<void> {
  try {
    const { campaignId } = action.payload
    const vouchers = yield call(getAllVouchersOnReportDownloadUrl, campaignId)
    yield call(saveAs, vouchers, 'download.xlsx')
    yield put(actions.downloadAllVouchersSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.downloadAllVouchersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getVouchersPaged.type, sagaGetVouchersFetch),
  takeEvery(actions.downloadAllVouchers.type, sagaDownloadAllVouchers),
]

import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { Api } from '../services/api'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
export const emailSignIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
}
export const logout = () => {
  Api.setToken('')
  return signOut(firebaseAuth)
}

export const sendEmailPasswordReset = (email: string) => {
  return sendPasswordResetEmail(firebaseAuth, email, {
    url: String(process.env.REACT_APP_URL_FRONT),
  })
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorState } from './types'

export type SetErrorAction = PayloadAction<ErrorState>

const initialState: ErrorState[] = []

export const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, data: SetErrorAction) => {
      state.push(data.payload)
    },
    popError: (state) => {
      state.pop()
    },
  },
})

export const errorActions = { ...slice.actions }

export const reducer = slice.reducer

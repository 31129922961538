import React from 'react'
import VoucherUsageTable from '../../components/elements/VoucherUsageTable'
import { VoucherUsageReportContainer } from './styles'

const VoucherUsageReport = () => {
  return (
    <VoucherUsageReportContainer>
      <VoucherUsageTable />
    </VoucherUsageReportContainer>
  )
}

export default VoucherUsageReport

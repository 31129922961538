import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state/createCampaignOrder'
import { useSelector } from '../../../../state/store'
import { ShippingPolicy } from '@vacinas-net/shared'

const VaccinationPlaceSelector = () => {
  const dispatch = useDispatch()
  const shippingPolicy = useSelector(
    (state) => state.createCampaignOrder.shippingPolicy
  )
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const shouldShowCompanyOption = () => {
    return !!campaign?.totalAvailableAttendances
  }

  return (
    <>
      <Typography marginBottom={1} color="primary" variant="h1">
        Escolha o local de vacinação
      </Typography>
      <Grid container xs={12}>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shippingPolicy === ShippingPolicy.Clinic}
                onChange={() => {
                  dispatch(actions.setVaccinationPlace(ShippingPolicy.Clinic))
                  dispatch(actions.setSearchedZipCode(undefined))
                  dispatch(
                    actions.setCreateOrderFormOrder({
                      availableAttendanceId: undefined,
                      placeName: undefined,
                    })
                  )
                }}
              />
            }
            label="Vacinação na clínica"
          />
        </Grid>
        {shouldShowCompanyOption() && (
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shippingPolicy === ShippingPolicy.Company}
                  onChange={() => {
                    dispatch(
                      actions.setVaccinationPlace(ShippingPolicy.Company)
                    )
                    dispatch(actions.setSearchedZipCode(undefined))
                    dispatch(actions.setSelectedClinic(undefined))
                    dispatch(actions.setSearchedClinicsByZipCode([]))
                    dispatch(actions.setVaccineShotDay(undefined))
                    dispatch(actions.setVaccineShotTime(undefined))
                  }}
                />
              }
              label="Vacinação na empresa"
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default VaccinationPlaceSelector

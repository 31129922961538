import React from 'react'
import { Chip } from '@material-ui/core'

const CampaignStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'active':
      statusText = 'ATIVA'
      statusColor = '#30C836'
      break
    case 'finished':
      statusText = 'ENCERRADA'
      statusColor = '#9E9E9E'
      break
    case 'notStarted':
      statusText = 'FUTURA'
      statusColor = '#9E9E9E'
      break
    default:
      statusText = 'INDEFINIDO'
      statusColor = '#686868'
      break
  }
  return (
    <Chip
      style={{
        backgroundColor: statusColor,
        color: '#FFFFFF',
        fontWeight: 'bold',
      }}
      label={statusText}
      className="campaign-status-chip"
    />
  )
}

export default CampaignStatusChip

import { CampaignOrderListDTO } from '@vacinas-net/shared'
import { call, takeEvery, put, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import { actions } from './slice'
import { errorActions } from '../errors'
import {
  CampaignOrdersGetAction,
  DownloadAllCampaignOrdersAction,
} from './types'
import {
  getCampaignOrders,
  getDownloadCampaignOrdersUrl,
} from '../../services/campaign'
import { saveAs } from 'file-saver'

export type getCampaignOrdersResponse = {
  total: number
  results: CampaignOrderListDTO[]
}

export function* sagaGetCampaignOrdersFetch(
  action: CampaignOrdersGetAction
): SagaIterator<void> {
  try {
    const { results, total }: getCampaignOrdersResponse = yield call(
      getCampaignOrders,
      action.payload
    )
    yield put(
      actions.getCamapaignOrdersSuccess({
        vouchers: results,
        total: total,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(actions.getCamapaignOrdersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaDownloadCamapaignOrders(
  action: DownloadAllCampaignOrdersAction
): SagaIterator<void> {
  try {
    const campaignOrders = yield call(
      getDownloadCampaignOrdersUrl,
      action.payload
    )
    yield call(saveAs, campaignOrders, 'download.xlsx')
    yield put(actions.downloadCampaignOrdersSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.downloadCampaignOrdersError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getCamapaignOrders.type, sagaGetCampaignOrdersFetch),
  takeEvery(actions.downloadCampaignOrders.type, sagaDownloadCamapaignOrders),
]

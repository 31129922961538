import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const DropzoneContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 2px dashed ${mainTheme.palette.primary.main};
  border-radius: ${mainTheme.spacing(1)}px;
  padding: ${mainTheme.spacing(1)}px;
  width: 100%;
`

import { addDays, format } from 'date-fns'
import { VoucherReportGet } from '../../state/voucherReport/types'
import api from '../api'

export const getVoucherPackage = async (campaignId: string) => {
  return api.get(`/client/campaign/${campaignId}/voucherPackage`)
}

export const getAllVouchersDownloadUrl = async (campaignId: string) => {
  return api.get(`/client/campaign/${campaignId}/report/voucherPackages`)
}

export const getSingleVoucherPackageDownloadUrl = async (
  campaignId: string,
  voucherPackageId: string
) => {
  return api.get(
    `/client/campaign/${campaignId}/report/voucherPackages/${voucherPackageId}`
  )
}

export const getVoucherUsageReport = async (payload: VoucherReportGet) => {
  const { campaignId, page, limit, filters } = payload
  const params: URLSearchParams = new URLSearchParams()
  limit && params.set('limit', limit.toString())
  page.toString() && params.set('page', page.toString())

  filters?.voucherRedemptionCode &&
    params.set('voucherRedemptionCode', `%${filters.voucherRedemptionCode}%`)

  filters?.customerName &&
    params.set('customerName', `%${filters.customerName}%`)

  filters?.clinicName && params.set('clinicName', `%${filters.clinicName}%`)

  filters?.clinicCity && params.set('clinicCity', `%${filters.clinicCity}%`)

  filters?.usageStatus && params.set('usageStatus', filters.usageStatus)

  filters?.orderPurchasedAt &&
    params.set('order.purchasedAt(min)', filters.orderPurchasedAt)

  filters?.orderPurchasedAtEnd &&
    params.set(
      'order.purchasedAt(max)',
      format(addDays(new Date(filters.orderPurchasedAtEnd), 2), 'yyyy-MM-dd')
    )

  return api.get(`/client/campaign/${campaignId}/voucherReport?${params}`)
}

export const getAllVouchersOnReportDownloadUrl = async (campaignId: string) => {
  return api.get(`/client/campaign/${campaignId}/voucherUsageReport`) //[TODO] set correct URL
}

export const requestAdditionalPackage = async (
  campaignId: string,
  quantity: number
) => {
  return api.post(`/client/campaign/${campaignId}/additionalPackage`, {
    quantity: quantity,
  })
}

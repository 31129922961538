import { PayloadAction } from '@reduxjs/toolkit'

export enum statusEnum {
  notFound = 'not_found',
  alreadyExists = 'already_exists',
  passwordEmailSent = 'password_email_sent',
} //[TODO] use it from shared
export type ValidateUserByEmailUsecaseResponse = {
  status: statusEnum
  customerData?: {
    name: string
  }
} //[TODO] use it from shared

export type CheckEmailPayload = {
  email: string
}

type Steps = 'email' | 'firstAccess' | 'password' | 'error'

export type FormDataState = {
  step: Steps
  isLoading: boolean
  userValidation?: ValidateUserByEmailUsecaseResponse
}

export type CheckEmailAction = PayloadAction<CheckEmailPayload>
export type CheckEmaiResponseAction =
  PayloadAction<ValidateUserByEmailUsecaseResponse>

import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const AttendanceItemContainer = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid ${mainTheme.palette.primary.main};
  border-radius: ${mainTheme.spacing(2)}px;
  padding: ${mainTheme.spacing(2)}px;
  @media (max-width: 900px) {
    overflow: scroll;
  }
  @media print {
    overflow: unset;
  }
  margin-top: ${mainTheme.spacing(2)}px;
`

export const AttendanceItemDataCell = styled('div')`
  margin-right: ${mainTheme.spacing(4)}px;
`
export const DoubleInputsContainer = styled('div')`
  display: flex;
  width: 100%;
  margin-top: ${mainTheme.spacing(2)}px;
`

export const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  margin-top: ${mainTheme.spacing(2)}px;
  justify-content: flex-end;
`

export const PatientChipContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: ${mainTheme.spacing(1)}px;
`

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

export class Api {
  client: AxiosInstance
  static token: string

  constructor() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_ENTRYPOINT,
      timeout: 5 * 60 * 1000, // 5 min timeout temporary
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.client = client
  }

  static setToken(token: string) {
    this.token = `Bearer ${token}`
  }

  async get<T extends Record<string, unknown>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<T | T[]> {
    const response = await this.client.get(path, {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: Api.token,
      },
    })
    return response.data
  }

  async post<T extends Record<string, unknown>>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.post(path, payload, {
      headers: { Authorization: Api.token, 'Accept-Language': 'pt' },
    })
    return response.data
  }

  async postFormData<T extends Record<string, unknown>>(
    path: string,
    formData: FormData
  ): Promise<T> {
    const response = await this.client.post(path, formData, {
      headers: {
        Authorization: Api.token,
        'Content-Type': 'multipart/form-data',
        'Accept-Language': 'pt',
      },
    })
    return response.data
  }

  async patch<T extends Record<string, unknown>>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.patch(path, payload, {
      headers: { Authorization: Api.token },
    })
    return response.data
  }

  async put<T extends Record<string, unknown>>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.put(path, payload, {
      headers: { Authorization: Api.token },
    })
    return response.data
  }

  async delete(path: string): Promise<number> {
    const response = await this.client.delete(path, {
      headers: { Authorization: Api.token },
    })
    return response.data
  }

  onError(f: (error: AxiosError) => void) {
    this.client.interceptors.response.use(undefined, f)
  }
}

export default new Api()

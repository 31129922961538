import {
  CustomerAvailableCampaignDTO,
  IAllowedCustomer,
} from '@vacinas-net/shared'
import sum from 'lodash/sum'
import groupBy from 'lodash/groupBy'
import { Dictionary } from 'lodash'
import { IAvailableAttendance } from '@vacinas-net/shared'
import { format } from 'date-fns'
import { Iugu } from '../../components/modules/Iugu'

export const calculateAvailableVouchers = (
  campaign: CustomerAvailableCampaignDTO
) => {
  const allowedCustomers: IAllowedCustomer[] = []
  campaign.allowedCustomersBatches?.forEach((item) =>
    allowedCustomers.push(...item.allowedCustomers)
  )

  const givenVouchersAmount = calculateGivenVouchers(campaign)
  const usedVouchersAmount = calculateUsedVouchersAmount(campaign)

  return givenVouchersAmount - usedVouchersAmount
}

export const calculateGivenVouchers = (
  campaign: CustomerAvailableCampaignDTO
) => {
  const allowedCustomers: IAllowedCustomer[] = []
  campaign.allowedCustomersBatches?.forEach((item) =>
    allowedCustomers.push(...item.allowedCustomers)
  )

  const givenVouchersAmount = allowedCustomers.map(
    (item) => item.givenVoucherAmount
  )

  return sum(givenVouchersAmount)
}

export const calculateUsedVouchersAmount = (
  campaign: CustomerAvailableCampaignDTO
) => {
  const allowedCustomers: IAllowedCustomer[] = []
  campaign.allowedCustomersBatches?.forEach((item) =>
    allowedCustomers.push(...item.allowedCustomers)
  )

  const usedVouchersAmount = allowedCustomers.map(
    (item) => item.usedVouchers.length
  )

  return sum(usedVouchersAmount)
}

export const groupAvailableAttendances = (
  availableAttendancesArray?: IAvailableAttendance[]
) => {
  const groups = groupBy(availableAttendancesArray || [], 'address.name')

  return groups
}

export const calculateVacineDateOptions = (
  placeName: string,
  availableAttendanceGroups: Dictionary<IAvailableAttendance[]>
) => {
  if (!placeName) return []
  const group = availableAttendanceGroups[placeName]
  if (!group) return []
  const vaccineDateOptions = group
    .sort((itemA, itemB) => {
      const firstStartDateTime = new Date(itemA.startDatetime).getTime()
      const secondStartDateTime = new Date(itemB.startDatetime).getTime()
      return firstStartDateTime - secondStartDateTime
    })
    .map((availableAttendance) => {
      const startDatetime = new Date(availableAttendance.startDatetime)
      const endDatetime = new Date(availableAttendance.endDatetime)
      const startsAtLabel = format(startDatetime, 'dd/MM/yyyy - HH:mm')
      const endsAtLabel = format(endDatetime, 'HH:mm')
      return {
        value: availableAttendance._id,
        label: `${startsAtLabel} até ${endsAtLabel}`,
      }
    })

  return vaccineDateOptions
}

export const getCreditCardHiddenPreview = (number: string) => {
  const cardBrand = Iugu.utils.getBrandByCreditCardNumber(number)

  return cardBrand === 'amex'
    ? `Cartão de Crédito: **** ****** ${number.substring(
        number.length,
        number.length - 5
      )}`
    : cardBrand === 'diners'
    ? `Cartão de Crédito: **** ****** ${number.substring(
        number.length,
        number.length - 4
      )}`
    : `Cartão de Crédito: **** **** **** ${number.substring(
        number.length,
        number.length - 4
      )}`
}

import { Typography } from '@mui/material'
import styled from 'styled-components'

export const AttendanceDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Link = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`

import React, { useEffect } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import IconePix from '../../../../../assets/IconePix.png'
import styled from 'styled-components'
import { mainTheme } from '../../../../../styles/mainTheme'
import PixQrCodePayment from './PixQrCodePayment'
import PixCodePayment from './PixCodePayment'
import { useSelector } from '../../../../../state/store'
import { PaymentStatus, parseIuguStatus } from '@vacinas-net/shared'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../../state/createCampaignOrder'
import { useNavigate } from 'react-router-dom'
import { checkPaymentStatus } from '../../../../../services/createCampaignOrder'
import { CheckCircleOutline } from '@mui/icons-material'

const DemoImageStyles = styled('img')`
  margin-bottom: ${mainTheme.spacing(2)}px;
  margin-left: auto;
  margin-right: auto;
`

const ContainerGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PixPaymentStep = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const invoiceId = useSelector((state) => state.createCampaignOrder.invoiceId)
  const paymentStatus = useSelector(
    (state) => state.createCampaignOrder.paymentStatus
  )

  useEffect(() => {
    if (
      !invoiceId ||
      paymentStatus?.status === PaymentStatus.CANCELED ||
      paymentStatus?.status === PaymentStatus.ERROR
    ) {
      return
    }
    if (paymentStatus?.status === PaymentStatus.PAID) {
      setTimeout(() => {
        navigate('/campaign/order/receipt')
      }, 2000)
    }
    setTimeout(async () => {
      const updatedPaymentStatus = await checkPaymentStatus(invoiceId!)
      dispatch(
        actions.setPaymentStatus({
          status: parseIuguStatus(updatedPaymentStatus.status),
        })
      )
    }, 2000)
  }, [paymentStatus, invoiceId])
  return (
    <Grid container xs={12} md={6}>
      <ContainerGrid item xs={12}>
        <DemoImageStyles src={IconePix} />
        <Typography
          marginBottom={`${mainTheme.spacing(2)}px`}
          textAlign={'center'}
          color={'primary'}
          variant="h1"
        >
          Agora é só fazer o Pix!
        </Typography>
        <Typography
          textAlign={'center'}
          variant="body1"
          color={'textSecondary'}
        >
          Para finalizarmos seu agendamento, é só realizar o pagamento seguindo
          as instruções abaixo.
        </Typography>
      </ContainerGrid>
      <Grid item xs={12}>
        <PixQrCodePayment />
      </Grid>
      <Grid item xs={12}>
        <PixCodePayment />
      </Grid>
      {paymentStatus?.status === PaymentStatus.PROCESSING && (
        <ContainerGrid marginTop={`${mainTheme.spacing(2)}px`} item xs={12}>
          <CircularProgress size={50} color="primary" />
          <Typography
            marginTop={`${mainTheme.spacing(1)}px`}
            textAlign={'center'}
            variant="h2"
            color={'primary'}
          >
            Aguardando pagamento...
          </Typography>
        </ContainerGrid>
      )}
      {paymentStatus?.status === PaymentStatus.PAID && (
        <ContainerGrid marginTop={`${mainTheme.spacing(2)}px`} item xs={12}>
          <CheckCircleOutline
            color="primary"
            style={{
              height: 65,
              width: 65,
            }}
          />{' '}
          <Typography
            marginTop={`${mainTheme.spacing(1)}px`}
            textAlign={'center'}
            variant="h2"
            color={'primary'}
          >
            Concluído
          </Typography>
        </ContainerGrid>
      )}
    </Grid>
  )
}

export default PixPaymentStep

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClinic } from '@vacinas-net/shared'
import { ClinicMapState, FetchMapClinicsAction } from './types'

export type SetMapClinicsAction = PayloadAction<IClinic[]>

const initialState: ClinicMapState = {
  initialData: [],
  clinics: [],
  isLoading: false,
}

export const slice = createSlice({
  name: 'Mapclinics',
  initialState,
  reducers: {
    getMapClinicsFetch: (state, action: FetchMapClinicsAction) => {
      state.isLoading = true
    },
    getMapClinicsSuccess: (state, data: SetMapClinicsAction) => {
      state.clinics = data.payload
      state.initialData = data.payload
      state.isLoading = false
    },
    getMapClinicsError: (state) => {
      state.isLoading = false
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

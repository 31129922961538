import { Button, Typography } from '@mui/material'
import React from 'react'
import { Image, PageNotFoundContainer, TextContainer } from './styles'
import { mainTheme } from '../../styles/mainTheme'
import { useNavigate } from 'react-router-dom'
import VacinasLogoNoName from '../../assets/VacinasLogoNoName.png'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <PageNotFoundContainer>
      <div>
        <Image src={VacinasLogoNoName} />
      </div>
      <TextContainer>
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h1">
          Oops!
        </Typography>
        <Typography
          style={{ marginBottom: mainTheme.spacing(1) }}
          variant="body1"
        >
          Infelizmente não conseguimos encontrar a página que você está
          procurando
        </Typography>
        <div>
          <Button
            onClick={async () => {
              navigate('/')
            }}
            color="primary"
            variant="contained"
          >
            VOLTAR AO INÍCIO
          </Button>
        </div>
      </TextContainer>
    </PageNotFoundContainer>
  )
}

export default PageNotFound

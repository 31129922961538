import React from 'react'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { mainTheme } from '../../../../styles/mainTheme'
import AvailableClinicsList from './AvailableClinicsList'
import { useSelector } from '../../../../state/store'
import { Close } from '@mui/icons-material'
import { CloseButton } from '../styles'

const SelectClinicModal = (props: {
  open: boolean
  setOpen: (state: boolean) => void
}) => {
  const { open, setOpen } = props
  const zipCode = useSelector(
    (state) => state.createCampaignOrder.searchedZipCode
  )

  return (
    <Dialog
      onClose={() => {
        setOpen(false)
      }}
      open={open}
      maxWidth={'lg'}
    >
      <DialogTitle color="primary">Selecione uma clínica</DialogTitle>
      <DialogContent>
        <Typography
          style={{ marginBottom: mainTheme.spacing(2) }}
          variant="body1"
          color="textSecondary"
        >
          {`Exibindo clínicas próximas a ${zipCode}`}
        </Typography>
        <AvailableClinicsList closeModal={() => setOpen(false)} />
      </DialogContent>
      <CloseButton
        onClick={() => {
          setOpen(false)
        }}
        size="small"
      >
        <Close />
      </CloseButton>
    </Dialog>
  )
}

export default SelectClinicModal

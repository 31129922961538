import { combineReducers } from 'redux'
import * as auth from './auth'
import * as user from './user'
import * as campaigns from './campaigns'
import * as vouchers from './vouchers'
import * as clinicMap from './clinicMap'
import * as voucherReport from './voucherReport'
import * as errors from './errors'
import * as campaignOrders from './campaignOrders'
import * as login from './login'
import * as customerOrders from './customerOrders'
import * as customerAttendance from './customerAttendance'
import * as createCampaignOrder from './createCampaignOrder'

export const actions = Object.freeze({
  auth: auth.actions,
  user: user.actions,
  campaigns: campaigns.actions,
  vouchers: vouchers.actions,
  clinicMap: clinicMap.actions,
  voucherReport: voucherReport.actions,
  campaignOrders: campaignOrders.actions,
  errors: errors.errorActions,
  login: login.actions,
  customerOrders: customerOrders.actions,
  customerAttendance: customerAttendance.actions,
  createCampaignOrder: createCampaignOrder.actions,
})

export const reducers = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  campaigns: campaigns.reducer,
  vouchers: vouchers.reducer,
  clinicMap: clinicMap.reducer,
  voucherReport: voucherReport.reducer,
  campaignOrders: campaignOrders.reducer,
  errors: errors.reducer,
  login: login.reducer,
  customerOrders: customerOrders.reducer,
  customerAttendance: customerAttendance.reducer,
  createCampaignOrder: createCampaignOrder.reducer,
})

export const sagas = [
  ...user.sagas,
  ...campaigns.sagas,
  ...vouchers.sagas,
  ...clinicMap.sagas,
  ...voucherReport.sagas,
  ...campaignOrders.sagas,
  ...login.sagas,
  ...customerOrders.sagas,
  ...customerAttendance.sagas,
]

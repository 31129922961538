import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getCampaignDetails, getCampaigns } from '../../services/campaign'
import { errorActions } from '../errors'
import { actions } from './slice'
import { CampaignDetailsFetchAction, CampaignsFetchAction } from './types'

export function* sagaGetCampaignsFetch(
  action: CampaignsFetchAction
): SagaIterator<void> {
  try {
    const campaigns = yield call(getCampaigns, action.payload)
    yield put(actions.getCampaignsSuccess(campaigns.results))
    yield put(actions.setTotalCampaigns(campaigns.total))
  } catch (e) {
    console.error(e)
    yield put(actions.getCampaignsError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaGetCampaignDetailsFecth(
  action: CampaignDetailsFetchAction
): SagaIterator<void> {
  try {
    const { campaignId } = action.payload
    const campaignDetails = yield call(getCampaignDetails, campaignId)
    yield put(actions.getCampaignDetailsSuccess(campaignDetails))
  } catch (e) {
    yield put(actions.getCampaigDetailsError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(actions.getCampaignsFetch.type, sagaGetCampaignsFetch),
  takeLatest(actions.getCampaignDetailsFetch.type, sagaGetCampaignDetailsFecth),
]

import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const UnauthorizedContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: ${mainTheme.spacing(6)}px;
  @media (max-width: 900px) {
    margin-left: ${mainTheme.spacing(1)}px;
    margin-right: ${mainTheme.spacing(1)}px;
  }
`
export const Image = styled('img')`
  margin-left: ${mainTheme.spacing(1 / 2)}px;
  max-width: 400px;
  @media (max-width: 900px) {
    max-width: 200px;
  }
`

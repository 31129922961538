import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from '../state/store'
import {
  isCustomer,
  isEnterprise,
  PermissionChecker,
} from '@vacinas-net/shared'

export const ProtectedRoute = (permissionChecker: PermissionChecker) => {
  const isLogged = useSelector((state) => state.auth.isLogged)
  const location = useLocation()
  const user = useSelector((state) => state.user)

  if (!isLogged) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (!permissionChecker(user.user.roles)) {
    return <Navigate to="/" state={{ from: location }} replace />
  }
  return <Outlet />
}

export function RequireEnterpriseAuth() {
  return ProtectedRoute(isEnterprise)
}

export function RequireCustomerAuth() {
  return ProtectedRoute(isCustomer)
}

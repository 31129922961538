import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { mainTheme } from '../../../../styles/mainTheme'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state/createCampaignOrder'
import PaymentSummaryCard from './IuguCreditCardForm/PaymentSummaryCard'
import PaymentMethodSelectButtonAndForm from './IuguCreditCardForm/PaymentMethodSelectButtonAndForm'
import { useSelector } from '../../../../state/store'
import { Iugu } from '../../../../components/modules/Iugu'
import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import { errorActions } from '../../../../state/errors'
import styled from 'styled-components'

const CustomBreakpointGrid = styled(Grid)`
  ${mainTheme.breakpoints.up(1280)} {
    width: ${(8 / 12) * 100}%;
  }
`

const PaymentStep = () => {
  const dispatch = useDispatch()
  const iuguCreditCardFormData = useSelector(
    (state) => state.createCampaignOrder.iuguCreditCardFormData
  )
  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )
  const [loading, setLoading] = useState(false)

  const validateAndCreatePaymentToken = async () => {
    setLoading(true)
    await Iugu.createPaymentToken(
      {
        month: iuguCreditCardFormData?.expiration
          ? iuguCreditCardFormData?.expiration.split('/')[0]
          : '',
        year: iuguCreditCardFormData?.expiration
          ? iuguCreditCardFormData?.expiration.split('/')[1]
          : '',
        number: iuguCreditCardFormData?.number
          ? iuguCreditCardFormData?.number
          : '',
        verification_value: iuguCreditCardFormData?.verification_value
          ? iuguCreditCardFormData?.verification_value
          : '',
        first_name: iuguCreditCardFormData?.full_name
          ? _.first(iuguCreditCardFormData?.full_name.split(' '))
          : '',
        last_name: iuguCreditCardFormData?.full_name
          ? _.last(iuguCreditCardFormData?.full_name.split(' '))
          : '',
      },
      (response) => {
        if (response.errors) {
          const errorMessage =
            'Falha na tokenização do cartão, verifique seus dados. Caso o erro persista, contate o suporte.'

          dispatch(
            actions.setIuguCreditCardFormDataErrors({
              expiration: response.errors.expiration ? 'Valor inválido' : '',
              full_name:
                response.errors.first_name || response.errors.last_name
                  ? 'Valor inválido'
                  : '',
              number: response.errors.number ? 'Valor inválido' : '',
              verification_value: response.errors.verification_value
                ? 'Valor inválido'
                : '',
            })
          )
          dispatch(
            errorActions.setError({
              errorId: String(Date.now()),
              errorMessage,
            })
          )
          setLoading(false)
          return
        }
        dispatch(actions.setPaymentToken(response.id))
        dispatch(actions.setCreateOrderFormStep('review'))
        setLoading(false)
      }
    )
  }

  const goBack = () => {
    dispatch(actions.setCreateOrderFormStep('place'))
  }
  const validateAndGoToNextStep = async () => {
    if (paymentMethod === 'pix') {
      dispatch(actions.setCreateOrderFormStep('review'))
      return
    }
    await validateAndCreatePaymentToken()
  }

  return (
    <div>
      <CustomBreakpointGrid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h1" color="primary">
            Efetuar pagamento
          </Typography>
          <Typography variant="body2" color="textSecondary">
            A transação é feita de forma segura e criptografada.
          </Typography>
        </Grid>
        <Grid marginTop={`${mainTheme.spacing(2)}px`} container xs={12}>
          <PaymentMethodSelectButtonAndForm />
        </Grid>
        <Grid marginTop={`${mainTheme.spacing(2)}px`} container xs={12}>
          <PaymentSummaryCard style={{ width: '100%', height: 'unset' }} />
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: mainTheme.spacing(1),
          }}
          item
          xs={12}
        >
          <Button onClick={() => goBack()} variant="text" size="small">
            {'< Data e Local'}
          </Button>
          <LoadingButton
            onClick={() => {
              validateAndGoToNextStep()
            }}
            variant="contained"
            size="large"
            color="primary"
            style={{
              marginLeft: mainTheme.spacing(1),
            }}
            loading={loading}
          >
            Próximo
          </LoadingButton>
        </Grid>
      </CustomBreakpointGrid>
    </div>
  )
}

export default PaymentStep

import { Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const LabeledItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const LabeledItemHeader = styled(Typography)`
  color: ${mainTheme.palette.grey[300]};
`

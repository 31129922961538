import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../../styles/mainTheme'

const CampaignOrderStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'finished':
      statusText = 'FINALIZADO'
      statusColor = mainTheme.palette.success.main
      break
    case 'cancelled':
      statusText = 'CANCELADO'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'PROCESSANDO'
      statusColor = mainTheme.palette.warning.light
      break
  }
  return (
    <Chip
      style={{
        backgroundColor: statusColor,
        color: '#FFFFFF',
        fontWeight: 'bold',
      }}
      label={statusText}
      className="campaign-status-chip"
    />
  )
}

export default CampaignOrderStatusChip

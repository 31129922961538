import React from 'react'
import {
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../../state/createCampaignOrder'
import { useSelector } from '../../../../../state/store'
import { mainTheme } from '../../../../../styles/mainTheme'
import CreditCardForm from './CreditCardForm'
import PixBanner from '../Pix/PixBanner'
import { CreditCard, Pix } from '@mui/icons-material'

const PurpleBackgroundPaper = styled(Paper)`
  background-color: #f9f0ff;
  width: 100%;
  border: 1px solid #f9f0ff;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: ${mainTheme.spacing(2)}px;
`

const Container = styled('div')`
  display: flex;
  width: 100%;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const PaymentMethodSelectButtonAndForm = () => {
  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )

  const dispatch = useDispatch()

  return (
    <PurpleBackgroundPaper style={{ width: '100%' }} elevation={0}>
      <StyledFormControl>
        <RadioGroup
          aria-labelledby="payment-method-selector"
          value={paymentMethod}
          name="payment-method-selector"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (paymentMethod === 'pix') {
              dispatch(
                actions.setIuguCreditCardFormData({
                  expiration: '',
                  full_name: '',
                  number: '',
                  verification_value: '',
                })
              )
            }
            dispatch(actions.setPaymentMethod(event.target.value))
          }}
        >
          <StyledFormControlLabel
            value="creditCard"
            control={<Radio />}
            componentsProps={{
              typography: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              },
            }}
            label={
              <Container>
                <div>
                  <Typography color="primary" variant="body2">
                    <strong>Cartão de crédito</strong>
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    até 12x sem juros
                  </Typography>
                </div>
                <CreditCard
                  style={{ marginLeft: 'auto', alignSelf: 'center' }}
                  color="primary"
                />
              </Container>
            }
          />
          <Divider />
          {paymentMethod === 'creditCard' && <CreditCardForm />}
          <StyledFormControlLabel
            value="pix"
            control={<Radio />}
            componentsProps={{
              typography: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              },
            }}
            label={
              <Container>
                <div>
                  <Typography color="primary" variant="body2">
                    Pix
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    aprovação em minutos{' '}
                  </Typography>
                </div>
                <Pix
                  style={{ marginLeft: 'auto', alignSelf: 'center' }}
                  color="primary"
                />
              </Container>
            }
          />
          {paymentMethod === 'pix' && <PixBanner />}
        </RadioGroup>
      </StyledFormControl>
    </PurpleBackgroundPaper>
  )
}

export default PaymentMethodSelectButtonAndForm

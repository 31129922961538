import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TextFieldFilter from '../../TextFieldFilter'
import { actions } from '../../../../state'
import { FilterInputsContainer } from './styles'
import FilterManagerButton from '../../FilterManagerButton'
import SelectFieldFilter from '../../SelectFieldFilter'
import DateFieldFilter from '../../DateFieldFilter'

export type VoucherFilters = {
  isVoucherRedemptionCodeOpen: boolean
  isCustomerNameOpen: boolean
  isClinicNameOpen: boolean
  isClinicCityOpen: boolean
  isUsageStatusOpen: boolean
  isOrderPurchasedAtOpen: boolean
}

const statusOptionsArray = [
  {
    value: 'used',
    label: 'Utilizado',
  },
  {
    value: 'unused',
    label: 'Não utilizado',
  },
]

const VoucherFilters = () => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState<VoucherFilters>({
    isVoucherRedemptionCodeOpen: false,
    isCustomerNameOpen: false,
    isClinicNameOpen: false,
    isClinicCityOpen: false,
    isUsageStatusOpen: false,
    isOrderPurchasedAtOpen: false,
  })

  const menuItemsList = [
    {
      filterName: 'Vale',
      onClick: () => {
        setFilters({
          ...filters,
          isVoucherRedemptionCodeOpen: true,
        })
      },
    },
    {
      filterName: 'Cliente',
      onClick: () => {
        setFilters({
          ...filters,
          isCustomerNameOpen: true,
        })
      },
    },
    {
      filterName: 'Clínica',
      onClick: () => {
        setFilters({
          ...filters,
          isClinicNameOpen: true,
        })
      },
    },
    {
      filterName: 'Cidade',
      onClick: () => {
        setFilters({
          ...filters,
          isClinicCityOpen: true,
        })
      },
    },
    {
      filterName: 'Status',
      onClick: () => {
        setFilters({
          ...filters,
          isUsageStatusOpen: true,
        })
      },
    },
    {
      filterName: 'Data de compra',
      onClick: () => {
        setFilters({
          ...filters,
          isOrderPurchasedAtOpen: true,
        })
      },
    },
  ]

  return (
    <>
      <FilterManagerButton menuItemsList={menuItemsList} />
      {Object.values(filters).find((item) => item === true) && (
        <FilterInputsContainer>
          <TextFieldFilter
            open={filters.isVoucherRedemptionCodeOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setVoucherRedemptionCodeFilter({
                  voucherRedemptionCode: value,
                })
              )
            }}
            label="Vale"
            closeFilter={() => {
              setFilters({
                ...filters,
                isVoucherRedemptionCodeOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isCustomerNameOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setCustomerNameFilter({
                  customerName: value,
                })
              )
            }}
            label="Cliente"
            closeFilter={() => {
              setFilters({
                ...filters,
                isCustomerNameOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isClinicNameOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setClinicNameFilter({
                  clinicName: value,
                })
              )
            }}
            label="Clínica"
            closeFilter={() => {
              setFilters({
                ...filters,
                isClinicNameOpen: false,
              })
            }}
          />

          <TextFieldFilter
            open={filters.isClinicCityOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setClinicCityFilter({
                  clinicCity: value,
                })
              )
            }}
            label="Cidade"
            closeFilter={() => {
              setFilters({
                ...filters,
                isClinicCityOpen: false,
              })
            }}
          />

          <SelectFieldFilter
            open={filters.isUsageStatusOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setUsageStatusFilter({
                  usageStatus: value,
                })
              )
            }}
            label="Status"
            closeFilter={() => {
              setFilters({
                ...filters,
                isUsageStatusOpen: false,
              })
            }}
            items={statusOptionsArray}
          />

          <DateFieldFilter
            open={filters.isOrderPurchasedAtOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setOrderPurchasedAtFilter({
                  orderPurchasedAt: value,
                })
              )
            }}
            label="Data de Inicio"
            closeFilter={() => {
              setFilters({
                ...filters,
                isOrderPurchasedAtOpen: false,
              })
            }}
            initialValue={undefined}
          />
          <DateFieldFilter
            open={filters.isOrderPurchasedAtOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.voucherReport.setOrderPurchasedAtFilterEnd({
                  orderPurchasedAtEnd: value,
                })
              )
            }}
            label="Data de Fim"
            closeFilter={() => {
              setFilters({
                ...filters,
                isOrderPurchasedAtOpen: false,
              })
            }}
            initialValue={undefined}
          />
        </FilterInputsContainer>
      )}
    </>
  )
}

export default VoucherFilters

/*
  How to add filters:
  1. Add <TextFieldFilter /> Tag with correct parameters
  2. Add New Action related to that filter (slice file) to the dispatchAction prop
  3. Add the filter to the MenuItemList Array
  4. Add filter to the request function (ex: services/vouchers/index.ts)
*/

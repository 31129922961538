import { createMuiTheme } from '@material-ui/core/styles'

export const mainTheme = createMuiTheme({
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    h1: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 12,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    grey: {
      '100': '#F7F7F7',
      '200': '#ECECEC',
      '300': '#686868',
      '400': '#9FA2B4',
      '500': '#DFE0EB',
      '600': '#888888',
    },
    primary: {
      main: '#5B2674', //purple
      light: '#F9F0FF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0CB4B7', //lightblue
      dark: '#1A9496', //darkblue
      contrastText: '#fff',
    },
    error: {
      main: '#ED1C24',
      dark: '#BD0016',
      light: '#FF5C5C',
    },
    success: {
      main: '#30C836',
      dark: '#105494',
      light: '#4BA0EF',
    },
    warning: {
      main: '#F17E2B',
      light: '#FFB400',
    },
    text: {
      primary: '#333',
    },
  },
})

import { Button, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { emailSignIn } from '../../../config/firebase'
import { mainTheme } from '../../../styles/mainTheme'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import {
  ButtonContainer,
  ClickableTypography,
  FirstAccessTypographyWrapper,
  ForgotPasswordTypography,
  LoginContainer,
  PaddedTypography,
  PaddedTypography2,
  StyledTextfield,
  TextFieldWrapper,
  TitleTypographyWrapper,
  WrongMailTypography,
} from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Api } from '../../../services/api'
import { useDispatch } from 'react-redux'
import { errorActions } from '../../../state/errors'
import ForgetPasswordPopup from '../../elements/ForgetPasswordPopup'
import api from '../../../services/api'
import { useSelector } from '../../../state/store'
import { actions } from '../../../state'

export enum statusEnum {
  notFound = 'not_found',
  alreadyExists = 'already_exists',
  passwordEmailSent = 'password_email_sent',
} //[TODO] use it from shared

interface ILoginFormInputs {
  email: string
  password: string
}

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required()

const LoginForm = () => {
  const [openForgetPasswordPopup, setOpenForgetPasswordPopup] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginFormInputs>({
    resolver: yupResolver(schema),
  })
  const userValidation = useSelector((state) => state.login.userValidation)
  const loadingValidation = useSelector((state) => state.login.isLoading)
  const step = useSelector((state) => state.login.step)

  const onSubmit: SubmitHandler<ILoginFormInputs> = (data) => {
    setLoading(true)
    emailSignIn(data.email, data.password)
      .then(async (res) => {
        const token = await res.user.getIdToken(true)
        Api.setToken(token)
        await api.patch(`/user/me/last-signin`, {})
        setLoading(false)
        navigate('/')
      })
      .catch((error) => {
        console.error(error)
        if (
          error.message === 'Firebase: Error (auth/user-not-found).' ||
          error.message === 'Firebase: Error (auth/wrong-password).'
        ) {
          dispatch(
            errorActions.setError({
              errorId: String(Date.now()),
              errorMessage: 'Erro: Usuário ou Senha inválidos',
            })
          )
        } else {
          dispatch(
            errorActions.setError({
              errorId: String(Date.now()),
              errorMessage: (error as Error).message,
            })
          )
        }
        setLoading(false)
      })
      .finally(() => {
        dispatch(actions.login.setEmailStep())
      })
  }

  const checkMail = async () => {
    dispatch(
      actions.login.getEmailValidationFetch({
        email: control._fields.email?._f.value,
      })
    )
  }

  return (
    <LoginContainer onSubmit={handleSubmit(onSubmit)}>
      {step === 'email' && (
        <>
          <TitleTypographyWrapper>
            <Typography color="primary" variant="h1">
              Fazer login
            </Typography>
            <Typography variant="body1">
              Use o mesmo e-mail dos seus pedidos
            </Typography>
          </TitleTypographyWrapper>
          <TextFieldWrapper>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextfield
                  onChange={onChange}
                  value={value}
                  label="E-mail"
                  variant="outlined"
                  color="primary"
                  error={errors.email ? true : false}
                  helperText={errors.email ? 'E-mail Inválido' : false}
                  fullWidth
                />
              )}
            />
          </TextFieldWrapper>
          <ButtonContainer>
            <Button
              onClick={() => checkMail()}
              variant="contained"
              size="large"
            >
              {loadingValidation ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Próximo'
              )}
            </Button>
          </ButtonContainer>
        </>
      )}
      {step === 'error' && (
        <>
          <TitleTypographyWrapper>
            <Typography color="primary" variant="h1">
              Ops!!
            </Typography>
            <PaddedTypography variant="body1">
              Não conseguimos identificar o e-mail
              {userValidation?.customerData?.name}, esteja certo que esse e-mail
              foi utilizado em compras no site Vacinas.net
            </PaddedTypography>
          </TitleTypographyWrapper>
          <ButtonContainer>
            <Button
              onClick={() => dispatch(actions.login.setEmailStep())}
              variant="contained"
              size="large"
            >
              Tentar outro e-mail
            </Button>
          </ButtonContainer>
        </>
      )}
      {step === 'firstAccess' && (
        <>
          <FirstAccessTypographyWrapper>
            <Typography color="primary" variant="h1">
              Olá, {userValidation?.customerData?.name}!
            </Typography>
            <PaddedTypography2 variant="body1">
              Identificamos que é seu primeiro acesso, enviamos para o e-mail{' '}
              {control._fields.email?._f.value} um link para o processo de
              criação de senha. Verifique seu e-mail para continuar o processo.
            </PaddedTypography2>
          </FirstAccessTypographyWrapper>
          <WrongMailTypography
            color={mainTheme.palette.success.light}
            variant="body2"
            onClick={() => {
              dispatch(actions.login.setEmailStep())
            }}
          >
            Esse não é meu e-mail
          </WrongMailTypography>
          <ButtonContainer>
            <Button
              onClick={() => dispatch(actions.login.setPasswordStep())}
              variant="contained"
              size="large"
            >
              Prosseguir para login
            </Button>
          </ButtonContainer>
        </>
      )}
      {step === 'password' && (
        <>
          <TitleTypographyWrapper>
            <Typography color="primary" variant="h1">
              Olá,
            </Typography>
            <ClickableTypography
              onClick={() => dispatch(actions.login.setEmailStep())}
              variant="body1"
            >
              {control._fields.email?._f.value}
            </ClickableTypography>
          </TitleTypographyWrapper>
          <TextFieldWrapper>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextfield
                  onChange={onChange}
                  value={value}
                  type="password"
                  label="Senha"
                  variant="outlined"
                  color="primary"
                  error={errors.password ? true : false}
                  helperText={errors.password ? 'Senha Inválida' : false}
                  fullWidth
                />
              )}
            />
            <ForgotPasswordTypography
              color={mainTheme.palette.success.light}
              textAlign="right"
              variant="body2"
              onClick={() => {
                setOpenForgetPasswordPopup(true)
              }}
            >
              Esqueceu sua senha?
            </ForgotPasswordTypography>
          </TextFieldWrapper>
          <ButtonContainer>
            <Button variant="contained" size="large" type="submit">
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Entrar'
              )}
            </Button>
          </ButtonContainer>
          <ForgetPasswordPopup
            open={openForgetPasswordPopup}
            handleClose={() => setOpenForgetPasswordPopup(false)}
          />
        </>
      )}
    </LoginContainer>
  )
}

export default LoginForm

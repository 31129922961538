import { IPatient } from '@vacinas-net/shared'
import api from '../api'

export const getCustomerAttendance = async (attendanceUUID: string) => {
  return api.get(`/customer/attendance/external/${attendanceUUID}`)
}

export const registerPatient = async (
  patient: IPatient,
  attendanceUUID: string,
  orderItemId: string
) => {
  return api.put(
    `/customer/attendance/external/${attendanceUUID}/patient-data/${orderItemId}`,
    { ...patient }
  )
}

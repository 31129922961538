import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TextFieldFilter from '../../TextFieldFilter'
import { actions } from '../../../../state'
import FilterManagerButton from '../../FilterManagerButton'
import SelectFieldFilter from '../../SelectFieldFilter'
import DateFieldFilter from '../../DateFieldFilter'
import { FilterInputsContainer } from '../../../../utils/sharedStyles'

export type OrderReportFilters = {
  isCouponFilterOpen: boolean
  isOrderFilterOpen: boolean
  isCustomerNameFilterOpen: boolean
  isCustomerDocumentFilterOpen: boolean
  isCityFilterOpen: boolean
  isClinicFilterOpen: boolean
  isStatusFilterOpen: boolean
  isOrderPurchasedAtOpen: boolean
  isHasVoucherFilterOpen: boolean
}

const statusOptionsArray = [
  {
    value: 'finished',
    label: 'Finalizado',
  },
  {
    value: 'cancelled',
    label: 'Cancelado',
  },
  {
    value: 'processing',
    label: 'Processando',
  },
]

const OrderReportFilters = () => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState<OrderReportFilters>({
    isOrderFilterOpen: false,
    isCustomerNameFilterOpen: false,
    isCustomerDocumentFilterOpen: false,
    isCityFilterOpen: false,
    isClinicFilterOpen: false,
    isStatusFilterOpen: false,
    isOrderPurchasedAtOpen: false,
    isCouponFilterOpen: false,
    isHasVoucherFilterOpen: false,
  })

  const menuItemsList = [
    {
      filterName: 'Pedido',
      onClick: () => {
        setFilters({
          ...filters,
          isOrderFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Cliente',
      onClick: () => {
        setFilters({
          ...filters,
          isCustomerNameFilterOpen: true,
        })
      },
    },
    {
      filterName: 'CPF',
      onClick: () => {
        setFilters({
          ...filters,
          isCustomerDocumentFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Clínica',
      onClick: () => {
        setFilters({
          ...filters,
          isCityFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Cidade',
      onClick: () => {
        setFilters({
          ...filters,
          isClinicFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Situação',
      onClick: () => {
        setFilters({
          ...filters,
          isStatusFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Data de compra',
      onClick: () => {
        setFilters({
          ...filters,
          isOrderPurchasedAtOpen: true,
        })
      },
    },
    {
      filterName: 'Cupom',
      onClick: () => {
        setFilters({
          ...filters,
          isCouponFilterOpen: true,
        })
      },
    },
    {
      filterName: 'Possui Vales?',
      onClick: () => {
        setFilters({
          ...filters,
          isHasVoucherFilterOpen: true,
        })
      },
    },
  ]

  return (
    <>
      <FilterManagerButton menuItemsList={menuItemsList} />
      {Object.values(filters).find((item) => item === true) && (
        <FilterInputsContainer>
          <TextFieldFilter
            open={filters.isOrderFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setOrderIdFilter({
                  orderId: value,
                })
              )
            }}
            label="Pedido"
            closeFilter={() => {
              setFilters({
                ...filters,
                isOrderFilterOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isCouponFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setCouponFilter({
                  coupon: value,
                })
              )
            }}
            label="Cupom"
            closeFilter={() => {
              setFilters({
                ...filters,
                isCouponFilterOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isCustomerNameFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setCustomerNameFilter({
                  customerName: value,
                })
              )
            }}
            label="Cliente"
            closeFilter={() => {
              setFilters({
                ...filters,
                isCustomerNameFilterOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isCustomerDocumentFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setCustomerDocumentFilter({
                  customerDocument: value,
                })
              )
            }}
            label="CPF"
            closeFilter={() => {
              setFilters({
                ...filters,
                isCustomerDocumentFilterOpen: false,
              })
            }}
          />
          <TextFieldFilter
            open={filters.isCityFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setClinicNameFilter({
                  clinicName: value,
                })
              )
            }}
            label="Clínica"
            closeFilter={() => {
              setFilters({
                ...filters,
                isCityFilterOpen: false,
              })
            }}
          />

          <TextFieldFilter
            open={filters.isClinicFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setCityFilter({
                  city: value,
                })
              )
            }}
            label="Cidade"
            closeFilter={() => {
              setFilters({
                ...filters,
                isClinicFilterOpen: false,
              })
            }}
          />

          <SelectFieldFilter
            open={filters.isStatusFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setStatusFilter({
                  status: value,
                })
              )
            }}
            label="Situação"
            closeFilter={() => {
              setFilters({
                ...filters,
                isStatusFilterOpen: false,
              })
            }}
            items={statusOptionsArray}
          />

          <DateFieldFilter
            open={filters.isOrderPurchasedAtOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setOrderPurchasedAtFilter({
                  orderPurchasedAt: value,
                })
              )
            }}
            label="Comprado antes de"
            closeFilter={() => {
              setFilters({
                ...filters,
                isOrderPurchasedAtOpen: false,
              })
            }}
            initialValue={undefined}
          />
          <DateFieldFilter
            open={filters.isOrderPurchasedAtOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setOrderPurchasedAtFilterEnd({
                  orderPurchasedAtEnd: value,
                })
              )
            }}
            label="Comprado depois de"
            closeFilter={() => {
              setFilters({
                ...filters,
                isOrderPurchasedAtOpen: false,
              })
            }}
            initialValue={undefined}
          />

          <SelectFieldFilter
            open={filters.isHasVoucherFilterOpen}
            dispatchAction={(value) => {
              dispatch(
                actions.campaignOrders.setHasVoucherStatusFilter({
                  status: value,
                })
              )
            }}
            label="Possui Vales?"
            closeFilter={() => {
              setFilters({
                ...filters,
                isHasVoucherFilterOpen: false,
              })
            }}
            items={[
              {
                value: 'yes',
                label: 'Sim',
              },
              {
                value: 'no',
                label: 'Não',
              },
            ]}
          />
        </FilterInputsContainer>
      )}
    </>
  )
}

export default OrderReportFilters

/*
  How to add filters:
  1. Add <TextFieldFilter /> Tag with correct parameters
  2. Add New Action related to that filter (slice file) to the dispatchAction prop
  3. Add the filter to the MenuItemList Array
  4. Add filter to the request function (ex: services/vouchers/index.ts)
*/

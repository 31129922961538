import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const FooterContainer = styled('div')`
  width: 100%;
  background-color: ${mainTheme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding-top: ${mainTheme.spacing(1)}px;
  padding-bottom: ${mainTheme.spacing(1 / 2)}px;
`
export const IconWrapper = styled('div')`
  margin-left: ${mainTheme.spacing(1)}px;
  margin-right: ${mainTheme.spacing(1)}px;
  color: white;
  cursor: pointer;
`

import { Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from '../../../state/store'
import {
  CreateCampaignOrderLayout,
  TitleGrid,
  SubmitGrid,
  VoucherCardGrid,
  CampaingOrderListWrapper,
} from '../styles'
import { useNavigate } from 'react-router-dom'
import CampaignCard from './CampaignCard'
import CreateCampaignOrderFooter from '../CreateCampaignOrderFooter'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state/createCampaignOrder'
import { ShippingPolicy } from '@vacinas-net/shared'

const CampaignListStep = () => {
  const campaigns = useSelector((state) => state.createCampaignOrder.campaigns)
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.setVaccinationPlace(ShippingPolicy.Clinic))
    if (!campaigns || !campaigns.length) navigate('/campaign/order')
  }, [])

  const goBack = () => {
    navigate('/campaign/order')
  }

  return (
    <CreateCampaignOrderLayout>
      <CampaingOrderListWrapper>
        <TitleGrid item>
          <Typography color="primary" variant={'h1'}>
            Início do Agendamento
          </Typography>
          <Typography marginTop={1} color="black" variant={'body1'}>
            Seja muito bem vinda(o). Confira seus vales abaixo.
            <br />
            Para cada vale vacina, você pode agendar a aplicação de uma dose.
          </Typography>
        </TitleGrid>

        <VoucherCardGrid>
          {campaigns?.map((campaign, idx) => (
            <CampaignCard key={idx} campaign={campaign} />
          ))}
        </VoucherCardGrid>

        <SubmitGrid
          item
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={2}
        >
          <Button onClick={() => goBack()} variant="text" size="small">
            {'< Voltar'}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              dispatch(
                actions.setIuguCreditCardFormData({
                  installmentsNumber: 1,
                  installmentsValue: campaign?.requiredCustomerPaymentInCents,
                })
              )
              navigate('/campaign/order/schedule')
            }}
            disabled={!campaign?._id}
          >
            Iniciar agendamento
          </Button>
        </SubmitGrid>
      </CampaingOrderListWrapper>
      <CreateCampaignOrderFooter />
    </CreateCampaignOrderLayout>
  )
}

export default CampaignListStep

import { Button, Typography } from '@mui/material'
import React from 'react'
import { Image, TextContainer, UnauthorizedContainer } from './styles'
import { mainTheme } from '../../styles/mainTheme'
import { useNavigate } from 'react-router-dom'
import VacinasLogoNoName from '../../assets/VacinasLogoNoName.png'
import { logout } from '../../config/firebase'
import { useDispatch } from 'react-redux'
import { actions } from '../../state'

const Unauthorized = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <UnauthorizedContainer>
      <div>
        <Image src={VacinasLogoNoName} />
      </div>
      <TextContainer>
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h1">
          Oops!
        </Typography>
        <Typography
          style={{ marginBottom: mainTheme.spacing(1) }}
          variant="body1"
        >
          Você não tem autorização para acessar esta página!
        </Typography>
        <div>
          <Button
            onClick={async () => {
              dispatch(actions.user.clearUser())
              dispatch(actions.auth.setIsLogged(false))
              await logout()
              navigate('/login')
            }}
            color="primary"
            variant="contained"
          >
            VOLTAR
          </Button>
        </div>
      </TextContainer>
    </UnauthorizedContainer>
  )
}

export default Unauthorized

import { Paper } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const SummaryCardPaper = styled(Paper)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: ${mainTheme.spacing(3)}px;
`
export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

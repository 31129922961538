import { Grid } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const CamapaignDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const MenuGrid = styled(Grid)`
  margin-bottom: ${mainTheme.spacing(3)}px;
`

export const StyledGrid = styled(Grid)`
  @media (min-width: 900px) {
    padding-top: 0px !important;
  }
`

import { CancelOutlined } from '@mui/icons-material'
import { FormControl, IconButton, InputLabel, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { StyledSelectFilter, SelectFilterContainer } from './styles'

const SelectFieldFilter = (props: {
  open: boolean
  label?: string
  initialValue?: string
  dispatchAction: (value: string) => void
  closeFilter: () => void
  items: {
    value: string
    label: string
  }[]
}) => {
  const { label, initialValue, dispatchAction, open, closeFilter, items } =
    props
  const [value, setValue] = useState(initialValue || '')
  return open ? (
    <SelectFilterContainer>
      <IconButton
        onClick={() => {
          setValue('')
          dispatchAction('')
          closeFilter()
        }}
      >
        <CancelOutlined fontSize="medium" />
      </IconButton>
      <FormControl fullWidth size="small">
        <InputLabel id={`${label}-input`}>{label}</InputLabel>
        <StyledSelectFilter
          labelId={`${label}-input`}
          id={`${label}-select`}
          value={value}
          label={label}
          onChange={(event) => {
            setValue(String(event.target.value))
            dispatchAction(String(event.target.value))
          }}
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelectFilter>
      </FormControl>
    </SelectFilterContainer>
  ) : (
    <></>
  )
}

export default SelectFieldFilter

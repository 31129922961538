import { TextField } from '@mui/material'
import styled from 'styled-components'

export const AdditionalVouchersContainer = styled('div')`
  display: flex;
  align-items: center;
`
export const StyledTextField = styled(TextField)`
  width: 175px;

  input:-webkit-autofill {
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000000;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #333;
  }

  ,
  & .MuiOutlinedInput-input {
    padding: 10px;
  }

  & .MuiInputLabel-root {
    top: auto;
    left: auto;
  }
`

import React from 'react'
import GoogleMapReact, { Coords } from 'google-map-react'
import CustomMarker from '../CustomMarker'
import { googleApiUrl } from '../../../../utils/utils'

const MapWithMarkers = (props: {
  markersCoordinates: Coords[]
  center?: Coords
  zoom?: number
}) => {
  const { markersCoordinates, center, zoom } = props
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: googleApiUrl,
      }}
      defaultCenter={center || undefined}
      defaultZoom={zoom || undefined}
    >
      {markersCoordinates.map((marker, index) => (
        <CustomMarker
          key={`${marker.lat} ${marker.lng} ${index}`}
          lat={marker.lat}
          lng={marker.lng}
        />
      ))}
    </GoogleMapReact>
  )
}

export default MapWithMarkers

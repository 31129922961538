import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getCustomerAttendance,
  registerPatient,
} from '../../services/customerAttendance'
import { errorActions } from '../errors'
import { actions } from './slice'
import { CustomerAttendanceFetchAction, RegisterPatientAction } from './types'

export function* sagaCustomerExternalAttendanceFetch(
  action: CustomerAttendanceFetchAction
): SagaIterator<void> {
  try {
    const { attendanceUUID } = action.payload
    const customerAttendance = yield call(getCustomerAttendance, attendanceUUID)
    yield put(actions.getCustomerAttendanceSuccess(customerAttendance))
  } catch (e) {
    console.error(e)
    yield put(actions.getCustomerAttendanceError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export function* sagaRegisterPatient(
  action: RegisterPatientAction
): SagaIterator<void> {
  try {
    const { attendanceUUID, orderItemId, patient } = action.payload
    yield call(registerPatient, patient, attendanceUUID, orderItemId)
    yield put(actions.registerPatientSuccess())
    yield put(
      actions.getCustomerAttendanceFetch({
        attendanceUUID: attendanceUUID,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(actions.registerPatientError())
    yield put(
      errorActions.setError({
        errorId: String(Date.now()),
        errorMessage: (e as Error).message,
      })
    )
  }
}

export const sagas = [
  takeLatest(
    actions.getCustomerAttendanceFetch.type,
    sagaCustomerExternalAttendanceFetch
  ),
  takeLatest(actions.registerPatient.type, sagaRegisterPatient),
]

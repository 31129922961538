import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SubmitSection from './SubmitSection'
import { mainTheme } from '../../../../styles/mainTheme'
import styled from 'styled-components'
import PaymentSummaryCard from '../PaymentStep/IuguCreditCardForm/PaymentSummaryCard'
import PaymentMethodCard from './PaymentMethodCard'
import PersonalDataSection from './PersonalDataSection'
import PlaceSection from './PlaceSection'
import { useSelector } from '../../../../state/store'
import ProcessingPaymentModal from './ProcessingPaymentModal'

const ScheduleOrderReviewGrid = styled(Grid)`
  width: 100%;
  gap: ${mainTheme.spacing(2.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    max-width: 700px;
  }
`

const BottomGrid = styled(Grid)`
  margin-top: 0px;
  ${mainTheme.breakpoints.up('md')} {
    margin-top: 32px;
  }
`

const ScheduleOrderReview = () => {
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const paymentMethod = useSelector(
    (state) => state.createCampaignOrder.paymentMethod
  )

  return (
    <ScheduleOrderReviewGrid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="h1" marginBottom={1.5}>
          Confirme as informações
        </Typography>
        <Typography variant="h2" fontWeight={'normal'}>
          Certifique-se que todos os dados estão preenchidos corretamente antes
          de agendar.
        </Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={2} xs={12}>
          <Grid xs={12} item md={6}>
            <PersonalDataSection />
          </Grid>
          <Grid xs={12} item md={6}>
            <PlaceSection />
          </Grid>
        </Grid>
        <BottomGrid container spacing={2} xs={12}>
          {campaign?.requiredCustomerPayment && (
            <Grid xs={12} item md={6}>
              <PaymentMethodCard />
            </Grid>
          )}
          {campaign?.requiredCustomerPayment && (
            <Grid xs={12} item md={6}>
              <PaymentSummaryCard />
            </Grid>
          )}
        </BottomGrid>
      </Grid>
      {paymentMethod === 'creditCard' && <ProcessingPaymentModal />}
      <SubmitSection />
    </ScheduleOrderReviewGrid>
  )
}

export default ScheduleOrderReview

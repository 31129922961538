import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { Search } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import ClinicMapList from './ClinicMapList'

// import httpClient from '../CustomHttpClient'
// import { apiUrl } from '../AppDataProvider'
// import { OptionType } from '../CustomAutocomplete'
import { IClinic } from '@vacinas-net/shared'
import MapWithMarkers from './MapWithMarkers'
import { parseAddress } from '../../../utils/utils'
import { mainTheme } from '../../../styles/mainTheme'
import { useParams } from 'react-router-dom'
import {
  ClinicMapPaper,
  LoadingContainer,
  MapContainer,
  SearchContainer,
} from './styles'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state'

const ClinicMap = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.clinicMap.isLoading)
  const initialData = useSelector((state) => state.clinicMap.initialData)

  const [searchValue, setSearchValue] = useState('')
  const [filteredClinics, setFilteredClinics] = useState<IClinic[]>([])
  const params = useParams()

  const fetchClinics = async () => {
    dispatch(
      actions.clinicMap.getMapClinicsFetch({ campaignId: params.campaignId! })
    )
  }

  const filterClinicsList = () => {
    setFilteredClinics(
      initialData.filter(
        (clinic) =>
          clinic.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          parseAddress(clinic.address)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      )
    )
  }

  useEffect(() => {
    setFilteredClinics(initialData)
  }, [initialData])

  useEffect(() => {
    fetchClinics()
  }, [])

  useEffect(() => {
    filterClinicsList()
  }, [searchValue])

  return loading ? (
    <LoadingContainer>
      <CircularProgress size={40} color="primary" />
    </LoadingContainer>
  ) : (
    <ClinicMapPaper>
      <SearchContainer>
        <Typography
          style={{
            marginTop: mainTheme.spacing(1),
            marginLeft: mainTheme.spacing(1),
            marginBottom: mainTheme.spacing(2),
          }}
          variant="h2"
        >{`Rede de aceitação da campanha`}</Typography>
        <Box>
          <TextField
            value={searchValue}
            onChange={(ev) => {
              setSearchValue(ev.target.value)
            }}
            label="Buscar por clínica ou endereço"
            variant="outlined"
            size="small"
            style={{
              marginLeft: mainTheme.spacing(1),
              marginBottom: mainTheme.spacing(2),
            }}
            data-testid={'input-search-clinic-group'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <ClinicMapList clinicsArray={filteredClinics}></ClinicMapList>
        </Box>
      </SearchContainer>
      <MapContainer>
        <MapWithMarkers
          markersCoordinates={filteredClinics
            .filter(
              (clinic) =>
                clinic.address?.geoCoordinates?.lat &&
                clinic.address?.geoCoordinates?.lng
            )
            .map((clinic) => {
              return {
                lat: Number(clinic.address?.geoCoordinates?.lat),
                lng: Number(clinic.address?.geoCoordinates?.lng),
              }
            })}
          center={{ lat: -14.6222409, lng: -49.2810116 }}
          zoom={4}
        />
      </MapContainer>
    </ClinicMapPaper>
  )
}

export default ClinicMap

import React from 'react'
import { Typography } from '@mui/material'
import PixLogo from '../../../../../assets/PixLogo.png'
import { ImageStyles, PixBannerContainer } from './styles'

const PixDescription = () => {
  return (
    <PixBannerContainer>
      <ImageStyles src={PixLogo} />
      <Typography textAlign={'center'} variant="body2" color={'textSecondary'}>
        Para gerarmos o código QR, basta finalizar seu agendamento e seguir as
        instruções.
      </Typography>
    </PixBannerContainer>
  )
}

export default PixDescription

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICampaignV2Client } from '@vacinas-net/shared'
import {
  CheckEmailAction,
  CheckEmaiResponseAction,
  FormDataState,
  statusEnum,
  ValidateUserByEmailUsecaseResponse,
} from './types'

const initialState: FormDataState = {
  step: 'email',
  userValidation: undefined,
  isLoading: false,
}

export const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    getEmailValidationFetch: (state, action: CheckEmailAction) => {
      state.isLoading = true
    },
    getEmailValidationSuccess: (state, data: CheckEmaiResponseAction) => {
      state.userValidation = data.payload
      state.isLoading = false
      if (state.userValidation.status === statusEnum.alreadyExists) {
        state.step = 'password'
      } else if (state.userValidation.status === statusEnum.passwordEmailSent) {
        state.step = 'firstAccess'
      } else {
        state.step = 'error'
      }
    },
    getEmailValidationError: (state) => {
      state.isLoading = false
    },
    setEmailStep: (state) => {
      state.step = 'email'
    },
    setErrorStep: (state) => {
      state.step = 'error'
    },
    setFirstAccessStep: (state) => {
      state.step = 'firstAccess'
    },
    setPasswordStep: (state) => {
      state.step = 'password'
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

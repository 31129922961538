import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { reducers as rootReducer, sagas as rootSagas } from './'

const sagaMiddleware = createSagaMiddleware()

function* rootSaga() {
  yield all([...rootSagas])
}

const middlewares = applyMiddleware(sagaMiddleware)

export const store = createStore(rootReducer, middlewares)

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export default store

import { Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '../../state/store'
import CreateCampaignOrderFooter from './CreateCampaignOrderFooter'
import {
  CreateCampaignOrderContent,
  CreateCampaignOrderLayout,
  SubmitGrid,
  TitleGrid,
} from './styles'

const CampaignNotFoundStep = () => {
  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const navigate = useNavigate()

  useEffect(() => {
    if (!customer.document) navigate('/campaign/order')
  }, [])

  return (
    <CreateCampaignOrderLayout>
      <CreateCampaignOrderContent container xs={12}>
        <TitleGrid item>
          <Typography color="primary" variant={'h1'}>
            Beneficiado não encontrado
          </Typography>
          <Typography marginTop={1} color="black" variant={'body1'}>
            Não encontramos nenhuma campanha cadastrada no CPF{' '}
            <b>{customer.document}</b>.
          </Typography>
          <Typography color="black" variant={'body1'}>
            Revise seus dados e tente novamente.
          </Typography>
        </TitleGrid>

        <SubmitGrid item>
          <Button
            onClick={() => navigate('/campaign/order')}
            variant="contained"
            size="large"
          >
            Voltar
          </Button>
        </SubmitGrid>
      </CreateCampaignOrderContent>
      <CreateCampaignOrderFooter />
    </CreateCampaignOrderLayout>
  )
}

export default CampaignNotFoundStep

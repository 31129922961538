import React, { useEffect, useRef } from 'react'
import Header from '../../components/elements/Header'
import { LayoutContainer } from '../../components/elements/Layout/styles'
import {
  AttendanceItemsSection,
  ClientInfoData,
  ClientInfoDataCell,
  ClientInfoSection,
  ClientInfoText,
  PrintButtonContainer,
  WelcomeSection,
  WelcomeSectionImageContainer,
  WelcomeSectionTextContainer,
} from './styles'
import VacinasWaterMark from '../../assets/VacinasWatermarkWhite.svg'
import { Button, Grid, Typography } from '@mui/material'
import { mainTheme } from '../../styles/mainTheme'
import LabeledItem from '../../components/elements/LabeledItem'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../state/store'
import { actions } from '../../state/customerAttendance'
import { format } from 'date-fns'
import LoadingContainer from '../../utils/LoadingContainer'
import { parseAddress } from '../../utils/utils'
import AttendanceItem from '../../components/elements/AttendanceItem'
import { Print } from '@mui/icons-material'
import ReactToPrint from 'react-to-print'
import { parseAttendanceType } from '@vacinas-net/shared'

const CustomerAttendance = () => {
  const componentRef = useRef(null)

  const dispatch = useDispatch()
  const params = useParams()
  const customerAttendance = useSelector(
    (state) => state.customerAttendance.attendance
  )
  const isLoading = useSelector((state) => state.customerAttendance.isLoading)
  const getCampaignDetails = () => {
    dispatch(
      actions.getCustomerAttendanceFetch({
        attendanceUUID: params.attendanceUUID!,
      })
    )
  }
  useEffect(() => {
    getCampaignDetails()
  }, [])

  return (
    <div>
      <Header />
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <LayoutContainer ref={componentRef}>
          <WelcomeSection container xs={12}>
            <Grid item xs={4} md={2}>
              <WelcomeSectionImageContainer>
                <img
                  style={{ width: 125 }}
                  src={VacinasWaterMark}
                  alt="Logo Jenner"
                />
              </WelcomeSectionImageContainer>
            </Grid>
            <Grid item xs={8} md={10}>
              <WelcomeSectionTextContainer>
                <Typography color="white" variant={'h2'}>
                  Bem vindo ao sistema de agendamento Vacinas.net
                </Typography>
                <Typography
                  style={{ marginTop: mainTheme.spacing(2) }}
                  color="white"
                  variant={'body1'}
                >
                  Aqui você pode visualizar seus atendimentos e informar quem
                  irá receber as aplicações{' '}
                </Typography>
              </WelcomeSectionTextContainer>
            </Grid>
          </WelcomeSection>
          <ClientInfoSection container xs={12}>
            <Typography color="black" variant={'h2'}>
              Olá {customerAttendance.customerName}
            </Typography>
            <ClientInfoText color="black" variant={'body1'}>
              Veja aqui as informações do seu atendimento{' '}
              <span style={{ color: mainTheme.palette.primary.main }}>
                {customerAttendance.aliasId}
              </span>
            </ClientInfoText>
            <ClientInfoData>
              <ClientInfoDataCell>
                <LabeledItem
                  title={`Data de atendimento`}
                  content={
                    customerAttendance.deliveryWindow?.startDate
                      ? format(
                          new Date(
                            customerAttendance.deliveryWindow?.startDate
                          ),
                          'dd/MM/yyyy'
                        )
                      : '-'
                  }
                />
              </ClientInfoDataCell>
              <ClientInfoDataCell>
                <LabeledItem
                  title={`Horário do atendimento`}
                  content={
                    customerAttendance.deliveryWindow?.endDate &&
                    customerAttendance.deliveryWindow?.startDate
                      ? `Entre ${format(
                          new Date(
                            customerAttendance.deliveryWindow?.startDate
                          ),
                          'HH:mm'
                        )} e ${format(
                          new Date(customerAttendance.deliveryWindow?.endDate),
                          'HH:mm'
                        )}`
                      : '-'
                  }
                />
              </ClientInfoDataCell>
              <ClientInfoDataCell>
                <LabeledItem
                  title={`Tipo do atendimento`}
                  content={parseAttendanceType(customerAttendance.type)}
                />
              </ClientInfoDataCell>
              <ClientInfoDataCell>
                <LabeledItem
                  title={`Nome da clínica`}
                  content={
                    customerAttendance.clinic?.name
                      ? customerAttendance.clinic?.name
                      : '-'
                  }
                />
              </ClientInfoDataCell>
              <ClientInfoDataCell>
                <LabeledItem
                  title={`Endereço`}
                  content={
                    customerAttendance.address
                      ? parseAddress(customerAttendance.address)
                      : '-'
                  }
                />
              </ClientInfoDataCell>
            </ClientInfoData>
          </ClientInfoSection>
          <AttendanceItemsSection>
            <Typography color="black" variant={'h2'}>
              Itens do atendimento
            </Typography>
            {customerAttendance.itemList?.map((item) => {
              return (
                <AttendanceItem attendanceItem={item} key={item.orderItemId} />
              )
            })}
          </AttendanceItemsSection>
          <PrintButtonContainer>
            <div>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Print />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </PrintButtonContainer>
        </LayoutContainer>
      )}
    </div>
  )
}

export default CustomerAttendance

import {
  ListItem,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
} from '@mui/material'
import React from 'react'
import {
  ModalClinicDataContainer,
  ModalList,
  ModalLocationPinContainer,
  StyledCard,
} from '../styles'
import { LocationOn } from '@mui/icons-material'
import { mainTheme } from '../../../../styles/mainTheme'
import { useSelector } from '../../../../state/store'
import { parseAddress } from '../../../../utils/utils'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../state'

const AvailableClinicsList = (props: { closeModal: () => void }) => {
  const dispatch = useDispatch()

  const searchedClinicsByZipCode = useSelector(
    (state) => state.createCampaignOrder.searchedClinicsByZipCode
  )
  const placeStepErrors = useSelector(
    (state) => state.createCampaignOrder.placeStepErrors
  )

  const parseDistanceToKm = (distance?: number) => {
    if (distance === undefined || distance === null) {
      return '-'
    }

    if (Math.round(distance / 1000) === 0) {
      return '<1 km'
    }

    return `${String(Math.round(distance / 1000))} km`
  }

  return (
    <ModalList>
      {searchedClinicsByZipCode?.map((clinic) => {
        return (
          <ListItem key={clinic._id}>
            <StyledCard>
              <CardActionArea
                onClick={() => {
                  dispatch(
                    actions.createCampaignOrder.setSelectedClinic(clinic)
                  )
                  dispatch(
                    actions.createCampaignOrder.setPlaceStepErrors({
                      ...placeStepErrors,
                      selectedClinic: '',
                    })
                  )
                  props.closeModal()
                }}
              >
                <CardContent>
                  <Grid container xs={12}>
                    <Grid item xs={3}>
                      <ModalLocationPinContainer>
                        <LocationOn color="primary" />
                        <Typography variant="body1" color="textSecondary">
                          {parseDistanceToKm(clinic.distance)}
                        </Typography>
                      </ModalLocationPinContainer>
                    </Grid>
                    <Grid item xs={9}>
                      <ModalClinicDataContainer>
                        <Typography variant="h2" color="secondary">
                          {`${clinic.name}`}
                        </Typography>
                        <Typography
                          style={{ marginTop: mainTheme.spacing(1) }}
                          variant="body1"
                          color="textSecondary"
                        >
                          {parseAddress(clinic.address)}
                        </Typography>
                      </ModalClinicDataContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </ListItem>
        )
      })}
    </ModalList>
  )
}

export default AvailableClinicsList

import { Paper } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const ClinicMapPaper = styled(Paper)`
  display: flex;
  width: 100%;
  padding: ${mainTheme.spacing(2)}px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const MapContainer = styled('div')`
  height: 700px;
  width: 100%;
  margin-left: ${mainTheme.spacing(3)}px;
  display: flex;
  align-items: 'center';
  @media (max-width: 900px) {
    margin-top: ${mainTheme.spacing(2)}px;

    margin-left: 0px;
  }
`
export const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const SearchContainer = styled('div')`
  width: 100%;
  max-width: 360px;
`

import React, { useState, ChangeEvent, MouseEvent, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import {
  TablePaper,
  TableCellBody,
  TableCellHeader,
  TableTitleContainer,
} from './styles'
import { mainTheme } from '../../../styles/mainTheme'
import VoucherUsageTableChip from '../chips/VouchersUsageTableChip'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../state/store'
import { actions } from '../../../state'
import { useParams } from 'react-router-dom'
import VoucherFilters from './Filters/VoucherFilters'

const VoucherUsageTable = () => {
  const dispatch = useDispatch()
  const params = useParams<{ campaignId?: string }>()
  const voucherReport = useSelector((state) => state.voucherReport)

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  const downloadAllVouchers = () => {
    dispatch(
      actions.voucherReport.downloadAllVouchers({
        campaignId: params.campaignId!,
      })
    )
  }

  useEffect(() => {
    dispatch(
      actions.voucherReport.getVouchersPaged({
        page: pagination.page,
        limit: pagination.rowsPerPage,
        campaignId: params.campaignId,
        filters: voucherReport.filters,
      })
    )
  }, [dispatch, pagination, params.campaignId])

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 0,
    })
  }, [voucherReport.filters])

  return (
    <>
      <VoucherFilters />
      <TablePaper>
        <TableTitleContainer>
          <Typography
            style={{
              paddingTop: mainTheme.spacing(1 + 1 / 2),
              marginLeft: mainTheme.spacing(2),
              marginBottom: mainTheme.spacing(4),
            }}
            variant="h2"
          >
            Utilização dos vales
          </Typography>

          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              disabled={voucherReport.isDownloadingAllVouchers}
              onClick={() => {
                downloadAllVouchers()
                handleClose()
              }}
            >
              Exportar todos os vales
            </MenuItem>
          </Menu>
        </TableTitleContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHeader>Vale</TableCellHeader>
                <TableCellHeader>Cliente</TableCellHeader>
                <TableCellHeader>Clínica</TableCellHeader>
                <TableCellHeader>Cidade</TableCellHeader>
                <TableCellHeader align="center">Situação</TableCellHeader>
                <TableCellHeader>Data da Compra</TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {!voucherReport.isLoading ? (
                voucherReport.vouchers.map((voucher) => (
                  <TableRow key={voucher.voucherId}>
                    <TableCellBody component="th" scope="row">
                      {voucher.voucherRedemptionCode}
                    </TableCellBody>
                    <TableCellBody>{voucher.customerName || '-'}</TableCellBody>
                    <TableCellBody>{voucher.clinicName || '-'}</TableCellBody>
                    <TableCellBody>{voucher.clinicCity || '-'}</TableCellBody>
                    <TableCellBody align="center">
                      <VoucherUsageTableChip status={voucher.usageStatus} />
                    </TableCellBody>
                    <TableCellBody>
                      {voucher.orderPurchasedAt
                        ? format(new Date(voucher.orderPurchasedAt!), 'P', {
                            locale: ptBR,
                          })
                        : '-'}
                    </TableCellBody>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCellBody />
                  <TableCellBody />
                  <TableCellBody />
                  <TableCellBody>
                    <CircularProgress
                      style={{ padding: mainTheme.spacing(2) }}
                      size={30}
                    />
                  </TableCellBody>
                  <TableCellBody />
                  <TableCellBody />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={voucherReport.total}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </TablePaper>
    </>
  )
}

export default VoucherUsageTable

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  CampaignOrderFiltersAction,
  CampaignOrdersGetAction,
  CampaignOrdersState,
  DownloadAllCampaignOrdersAction,
} from './types'

const initialState: CampaignOrdersState = {
  isDownloadingCampaigns: false,
  isLoading: true,
  orders: [],
  page: 0,
  total: 0,
  filters: {},
}

export const slice = createSlice({
  name: 'campaignOrders',
  initialState,
  reducers: {
    getCamapaignOrders: (state, action: CampaignOrdersGetAction) => {
      const { page, limit } = action.payload
      state.isLoading = true
      state.page = page
      state.limit = limit
    },
    getCamapaignOrdersSuccess: (state, action) => {
      state.isLoading = false
      state.orders = action.payload.vouchers
      state.total = action.payload.total
    },
    getCamapaignOrdersError: (state) => {
      state.isLoading = false
    },
    setOrderIdFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.orderId = action.payload.orderId
    },
    setCustomerNameFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.customerName = action.payload.customerName
    },
    setCustomerDocumentFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.customerDocument = action.payload.customerDocument
    },
    setClinicNameFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.clinicName = action.payload.clinicName
    },
    setCityFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.city = action.payload.city
    },
    setStatusFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.status = action.payload.status
    },
    setOrderPurchasedAtFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.orderPurchasedAt = action.payload.orderPurchasedAt
    },
    setHasVoucherStatusFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.hasVoucher = action.payload.status
    },
    setOrderPurchasedAtFilterEnd: (
      state,
      action: CampaignOrderFiltersAction
    ) => {
      state.filters.orderPurchasedAtEnd = action.payload.orderPurchasedAtEnd
    },
    setCouponFilter: (state, action: CampaignOrderFiltersAction) => {
      state.filters.coupon = action.payload.coupon
    },
    downloadCampaignOrders: (
      state,
      action: DownloadAllCampaignOrdersAction
    ) => {
      state.isDownloadingCampaigns = true
    },
    downloadCampaignOrdersSuccess: (state) => {
      state.isDownloadingCampaigns = false
    },
    downloadCampaignOrdersError: (state) => {
      state.isDownloadingCampaigns = false
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer

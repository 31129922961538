import React, { useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  ScheduleOrderFormContainer,
  ScheduleOrderMenuContainer,
} from '../ScheduleOrderStep/styles'
import Menu from './Menu'
import {
  CreateCampaignOrderFooterStyled,
  ScheduleOrderFormGrid,
} from './styles'
import ReceiptInfo from './ReceiptInfo'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state/createCampaignOrder'
import { LoadingButton } from '@mui/lab'
import { getCampaignAllowerUserList } from '../../../services/createCampaignOrder'
import { errorActions } from '../../../state/errors'

const ScheduleOrderReceipt = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const order = useSelector((state) => state.createCampaignOrder.createdOrder)
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)

  useEffect(() => {
    if (!campaign || !order) navigate('/campaign/order')
  }, [])

  const searchCampaigns = async () => {
    try {
      dispatch(actions.setLoading(true))
      const campaigns = await getCampaignAllowerUserList({
        document: customer.document.replace(/[^\d]/g, ''),
      })

      if (!campaigns?.length) return navigate('/campaign/order/not-found')

      dispatch(actions.setCampaigns(campaigns))

      navigate('/campaign/order/list')
    } catch (error) {
      const errorMessage = (error as Error).message

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  const submit = async () => {
    dispatch(
      actions.setCreateOrderFormOrder({
        fullName: '',
        birthdate: '',
        email: '',
        document: '',
        phone: '',
        placeName: '',
        availableAttendanceId: '',
      })
    )
    dispatch(actions.setSearchedZipCode(''))
    dispatch(actions.setAvailableAttendances(undefined))
    dispatch(actions.setSearchedClinicsByZipCode(undefined))
    dispatch(actions.setVaccineShotTime(undefined))
    dispatch(actions.setVaccineShotDay(undefined))
    dispatch(actions.setSelectedClinic(undefined))
    dispatch(actions.setSearchedZipCode(undefined))
    dispatch(actions.setCreateOrderFormStep('form'))
    dispatch(actions.setCampaign(undefined))
    dispatch(actions.setInvoiceId(undefined))
    dispatch(actions.setPaymentStatus(undefined))
    dispatch(actions.setPixPaymentCode(undefined))
    dispatch(actions.setPixPaymentQrCode(undefined))
    dispatch(
      actions.setIuguCreditCardFormData({
        expiration: '',
        full_name: '',
        installmentsNumber: 1,
        installmentsValue: campaign?.requiredCustomerPaymentInCents,
        number: '',
        verification_value: '',
      })
    )

    await searchCampaigns()
  }

  // const allowedCustomers = _.flatten(
  //   campaign?.allowedCustomersBatches?.map((batch) =>
  //     batch.allowedCustomers.map((customer) => ({
  //       name: customer.fullName,
  //       document: customer.document,
  //     }))
  //   )
  // )

  // const customerData = allowedCustomers.find(
  //   (allowedCustomer) =>
  //     allowedCustomer.document === customer.document.replace(/[^0-9]/g, '')
  // )

  if (!campaign || !order) return <></>

  return (
    <Grid container style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <ScheduleOrderMenuContainer item xs={12} md={4} lg={3}>
        <CreateCampaignOrderFooterStyled />
        <Menu />
      </ScheduleOrderMenuContainer>
      <ScheduleOrderFormContainer item xs={12} md={8} lg={9}>
        <ScheduleOrderFormGrid container>
          <Grid item xs={12} textAlign={'center'}>
            <Typography color="primary" variant="h1" marginBottom={1.5}>
              {`${formData.fullName}, seu pedido foi realizado com sucesso!`}
            </Typography>
            <Typography variant="h2" fontWeight={'normal'}>
              Seu agendamento foi realizado e está aguardando a confirmação da
              clínica. Você receberá uma notificação em seu e-mail após a
              confirmação.
            </Typography>
          </Grid>

          <ReceiptInfo order={order} />

          <Grid item xs={12} marginBottom={4.5}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              onClick={() => submit()}
              fullWidth
            >
              RETORNAR PARA PÁGINA INICIAL
            </LoadingButton>
          </Grid>
        </ScheduleOrderFormGrid>
      </ScheduleOrderFormContainer>
    </Grid>
  )
}

export default ScheduleOrderReceipt

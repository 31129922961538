import { Paper, Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const OrderCardContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: ${mainTheme.spacing(2)}px;
  margin-top: ${mainTheme.spacing(2)}px;
`

export const CardHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CustomerDataContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${mainTheme.spacing(4)}px;
  margin-bottom: ${mainTheme.spacing(4)}px;
`

export const LabeledItemHeader = styled(Typography)`
  color: ${mainTheme.palette.grey[300]};
  fontweight: bold;
`
export const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  padding-top: ${mainTheme.spacing(3)}px;
  padding-bottom: ${mainTheme.spacing(3)}px;
  border-bottom: 1px solid ${mainTheme.palette.primary.main};
  @media (max-width: 900px) {
    overflow: scroll;
  }
`
export const Link = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`

import React from 'react'
import OrderReportTable from '../../components/elements/OrderReportTable'
import { OrderReportContainer } from './styles'

const OrderReport = () => {
  return (
    <OrderReportContainer>
      <OrderReportTable />
    </OrderReportContainer>
  )
}

export default OrderReport

import styled from 'styled-components'
import { Paper, TableCell } from '@mui/material'
import { mainTheme } from '../../../styles/mainTheme'

export const TablePaper = styled(Paper)`
  overflow: none;
  padding: ${mainTheme.spacing(3)}px;
`

export const TableCellHeader = styled(TableCell)`
  color: ${mainTheme.palette.grey[400]};
  font-weight: bold;
  border-color: ${mainTheme.palette.grey[500]};
  max-width: 80px;
`

export const TableCellBody = styled(TableCell)`
  border-color: ${mainTheme.palette.grey[500]};
  @media (max-width: 900px) {
    overflow: scroll;
  }
`

export const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`

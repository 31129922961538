import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { mainTheme } from '../../../styles/mainTheme'
import { makeStyles } from '@material-ui/core/styles'
import { MoreVert } from '@mui/icons-material'
import { logout } from '../../../config/firebase'
import { useNavigate } from 'react-router-dom'
import { actions } from '../../../state'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  hideOnMobile: {
    display: 'none',
    [mainTheme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
})

const CustomAvatar = (props: { name: string; imageURL?: string }) => {
  const { name, imageURL } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <div className={classes.hideOnMobile}>
        <Typography variant="body1">{name}</Typography>
      </div>
      <div
        style={{
          marginLeft: mainTheme.spacing(1),
          marginRight: mainTheme.spacing(1),
        }}
      >
        {imageURL ? (
          <Avatar
            src={imageURL}
            sx={{ bgcolor: mainTheme.palette.primary.main }}
          />
        ) : (
          <Avatar sx={{ bgcolor: mainTheme.palette.primary.main }}>
            {name && name.charAt(0)}
          </Avatar>
        )}
      </div>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={async () => {
              dispatch(actions.user.clearUser())
              dispatch(actions.auth.setIsLogged(false))
              navigate('/login')
              await logout()
            }}
          >
            Sair
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default CustomAvatar

import React from 'react'
import { Grid, Typography, List } from '@mui/material'
import { mainTheme } from '../../../../../styles/mainTheme'
import { PaperContainer, StyledBadge, StyledListItem } from './styles'
import { useSelector } from '../../../../../state/store'

const PixQrCodePayment = () => {
  const pixQrCode = useSelector(
    (state) => state.createCampaignOrder.pixPaymentQrCode
  )

  return (
    <PaperContainer elevation={0}>
      <Grid container xs={12}>
        <Grid item xs={5}>
          <img width={'100%'} src={pixQrCode} />
        </Grid>
        <Grid paddingLeft={`${mainTheme.spacing(2)}px`} item xs={7}>
          <Typography textAlign={'left'} color={'primary'} variant="h2">
            QR Code
          </Typography>

          <List>
            <StyledListItem>
              <StyledBadge color="primary" badgeContent={1} />
              <Typography color={'textSecondary'} variant="body1">
                Em seu app de pagamento, entre na seção do Pix, selecionando a
                opção de pagar com QR Code.{' '}
              </Typography>
            </StyledListItem>
            <StyledListItem>
              <StyledBadge color="primary" badgeContent={2} />
              <Typography color={'textSecondary'} variant="body1">
                Aponte a câmera do celular para o código ao lado.
              </Typography>
            </StyledListItem>
            <StyledListItem>
              <StyledBadge color="primary" badgeContent={3} />
              <Typography color={'textSecondary'} variant="body1">
                Confirme o valor e demais dados e faça o pagamento!
              </Typography>
            </StyledListItem>
          </List>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

export default PixQrCodePayment
